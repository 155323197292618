import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Api from '../../../_helpers/Api'

//images
import createProjectIllustration from '../../../assets/images/modals/create-project-illustration.jpg'
import loader from '../../../assets/images/app/loader.svg'
import closeIcon from '../../../assets/images/modals/icons/close.png'

//modals
import SuccessModal from '../../modals/Success'
import Dropdown from '../../app/Dropdown'

function Create(props, ref) {
    const [create, setCreate] = useState({
        overlay: false,
        modal: false,
        types: [],
        clients: [],
        loading: false,
        checkboxes: {
            address_type: 0,
            have_road: 0,
            have_sidewalk: 0
        }
    })

    const form = document.getElementById('form')

    //refs
    const successModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: () => {
            setCreate(create => ({
                ...create,
                overlay: true,
            }))
            setTimeout(() => {
                setCreate(create => ({
                    ...create,
                    modal: true,
                }))
            }, 50)
        },
        close: () => handleClose(),
    }))

    const handleClose = () => {
        setCreate(create => ({
            ...create,
            modal: false,
        }))

        setTimeout(() => {
            document.getElementById('form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)
            removeValidations()
            setCreate(create => ({
                ...create,
                overlay: false,
            }))
        }, 100)
    }

    useEffect(() => {
        Api.get('types/get')
            .then(res => {
                setCreate(create => ({
                    ...create,
                    types: res.data.items,
                }))
            })

        Api.get('clients/get')
            .then(res => {
                setCreate(create => ({
                    ...create,
                    clients: res.data.items,
                }))
            })
    }, [])

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)
        removeValidations()

        setCreate(create => ({
            ...create,
            loading: true,
        }))

        Api.post('projects/add', data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors

                fields && Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                        inputs.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => {
                setCreate(create => ({
                    ...create,
                    loading: false,
                }))
            })
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    const handleChange = e => {
        const val = e.target.checked
        const name = e.target.name

        setCreate(create => ({
            ...create,
            checkboxes: {
                ...create.checkboxes,
                [name]: val
            },
        }))
    }

    return (
        <div className={`${create.overlay ? 'visible' : ''} overlay`}>
            <SuccessModal
                mainMessage="Успешно създаване на нов проект!"
                secondaryMessage="Може да намерите новият проектв списъка с проекти."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <div className="scroller">
                <div className={`${create.modal ? 'show' : ''} box box-create-project`}>
                    <img src={closeIcon} className="close" onClick={handleClose} />
                    <form id="form" onSubmit={handleSubmit} className="create-task">
                        <h2>
                            <span>
                                Създаване на
                        </span>
                            <br />
                        НОВ ПРОЕКТ
                    </h2>
                        <p>
                            Опишете основната информация по Вашия нов проект
                    </p>

                        <br />
                        <br />

                        <div className="left">
                            <div className="row">
                                <div className="col full-width">
                                    <label htmlFor="name">
                                        Име на проекта
                                </label>
                                    <input autoComplete="off" role="presentation"
                                        type="text"
                                        placeholder="Въведете име..."
                                        id="name"
                                        name="name"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col full-width">
                                    <label htmlFor="type_id">
                                        Тип
                                </label>
                                    <select id="type_id" name="type_id">
                                        <option value="0" selected disabled>
                                            Избор
                                    </option>
                                        {create.types.map(type =>
                                            <option key={type.id} value={type.id}>
                                                {type.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col full-width">
                                    <label htmlFor="client_id">
                                        Клиенти
                                </label>
                                    <select name="client_id" id="client_id">
                                        <option value="0" selected disabled>
                                            Избор
                                    </option>
                                        {create.clients.map(client =>
                                            <option key={client.id} value={client.id}>
                                                {client.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Склад
                                </label>
                                    <Dropdown
                                        inputIdName="storage_id"
                                        inputClassName="storage_id"
                                        url={`storages/get-nopage`}
                                        multiple={true}
                                        multipleInputValues={''}
                                        multipleInputIdValues={''}
                                        renderText={data => {
                                            return <>{data.name}</>
                                        }}
                                    />
                                </div>
                            </div>


                            <div className="row">
                                <div className="col">
                                    <label className="checkbox">
                                        Населено място
                                    <input
                                            autoComplete="off"
                                            role="presentation"
                                            type="checkbox"
                                            name="address_type"
                                            onChange={handleChange}
                                            checked={create.checkboxes.address_type}
                                            value={create.checkboxes.address_type}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="row x2">
                                <div className="col">
                                    <label className="checkbox">
                                        Път
                                    <input
                                            autoComplete="off"
                                            role="presentation"
                                            type="checkbox"
                                            name="have_road"
                                            onChange={handleChange}
                                            checked={create.checkboxes.have_road}
                                            value={create.checkboxes.have_road}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="col">
                                    <label className="checkbox">
                                        Tротоари
                                    <input
                                            autoComplete="off"
                                            role="presentation"
                                            type="checkbox"
                                            name="have_sidewalk"
                                            onChange={handleChange}
                                            checked={create.checkboxes.have_sidewalk}
                                            value={create.checkboxes.have_sidewalk}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col full-width">
                                    <label htmlFor="fromdate">
                                        От
                                </label>
                                    <input autoComplete="off" role="presentation"
                                        type="date"
                                        id="fromdate"
                                        name="fromdate"
                                    />
                                </div>
                                <div className="col full-width">
                                    <label htmlFor="todate">
                                        До
                                </label>
                                    <input autoComplete="off" role="presentation"
                                        type="date"
                                        id="todate"
                                        name="todate"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <img src={createProjectIllustration} />
                        </div>
                        <div className="buttons">
                            {create.loading ?
                                <img src={loader} alt="loading" />
                                :
                                <button className="btn-add">
                                    Създаване
                            </button>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Create)
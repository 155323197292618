import React from 'react'

//images
import filterArrowIcon from '../../../assets/images/elements/icons/filter-arrow.png'


let timeout
function Filter(props) {

    const handleFilterByName = e => {
        let value = e.target.value
        clearInterval(timeout)
        timeout = setTimeout(() => props.handleFilterByName(value), 300)
    }

    const handleSortByDate = () => props.handleSortByDate()
    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            <input autoComplete="off" role="presentation"
                type="search"
                placeholder="Въведете име..."
                onChange={handleFilterByName}
            />

            <div className="sort" onClick={handleSortByDate}>
                <p>
                    Сортиране по:
                </p>
                Дата на създаване
                <img src={filterArrowIcon} alt="filter" className={`filter-arrow ${props.filterByDate == 'asc' ? 'rotate' : ''}`} />
            </div>
        </form>
    )
}

export default Filter
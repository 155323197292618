import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

function Tabs(props) {
    const location = useHistory()

    const [tabs, setTabs] = useState({
        data: [
            {
                id: 1,
                name: 'Материали',
            },
        ]
    })

    const handleActiveTab = e => {
        let tabId = e.target.getAttribute('data-tab')
        props.handleCurrentTab(tabId)
    }

    return (
        <ul className="tabs wrapper">
            {tabs.data.map(tab =>
                <li
                    data-tab={tab.id}
                    className={tab.id == props.currentTab ? 'active' : ''}
                    onClick={handleActiveTab}
                >
                    {tab.name}
                </li>
            )}
        </ul>
    )
}

export default Tabs
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

//context
import { useAuthContext } from '../../_contexts/AuthContext'

//helpers
import Api from '../../_helpers/Api'

//styles
import '../../assets/css/login.css'

//images
import brand from '../../assets/images/pages/login/brand.png'
import loader from '../../assets/images/app/loader.svg'
import dsLogo from '../../assets/images/pages/login/ds-logo.png'

function Index() {
    const auth = useAuthContext()
    const history = useHistory()

    const [login, setLogin] = useState({
        validate: false,
        loading: false,
    })

    if (auth.isLogged) history.replace('/')

    const handleLogin = e => {
        e.preventDefault()

        let data = new FormData(e.target)
        setLogin(login => ({
            ...login,
            loading: true,
            validate: false,
        }))

        Api.post('auth/login', data)
            .then(res => {
                auth.login(res.data, true)

                setLogin(login => ({
                    ...login,
                    loading: false,
                    validate: false,
                }))

                history.push('/')
            })
            .catch(() => {
                setLogin(login => ({
                    ...login,
                    loading: false,
                    validate: true,
                }))
            })
    }

    // const handleInvalid = e => e.target.setCustomValidity('Моля, попълнете полето.')

    return (
        <section id="login">
            <div className="wrapper top-wrapper">
                <form autoComplete="off" role="presentation" onSubmit={handleLogin}>
                    <img src={brand} alt="Prespa" />
                    <h1>
                        Prespa Invest
                    </h1>
                    <h2>
                        Вход за потребители на системата
                    </h2>
                    <div className="row">
                        <div className="col full-width">
                            <label htmlFor="email">
                                Е-поща
                            </label>
                            <input autoComplete="off" role="presentation"
                                type="email"
                                placeholder="Въведете потребителско име"
                                id="email"
                                name="email"
                                className={login && login.validate && 'validate'}
                            // required
                            // onInvalid={handleInvalid}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col full-width">
                            <label htmlFor="password">
                                Парола
                            </label>
                            <input autoComplete="off" role="presentation"
                                type="password"
                                id="password"
                                name="password"
                                placeholder="Въведете парола"
                                className={login && login.validate && 'validate'}
                            // required
                            // onInvalid={handleInvalid}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        {login.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <button type="submit">
                                Вход
                            </button>
                        }
                    </div>
                </form>
            </div>
            <div className="bottom">
                <div className="wrapper">
                    <a href="https://danielstandard.com" target="_blank" className="software-partner">
                        <img src={dsLogo} alt="Daniel Standard" />
                    </a>
                </div>
            </div>
        </section>
    )
}
export default Index
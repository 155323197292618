import React, { useRef } from 'react'

//modals
import SelectWorkers from '../SelectWorkers'
import AttentionModal from '../Attention'

//images
import deleteIcon from '../../../assets/images/modals/icons/delete.png'

function SelectWorkersField(props) {

    // refs
    const selectMachinesRef = useRef(null)
    const attentionModalRef = useRef(null)

    const handleOpenModal = e => {
        e.preventDefault();

        if (props.brigade_id || props.isBrigade) {
            selectMachinesRef.current.open(props.brigade_id)
        } else if (!props.brigade_id && props.isTask) {
            attentionModalRef.current.open()
        }
    }

    const handleRemove = id => {
        let machines = props.workers.filter(m => Number(m.id) !== Number(id));

        if (typeof props.setWorkers === 'function') {
            props.setWorkers(machines);
        }
    }

    return (
        <div className="col full-width">
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Моля, изберете бригада!"
                secondaryMessage="Работниците са пряко обвързани с бригадите."
            />
            <h4>
                Избрани работници
            </h4>
            {props.workers.length === 0
                ?
                <p className="left extra-text">Все още нямате добавени работници към текущата бригада. </p>
                :
                <ul className="scroller-list">
                    {props.workers.filter(t => props.isTask || t.role_id != 2).map(m =>

                        <li
                            key={'machine-' + m.id}
                            className="selected-list">
                            {m.type ? (m.type.name + ' - ') : ''} {m.name}
                            <img
                                src={deleteIcon}
                                alt="Изтриване"
                                onClick={() => handleRemove(m.id)} />

                        </li>
                    )}
                </ul>
            }

            {props.workers.filter(t => props.isTask || t.role_id != 2).map(u =>
                <input key={'machine-' + u.id} name="user_id[]" value={u.id} type="hidden" />
            )}

            <button className="btn-extra-add" onClick={handleOpenModal} name="user_id">
                Добавяне
            </button>

            <SelectWorkers
                ref={selectMachinesRef}
                isBrigade={props.isBrigade}
                brigade_id={props.brigade_id}
                isTask={props.isTask}
                setWorkers={props.setWorkers}
                workers={props.workers}
            />
        </div>
    )
}

export default SelectWorkersField
import React from 'react'
import { useHistory } from 'react-router-dom'

function Project(props) {
    const history = useHistory()
    const data = props.data

    const lastDayOfMonth = (y, m) => {
        return new Date(y, m + 1, 0).getDate()
    }
    const lastDayOfCurrentMonth = lastDayOfMonth(data.from_year, data.from_month)

    const handleShowProject = projectId => history.push(`/projects/view/${projectId}/${data.address_type}`)

    return (
        <li
            style={{
                left: `${((Number(data.from_month) - Number(props.fromMonth)) * 115) + (115 / lastDayOfCurrentMonth * Number(data.from_day)) - 5 + ((Number(data.from_year) - Number(props.fromYear) > 0 ? (Number(data.from_year) - Number(props.fromYear)) * 115 * 12 : 0))}px`,
                width: `${(Number(data.to_month - data.from_month) * 115) + Number(data.from_day) + (Number(data.to_year) - Number(data.from_year) > 0 ? (Number(data.to_year) - Number(data.from_year)) * 115 * 12 : 0)}px`,
                backgroundColor: '#1298FF'
            }}
            onClick={() => handleShowProject(data.id)}
        >
            <span>
                {data.from_dmy} - {data.to_dmy}
            </span>
            <div className="dashbar">
                <div className="progress" style={{ width: data.progress + '%' }}>
                    <p>
                        {data.progress}%
                    </p>
                </div>
            </div>
        </li>
    )
}

export default Project
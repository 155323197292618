import React, { useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//components
import Material from './Material'

//modals
import RemoveModal from '../../../../modals/Remove'
import AttentionModal from '../../../../modals/Attention'

function MaterialsTable(props) {
    const materials = props.materials

    //refs
    const removeModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //end refs

    return (
        <>
            <RemoveModal
                ref={removeModalRef}
                // handleRemoveCallback={handleRemoveCallback}
                mainMessage={`Сигурни ли сте, че искате да премахнете този работник от бригадата?`}
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Не може да премахнете последния работник от бригадата"
                secondaryMessage="Може да изтриете бригадата през бутна за редакция."
            />

            <table>
                <thead>
                    <tr>
                        <th>Име</th>
                        <th>Характ.</th>
                        <th>Наличност/Проект</th>
                        <th>Наличност/Склад</th>
                        <th className="option">
                            Опции
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {materials.map(material =>
                        <Material
                            material={material}
                            handleTransfer={props.handleTransfer}
                            handleOpen={props.handleOpen}
                        />
                    )}
                </tbody>
            </table>
        </>
    )
}

export default MaterialsTable
import React, { useEffect, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import Api from '../../_helpers/Api'

//componets
import Tabs from './tabs/Bar'
import Stages from './tabs/stages/Index'
import Tasks from './tabs/tasks/Index'
import ProjectData from './partials/ProjectData'
import Brigade from './tabs/brigade/Index'
import Materials from './tabs/materials/Index'

//images
import backIcon from '../../assets/images/pages/icons/back-to-projects.png'
import loader from '../../assets/images/app/loader.svg'

//contexts
import { useProjcetContext } from '../../_contexts/ProjectContext'
import { useAuthContext } from '../../_contexts/AuthContext'

function View() {
    const projectContext = useProjcetContext()
    const auth = useAuthContext()
    const projectId = useParams().id
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    const [view, setView] = useState({
        data: {},
        refresh: '',
        currentTab: isAdmin || isForeman ? searchParams.get('tab') ? searchParams.get('tab') : 1 : 2
    })

    useEffect(() => {
        Api.get(`projects/getbyid?id=${projectId}`)
            .then(res => {
                setView(view => ({
                    ...view,
                    data: res.data
                }))
                projectContext.setData(res.data)
            })
    }, [view.refresh])

    const handleCurrentTab = currentTab => {
        setView(view => ({
            ...view,
            currentTab: currentTab
        }))
    }

    const handleRefreshData = () => {
        setView(prev => ({
            ...prev,
            refresh: new Date().getTime()
        }))
    }

    return (
        <section id="view-project">
            <aside>
                <div className="wrapper">
                    <Link to="/projects">
                        <img src={backIcon} alt="Назад" />
                        Към списък с проекти
                    </Link>
                    {!view.data.id ?
                        <img src={loader} alt="loading" />
                        :
                        <ProjectData
                            data={view.data}
                        />
                    }
                </div>
            </aside>
            <div className="main wrapper">
                <Tabs
                    key={view.refresh}
                    currentTab={view.currentTab}
                    projectId={projectId}
                    handleCurrentTab={handleCurrentTab}
                    handleRefreshData={handleRefreshData}
                />
                {view.currentTab == 1 ?
                    <Stages
                        handleRefreshData={handleRefreshData}
                    />
                    :
                    view.currentTab == 2 ?
                        <Tasks
                            handleRefreshData={handleRefreshData}
                        />
                        :
                        view.currentTab == 3 ?
                            <Materials
                                handleRefreshData={handleRefreshData}
                            />
                            :
                            view.currentTab >= 4 ?
                                <Brigade
                                    handleRefreshData={handleRefreshData}
                                    tab={view.currentTab}
                                /> :
                                ''
                }
            </div>
        </section>
    )
}

export default View
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

//components
import Calendar from 'react-calendar'

//images
import calendarPrev from '../../../../assets/images/pages/icons/calendar-prev.png'
import calendarNext from '../../../../assets/images/pages/icons/calendar-next.png'
import Api from '../../../../_helpers/Api'

//modals
import CreateTaskModal from '../../../projects/tabs/tasks/modals/CreateEdit'
import AttentionModal from '../../../modals/Attention'

function Index() {
    const history = useHistory()
    const initialTodaysEvent = {
        name: '',
        from_dmy: null,
        to_dmy: null,
        id: null
    }
    const [activities, setActivities] = useState({
        calendar: new Date(),
        changeMonthRefresh: new Date(),
        data: [],
        todaysEvent: initialTodaysEvent,
        projects: []
    })

    //refs
    const createTaskModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //end refs

    const handleChangeCalendar = date => {
        const selectedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`

        const selectedDataActivity = activities.data.filter(activity => {
            return activity.date == selectedDate
        })[0]

        setActivities(prev => ({
            ...prev,
            calendar: date,
            todaysEvent: selectedDataActivity ? selectedDataActivity : initialTodaysEvent
        }))
    }

    const handleChange = date => {
        setActivities(prev => ({
            ...prev,
            changeMonthRefresh: date.activeStartDate,
        }))
    }

    useEffect(() => {
        Api.get('projects/all')
            .then(res => {
                setActivities(prev => ({
                    ...prev,
                    projects: res.data,
                }))
            })
    }, [])

    useEffect(() => {
        document.querySelector('.react-calendar__month-view__days').classList.remove('remove-blure')
        Api.get(`dashboard/calendar?month=${activities.changeMonthRefresh.getMonth() + 1}&year=${activities.changeMonthRefresh.getFullYear()}`)
            .then(res => {
                setActivities(prev => ({
                    ...prev,
                    data: res.data
                }))

                const buttons = document.querySelectorAll('.react-calendar__tile > abbr')

                for (let i = 0; i < buttons.length; i++) {
                    const button = buttons[i]
                    const buttonLabel = button.getAttribute('aria-label')

                    for (let j = 0; j < res.data.length; j++) {
                        if (res.data[j].date == buttonLabel) {
                            if (res.data[j].event_type == 1) button.classList.add('stage-start')
                            else if (res.data[j].event_type == 2) button.classList.add('stage-end')
                            else if (res.data[j].event_type == 3) {
                                button.classList.remove('stage-start')
                                button.classList.add('project-start')
                            }
                            else if (res.data[j].event_type == 4) {
                                button.classList.remove('stage-end')
                                button.classList.add('project-end')
                            }
                        }
                    }
                    document.querySelector('.react-calendar__month-view__days').classList.add('remove-blure')
                }
            })
    }, [activities.changeMonthRefresh])

    const handleAddTask = () => {
        if (activities.projects.length == 0) attentionModalRef.current.open()
        else createTaskModalRef.current.open()
    }

    const handleViewActivity = (id, typeId, projectId) => {
        if (typeId == 1 || typeId == 2) history.push(`/projects/view/${projectId}/${activities.todaysEvent.event.project.address_type}/?stage_id=${id}`)
        else if (typeId == 3 || typeId == 4) history.push(`/projects/view/${id}/${activities.todaysEvent.event.address_type}`)
    }

    return (
        <>
            <CreateTaskModal
                ref={createTaskModalRef}
                projectsFieldReadOnly={false}
                projects={activities.projects}
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Преди да създадете задача първо създайте проект!"
                secondaryMessage="Проектите и задачите са пряко обвързани."
            />
            <div className="holder">
                <div className="row">
                    <Calendar
                        onChange={handleChangeCalendar}
                        value={activities.calendar}
                        locale="bg-BG"
                        nextLabel={<img src={calendarNext} title="Следващ месец" />}
                        prevLabel={<img src={calendarPrev} title="Предходен месец" />}
                        prev2Label=""
                        next2Label=""
                        formatLongDate={(locale, date) => `${new Date(date).getDate(date)}-${new Date(date).getMonth(date) + 1}-${new Date(date).getFullYear(date)}`}
                        showFixedNumberOfWeeks={false}
                        showNeighboringMonth={false}
                        onActiveStartDateChange={handleChange}
                    />
                </div>
                <div className="row quick-add" style={!activities.todaysEvent.event ? { marginBottom: 0 } : {}}>
                    <p>
                        Бързо добавяне
                        <br />
                        на задача
                    </p>
                    <button
                        className="btn-extra-add"
                        onClick={handleAddTask}
                    >
                        Добави
                    </button>
                </div>
                {activities.todaysEvent.event &&
                    <>
                        <hr />
                        <div className="col info">
                            <h3>
                                {activities.todaysEvent.event.name}
                            </h3>
                            <p>
                                Вижте подробности за активностите, чиито предвиден срок изтича на {activities.todaysEvent.event.to_dmy}
                            </p>
                            <button onClick={() => handleViewActivity(activities.todaysEvent.event.id, activities.todaysEvent.event_type, activities.todaysEvent.event.project_id && activities.todaysEvent.event.project_id)}>
                                Виж
                            </button>
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default Index
import React, { useState, useImperativeHandle, forwardRef } from 'react'

//images
import attentionIcon from '../../assets/images/modals/icons/attention.png'

function Attention(props, ref) {

    const [attention, setAttention] = useState({
        overlay: false,
        modal: false,
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            setAttention(attention => ({
                ...attention,
                overlay: true,
            }))
            setTimeout(() => {
                setAttention(attention => ({
                    ...attention,
                    modal: true,
                }))
            }, 50)
        },
        close: () => handleClose(),
    }))

    const handleClose = e => {
        e.preventDefault()

        setAttention(attention => ({
            ...attention,
            modal: false,
        }))

        setTimeout(() => {
            setAttention(attention => ({
                ...attention,
                overlay: false,
            }))
        }, 100)

        props.handleAttentionAction && props.handleAttentionAction()
    }

    return (
        <div className={`${attention.overlay ? 'visible' : ''} overlay`}>
            <div className={`${attention.modal ? 'show' : ''} box`}>
                <img src={attentionIcon} alt="attention" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">
                    <button className="btn-primary" onClick={handleClose}>
                        Разбрах
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Attention)
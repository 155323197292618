import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../_helpers/Api'

//images
import loader from '../../assets/images/app/loader.svg'
import closeIcon from '../../assets/images/modals/icons/close.png'
import { useAuthContext } from '../../_contexts/AuthContext'

//modals
import SuccessModal from './Success'
import AttentionModal from './Attention'

//components
import Dropdown from '../app/Dropdown'


function CreateEdit(props, ref) {


    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1
    const storage = {
        storage_id: null,
        material_id: null,
        current_price: null,
        single_price: null,
        quantity: 0,
        total_quantity: 0,
    }

    const [createEdit, setCreateEdit] = useState({
        overlay: false,
        modal: false,
        loading: false,
        storages: [],
        materials: [],
        //edit
        mainLoading: false,
        id: null,
        storage: storage,
        original: storage,
    })

    const form = document.getElementById('edit-form')

    //refs
    const successModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: (id, quantity) => handleOpen(id, quantity),
        close: () => handleClose(),
    }))

    const handleOpen = (id, quantity) => {
        Api.get(`projects/get-items?stage_id=${props.stageId}`)
            .then(res => {
                setCreateEdit(createEdit => ({
                    ...createEdit,
                    storages: res.data,
                }))
                stopMainLoading()
            })
        setCreateEdit(createEdit => ({
            ...createEdit,
            overlay: true,
            id: id,
            mainLoading: true,
            storage: {
                ...createEdit.storages,
                total_quantity: quantity ? quantity : 0
            }
        }))
        setTimeout(() => {
            setCreateEdit(createEdit => ({
                ...createEdit,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            modal: false,
        }))
        setTimeout(() => {
            document.getElementById('edit-form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)

            removeValidations()
            setCreateEdit(createEdit => ({
                ...createEdit,
                overlay: false,
                mainLoabg: true,
                id: null,
                storage: storage,
                original: storage
            }))
        }, 100)
    }

    const handleChange = e => {
        let name = e.target.name
        let val = e.target.value

        setCreateEdit(createEdit => ({
            ...createEdit,
            storage: {
                ...createEdit.storage,
                [name]: val
            }
        }))
        setCreateEdit(prev => ({
            ...prev,
            storage: {
                ...prev.storage,
                current_price: createEdit.storage.single_price * Number(val)
            }
        }))
    }
    const handleChangeMaterials = data => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            storage: {
                ...createEdit.storage,
                material_id: data.id,
                current_price: Number(data.price),
                single_price: Number(data.price)
            }
        }))
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()

        data.append('stage_id', props.stageId)

        if (createEdit.id) data.append('id', createEdit.id)

        let url
        if (createEdit.id) url = 'stages/returnmaterial'
        else url = 'stages/addmaterial'

        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors
                Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                        let inputsWithClass = form.querySelectorAll("[className='" + i[0] + "']")

                        inputs.forEach(i => i.classList.add('validate'))
                        inputsWithClass.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: false,
        }))
    }

    const stopMainLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            mainLoading: false,
        }))
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <div className={`${createEdit.overlay ? 'visible' : ''} overlay`}>
            <SuccessModal
                mainMessage={createEdit.id ? 'Успешно редактирахте материал!' : 'Материалът е добавен успешно!'}
                secondaryMessage="Може да откриете промените в списъка с материали."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Моля изберете склад!"
                secondaryMessage="Склада и материалите са пряко обвързани."
            />
            <div className="scroller">
                <div className={`${createEdit.modal ? 'show' : ''} box box-small`}>
                    <img src={closeIcon} className="close" onClick={handleClose} />
                    <form id="edit-form" onSubmit={handleSubmit} className="create-task">
                        <h3>
                            {createEdit.id ?
                                'Връщане на материал' :
                                'Добавяне на материал'
                            }
                        </h3>
                        {createEdit.mainLoading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                {!createEdit.id && <div className="row">
                                    <div className="col full-width" >
                                        <label>
                                            Материал
                                    </label>
                                        <Dropdown
                                            inputIdName="material_id"
                                            url='projects/get-items'
                                            params={{
                                                stage_id: props.stageId
                                            }}
                                            inputClassName="material_id"
                                            renderText={data => {
                                                return <>{data.materials.name} - {data.materials.specification} - {data.supplier && data.supplier.name + ' - '}{data.price} лв.</>
                                            }}
                                            onChange={handleChangeMaterials}
                                        />
                                    </div>
                                </div>}
                                <div className="row">
                                    {!isForeman && createEdit.storage.material_id &&
                                        <div className="col full-width">
                                            <label>
                                                Цена
                                        </label>
                                            <input
                                                type="text"
                                                readOnly
                                                value={`${createEdit.storage.current_price ? createEdit.storage.current_price : createEdit.storage.single_price} лв.`}
                                                name="current_price"
                                                onChange={handleChange}
                                            />
                                        </div>}
                                    {createEdit.storage.material_id || createEdit.id ?
                                        <div className="col full-width">
                                            <label>
                                                Количество
                                        </label>
                                            <input
                                                type="number"
                                                defaultValue={createEdit.storage.quantity}
                                                name="quantity"
                                                min="1"
                                                onChange={handleChange}
                                            />
                                        </div> : ''}
                                </div>
                                <div className="buttons">
                                    {createEdit.loading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        <>
                                            <button className="btn-add">
                                                {createEdit.id ?
                                                    'Запазване' :
                                                    'Добавяне'
                                                }
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
import React, { useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//images
import dotsBlack from '../../../../../assets/images/pages/icons/dots-black.png'

function Worker(props) {
    const worker = props.worker
    const [eachWorker, setEachWorker] = useState({
        optionsMenu: false,
    })

    const handleMenuOptions = () => {
        setEachWorker(eachWorker => ({
            ...eachWorker,
            optionsMenu: eachWorker.optionsMenu ? false : true
        }))
    }

    const handleClose = () => {
        if (eachWorker.optionsMenu) {
            setEachWorker(eachWorker => ({
                ...eachWorker,
                optionsMenu: false
            }))
        }
    }

    const handleEdit = id => {
        props.handleEdit(id)
        handleClose()
    }

    const handleConfig = id => {
        props.handleConfig(id, 1)
        handleClose()
    }

    return (
        <tr key={worker.id}>
            <td>
                <div style={{ display: "flex" }}>
                    <div style={{ backgroundColor: worker.color && worker.color }} className='workcolor'>
                    </div>
                    <div className='worker'>
                        {worker.name}
                    </div>
                </div>
            </td>
            <td className="option">
                <img
                    src={dotsBlack}
                    alt="Опции"
                    title="Опции"
                    onClick={handleMenuOptions}
                />
                <ClickAwayListener onClickAway={handleClose}>
                    <div style={{ textAlign: 'left' }} className={`options ${eachWorker.optionsMenu ? 'active' : ''}`}>
                        <div className="menu-item" onClick={() => handleConfig(worker.id)}>
                            Конфигурация
                        </div>
                        <div className="menu-item" onClick={() => handleEdit(worker.id)}>
                            Редакция
                        </div>
                    </div>
                </ClickAwayListener>
            </td>
        </tr>
    )
}

export default Worker
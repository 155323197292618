import React from 'react'

function Material(props) {
    const each = props.material

    return (
        <tr key={each.id}>
            <td title={each.material.name}>
                {each.material.name.length > 10 ? each.material.name.substring(0, 10) + '...' : each.material.name}
            </td>
            <td title={each.material.specification}>
                {each.material.specification}
            </td>
            <td title={each.storage.name}>
                {each.storage.name.length > 10 ? each.storage.name.substring(0, 10) + '...' : each.storage.name}
            </td>
            <td title={each.supplier ? each.supplier.name : 'Не е посочен'}>
                {each.supplier ? each.supplier.name.length > 10 ? each.supplier.name.substring(0, 10) + '...' : each.supplier.name : 'Не е посочен'}
            </td>
            <td
                className={Number(each.material.min_quantity) >= each.quantity ? 'low-quantity' : ''}
                title={`${each.quantity} ${each.material.volumetype}`}
            >
                {each.quantity} {each.material.volumetype}
            </td>
            <td title={`${each.price} лв.`}>
                {each.price} лв.
            </td>
        </tr>
    )
}

export default Material
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

//componets
import Tabs from './tabs/Bar'
import Workers from './tabs/workers/Index'
import Machines from './tabs/machines/Index'
import MachinesType from './tabs/machineType/Index'
import Foremen from './tabs/foremen/Index'
import Materials from './tabs/materials/Index'
import Suppliers from './tabs/suppliers/Index'
import Storages from './tabs/storages/Index'
import Incomes from './tabs/incomes/Index'
import Expenses from './tabs/expenses/Index'
import Projects from './tabs/projects/Index'
import Work from './tabs/work/Index'
import Tasks from './tabs/tasks/Index'

function Index() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const [administration, setAdministration] = useState({
        data: {},
        refresh: false,
        currentTab: searchParams.get('tab') ? searchParams.get('tab') : 1,
    })

    const handleCurrentTab = currentTab => {
        setAdministration(administration => ({
            ...administration,
            currentTab: currentTab
        }))
    }

    return (
        <section id="administration">
            <Tabs
                currentTab={administration.currentTab}
                handleCurrentTab={handleCurrentTab}
            />
            {administration.currentTab == 1 ?
                <Foremen />
                :
                administration.currentTab == 2 ?
                    <Workers />
                    :
                    administration.currentTab == 3 ?
                        <Machines />
                        :
                        administration.currentTab == 4 ?
                            <MachinesType />
                            :
                            administration.currentTab == 5 ?
                                <Materials />
                                :
                                administration.currentTab == 6 ?
                                    <Suppliers />
                                    :
                                    administration.currentTab == 7 ?
                                        <Storages />
                                        :
                                        administration.currentTab == 8 ?
                                            <Incomes />
                                            :
                                            administration.currentTab == 9 ?
                                                <Expenses />
                                                :
                                                administration.currentTab == 10 ?
                                                    <Projects />
                                                    :
                                                    administration.currentTab == 11 ?
                                                        <Work />
                                                        :
                                                        administration.currentTab == 12 ?
                                                            <Tasks />
                                                            :
                                                            ''
            }
        </section>
    )
}

export default Index
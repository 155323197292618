import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import imageImage from '../../../../../assets/images/modals/icons/image.png'
import deleteIcon from '../../../../../assets/images/modals/icons/delete.png'

//modals
import SuccessModal from '../../../../modals/Success'
import AttentionModal from '../../../../modals/Attention'

function Complete(props, ref) {

    const [modal, setModal] = useState({
        overlay: false,
        modal: false,
        id: null,
        task: {},
        loading: false,
        size: 0,
        neededSize: 0,
        sizeName: ''
    })

    const [files, setFiles] = useState([])

    //refs
    const formRef = useRef(null)
    const uploadInputRef = useRef(null)
    const successModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //end refs

    const form = formRef.current

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {

        setModal(prev => ({
            ...prev,
            task: {}
        }))

        setFiles([])

        form.reset()

        Api.get('tasks/getid?id=' + id)
            .then(res => {
                let needImage = res.data.need_image
                let needVolume = res.data.volume_id

                let needsSomething = needImage || needVolume
                setModal(prev => ({
                    ...prev,
                    task: res.data,
                    needImage: needImage,
                    needVolume: needVolume,
                    neededSize: res.data.size,
                    sizeName: res.data.volume.name
                }))

                if (needsSomething) {
                    setModal(modal => ({
                        ...modal,
                        overlay: true,
                        id: id
                    }))
                    setTimeout(() => {
                        setModal(modal => ({
                            ...modal,
                            modal: true,
                        }))
                    }, 50)
                } else {
                    Api.post('tasks/complete', {
                        id: id
                    })
                        .then(res => handleSuccess(res.data))
                }
            })
    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setModal(modal => ({
            ...modal,
            modal: false,
        }))

        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                overlay: false,
            }))
        }, 100)
    }

    const handleSave = e => {
        e.preventDefault()

        setModal(prev => ({
            ...prev,
            loading: true
        }))

        removeValidations()

        let data = new FormData(form)

        data.append('id', modal.id)

        files.map(f => data.append('images[]', f))

        Api.post('tasks/complete', data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        })
            .then(res => {
                if (res.data.is_completed == 0) {
                    setModal(prev => ({
                        ...prev,
                        size: res.data.completed_size
                    }))
                    attentionModalRef.current.open()
                    props.handleRefreshData()
                }
                else handleSuccess(res.data)
                handleClose()
            })
            .catch(err => {
                let fields = err.response && err.response.data && err.response.data.errors

                if (typeof fields === 'object') {
                    Object.entries(fields).map(i => {
                        if (i[0]) {
                            let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                            inputs.forEach(i => i.classList.add('validate'))
                        }
                    })
                }
            })
            .finally(() => {
                setModal(prev => ({
                    ...prev,
                    loading: false
                }))
            })
    }

    const handleOk = () => handleClose()

    const handleSuccess = data => {
        let modal = successModalRef.current

        modal.open()
        props.handleUpdateTask(data)
        props.handleRefreshData()
    }

    const handleOpenUpload = e => {
        e.preventDefault()

        let input = uploadInputRef.current

        input.click()
    }

    const handleAttachFiles = e => {
        let attachments = Array.from(e.target.files)

        setFiles(files.concat(attachments))
    }

    const handleDetachFile = index => {
        let filtered = files.filter((f, i) => {
            return Number(i) !== Number(index)
        })

        setFiles(filtered)
    }

    const calcFileSize = size => {
        return size / 1000
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <>
            <SuccessModal
                ref={successModalRef}
                mainMessage="Задачата беше завършена успешно!"
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage={`Обем на работа ${modal.size}/${modal.neededSize} ${modal.sizeName}.`}
                secondaryMessage="За да завършите попълнене изискванията на задачата."
                handleOk={handleOk}
            />
            <form ref={formRef} className={`${modal.overlay ? 'visible' : ''} overlay`}>
                <div className={`${modal.modal ? 'show' : ''} box`}>
                    <img src={imageImage} className="main-image" />
                    <h3>
                        За да завършите задачата е нужно да
                        {modal.needImage && modal.needVolume
                            ? ' прикачите снимки и да опишете свършената работа'
                            : modal.needImage
                                ? ' прикачите снимки'
                                : modal.needVolume
                                    ? ' опишете свършената работа'
                                    :
                                    ''
                        }
                    </h3>
                    <p>
                        Опишете обема свършена работа и прикачете нужните изображения
                    </p>
                    {modal.needVolume
                        ?
                        <div className="row">
                            <div className="col full-width">
                                <label>
                                    Описание на обема свършена работа
                                </label>
                                <div className="row">
                                    <input
                                        type="number"
                                        name="completed_size"
                                        min="0"
                                        max={modal.task.size}
                                    />
                                    <span className="top-auto">
                                        {modal.task.volume ? modal.task.volume.name : ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                    {modal.needImage
                        ?
                        <>
                            <div className="attachments scroller-list">
                                {files.map((a, i) =>
                                    <div key={'a-' + i} className="attachment selected-list">
                                        <div className="name">
                                            {a.name}
                                        </div>
                                        <div className="size">
                                            {calcFileSize(a.size)} KB
                                        </div>
                                        <img
                                            src={deleteIcon}
                                            alt="Изтриване"
                                            onClick={() => handleDetachFile(i)}
                                        />

                                    </div>
                                )}
                            </div>

                            <div className="attach-image" name="images">
                                <input
                                    ref={uploadInputRef}
                                    type="file"
                                    accept="image/*"
                                    onChange={handleAttachFiles}
                                    multiple
                                    hidden
                                />
                                <button 
                                    className="btn-extra-add" 
                                    onClick={handleOpenUpload}
                                    style={{    
                                        backgroundPosition: '20px center'
                                    }}
                                >
                                    Добавяне на изображения
                                </button>
                            </div>
                        </>
                        :
                        ''
                    }

                    <div className="buttons">
                        {modal.loading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <button className="btn-cancel" onClick={handleClose}>
                                    Отказ
                                </button>
                                <button className="btn-add" onClick={handleSave}>
                                    Запазване
                                </button>
                            </>
                        }
                    </div>

                </div>
            </form>
        </>
    )
}

export default forwardRef(Complete)
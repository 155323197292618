import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import closeIcon from '../../../../../assets/images/modals/icons/close.png'

//modals
import SuccessModal from '../../../../modals/Success'
import RemoveModal from '../../../../modals/Remove'

//contexts
import { useFinanceContext } from '../../../../../_contexts/FinananceContex'

function CreateEdit(props, ref) {
    const financeContext = useFinanceContext()
    const date = new Date()

    const materials = {
        date: `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? 0 : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? 0 : ''}${date.getDate()}`,
        project_id: null,
        income_id: null,
        name: '',
        description: '',
        price: '',
    }

    const [createEdit, setCreateEdit] = useState({
        overlay: false,
        modal: false,
        loading: false,
        //edit
        mainLoading: false,
        id: null,
        materials: materials,
        original: materials,
    })

    // createEdit.materials.ymd



    const form = document.getElementById('edit-form')

    //refs
    const successModalRef = useRef(null)
    const removeModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        getCurrentDate();
        setCreateEdit(createEdit => ({
            ...createEdit,
            overlay: true,
            id: id,
            mainLoading: id ? true : false,
        }))
        setTimeout(() => {
            setCreateEdit(createEdit => ({
                ...createEdit,
                modal: true,
            }))
        }, 50)

        if (id) {
            Api.get(`income/getbyid?id=${id}`)
                .then(res => {
                    setCreateEdit(createEdit => ({
                        ...createEdit,
                        materials: res.data,
                        original: res.data,
                    }))
                })
                .finally(() => stopMainLoading())
        }
    }

    const getCurrentDate = () => {
        let current_date = new Date();
        let dd = String(current_date.getDate()).padStart(2, '0');
        let mm = String(current_date.getMonth() + 1).padStart(2, '0');
        let yyyy = current_date.getFullYear();
        current_date = yyyy + '-' + mm + '-' + dd;
        let ymd = 'date'

        setCreateEdit(prev => ({
            ...prev,
            materials: {
                ...prev.materials,
                [ymd]: current_date
            }
        }))
    }

    const handleClose = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            modal: false,
        }))
        removeModalRef.current.close()
        setTimeout(() => {
            document.getElementById('edit-form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)

            removeValidations()
            setCreateEdit(createEdit => ({
                ...createEdit,
                overlay: false,
                mainLoading: true,
                id: null,
                materials: materials,
                original: materials
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()

        let url

        if (createEdit.id) {
            data.append('id', createEdit.id)
            url = 'income/edit'
        }
        else url = 'income/create'

        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors

                Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                        let inputsWithClass = form.querySelectorAll("[className='" + i[0] + "']")

                        inputs.forEach(i => i.classList.add('validate'))
                        inputsWithClass.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: false,
        }))
    }

    const stopMainLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            mainLoading: false,
        }))
    }

    const handleDelete = e => {
        e.preventDefault()
        removeModalRef.current.open()
    }
    const handleRemoveCallback = () => {
        startLoading()
        handleClose()
        Api.post('income/delete', {
            id: createEdit.id
        })
            .then(() => {
                stopLoading()
                props.handleRefreshData()
            })
    }

    const handleChange = e => {
        let name = e.target.name
        let val = e.target.value


        setCreateEdit(createEdit => ({
            ...createEdit,
            materials: {
                ...createEdit.materials,
                [name]: val
            }
        }))
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <div className={`${createEdit.overlay ? 'visible' : ''} overlay`}>
            <RemoveModal
                mainMessage="Сигурни ли сте, че искате да изтриете тоз приход?"
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                loading={createEdit.loading}
            />
            <SuccessModal
                mainMessage={createEdit.id ? 'Успешно редактирахте приход!' : 'Прихода е добавен успешно!'}
                secondaryMessage="Може да откриете промените в списъка с приходи."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <div className="scroller">
                <div className={`${createEdit.modal ? 'show' : ''} box box-small`}>
                    <img src={closeIcon} className="close" onClick={handleClose} />
                    <form id="edit-form" onSubmit={handleSubmit} className="create-task">
                        <h3>
                            {createEdit.id ?
                                'Редакция на приход' :
                                'Добавяне на приход'
                            }
                        </h3>
                        {createEdit.mainLoading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Проект
                                    </label>
                                        <select
                                            name="project_id"
                                            defaultValue={createEdit.materials.project_id && createEdit.materials.project_id}
                                            onChange={handleChange}
                                        >
                                            <option selected disabled>
                                                Избор
                                        </option>
                                            {financeContext.projects.map(project =>
                                                <option key={project.id} value={project.id}>
                                                    {project.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Тип приход
                                    </label>
                                        <select
                                            name="income_id"
                                            defaultValue={createEdit.materials.income_id && createEdit.materials.income_id}
                                            onChange={handleChange}
                                        >
                                            <option selected disabled>
                                                Избор
                                        </option>
                                            {financeContext.incomeTypes.map(income =>
                                                <option key={income.id} value={income.id}>
                                                    {income.name}
                                                </option>
                                            )}
                                            <option value="0">
                                                Друг
                                        </option>
                                        </select>
                                    </div>
                                </div>
                                {createEdit.materials.income_id == 0 && <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Друг тип
                                    </label>
                                        <input
                                            type="text"
                                            defaultValue={createEdit.materials.name && createEdit.materials.name}
                                            name="name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col">
                                        <label>
                                            Дата
                                    </label>
                                        <input
                                            type="date"
                                            value={createEdit.materials.date && createEdit.materials.date}
                                            name="date"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>
                                            Стойност(в лв.)
                                    </label>
                                        <input
                                            type="number"
                                            defaultValue={createEdit.materials.price && createEdit.materials.price}
                                            name="price"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="buttons">
                                    {createEdit.loading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        <>
                                            {createEdit.id && <button className="btn-delete" onClick={handleDelete}>
                                                Изтриване
                                        </button>}
                                            <button className="btn-add">
                                                {createEdit.id ?
                                                    'Запазване' :
                                                    'Добавяне'
                                                }
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
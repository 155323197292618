import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import notFoundIcon from '../../../../../assets/images/pages/icons/not-found.png'
import editIcon from '../../../../../assets/images/pages/icons/edit.png'
import deadlineIcon from '../../../../../assets/images/pages/icons/deadline.png'
import finishedIcon from '../../../../../assets/images/pages/icons/finished.png'
import readyIcon from '../../../../../assets/images/pages/icons/ready.png'
import returnIcon from '../../../../../assets/images/pages/icons/return.png'
import priorityIcon from '../../../../../assets/images/pages/icons/priority.png'

//modals
import QuestionModal from '../../../../modals/Question'
import QuestionModalFinish from '../../../../modals/Question'
import CompleteModal from '../modals/Complete'

//contexts
import { useAuthContext } from '../../../../../_contexts/AuthContext'

function List(props) {
    const auth = useAuthContext()
    const inCity = useParams().inCity == 1
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    //refs
    const completeModalRef = useRef(null)
    const questionModalRef = useRef(null)
    const questionModalFinishRef = useRef(null)
    //end refs

    const handleEdit = id => props.handleEditTask(id)

    const handleFinishTask = (id, type) => {
        if (type == 1) completeModalRef.current.open(id)
        else questionModalFinishRef.current.open(id)
    }

    const handleConfirmFinishTask = id => completeModalRef.current.open(id)

    const handleRepeatTask = id => questionModalRef.current.open(id)

    const handleConfirmRepleatTask = id => {
        Api.post('tasks/return/completed', {
            id: id
        })
            .then(() => props.handleRefreshData())
    }

    const handleStartTask = id => {
        Api.post(`/tasks/mark-active?id=${id}`)
        .then(() => props.handleRefreshData())
    }


return (
    <>
        <CompleteModal
            ref={completeModalRef}
            handleUpdateTask={data => props.handleUpdateTask(data)}
            handleRefreshData={() => props.handleRefreshData()}
        />
        <QuestionModal
            ref={questionModalRef}
            mainMessage='Сигурни ли сте че искате да върнете задачата за изпълнение?'
            seoncaryMessage='Въведените данни биват съхранявани.'
            handleCallback={handleConfirmRepleatTask}
        />

        <QuestionModalFinish
            ref={questionModalFinishRef}
            mainMessage='Сигурни ли сте, че искате да завършите тази задача?'
            seoncaryMessage='След завършване задачата ще бъде със статус завършена.'
            handleCallback={handleConfirmFinishTask}
        />

        <ul className="tasks-list">
            {props.loading ?
                <img src={loader} alt="loading" />
                :
                props.tasks.length == 0 ?
                    <div className="no-results">
                        <img src={notFoundIcon} alt="Няма резултати" />
                            Не бяха намерени резултати <br />
                            от Вашето търсене.
                        </div>
                    :
                    props.tasks.map(task =>
                        <li key={'task-' + task.id} className={task.current_task ? 'current-task' : ''}>
                            <div className="period">
                                {task.is_completed ?
                                    <span className="finished">
                                        <img src={finishedIcon} alt="Крайна дата" />
                                        <time>
                                            {task.date_complete}
                                        </time>
                                    </span>
                                    :
                                    <>
                                        <span>
                                            <img src={priorityIcon} alt="Спешна" title="Приоритетна задача" />
                                            <img src={deadlineIcon} alt="Начална дата" title="Начална дата" />
                                            <time>
                                                {task.to_dmy}
                                            </time>
                                        </span>
                                        {(isAdmin || isForeman) && <img
                                            src={editIcon}
                                            alt="Редакция"
                                            title="Редакция"
                                            className="edit-task"
                                            onClick={() => handleEdit(task.id)}
                                        />}
                                    </>
                                }

                            </div>
                            <h2>
                                {task.name}
                            </h2>
                            <p className="client">
                                {inCity ? 'Адрес' : 'Междушахтие'}:
                                    <span className='color'>
                                    <i style={{ backgroundColor: task.stage.color }}>

                                    </i>
                                    {task.stage.name ? task.stage.name : 'от' + ' ' + task.stage.from + '  ' + 'до' + ' ' + task.stage.to}
                                </span>
                            </p>
                            <p className="client" style={task.is_completed == 0 ? { marginBottom: 30 + 'px' } : {}}>
                                Изпълнител:
                                    <span>
                                    {task.workers.length > 0 ? task.workers.map(worker => worker.name).join(', ') : 'Не е посочен.'}
                                </span>
                            </p>
                            {task.images.length > 0 && task.is_completed == 1 ?
                                <h3 className="view" onClick={() => props.handleViewImages(task.id)}>
                                    Виж изображенията({task.images.length})
                                    </h3>
                                :
                                ''
                            }
                            <div className="bar" style={isWorker && task.is_completed ? { marginTop: 50 + 'px' } : {}}>
                                <div className="progress" style={{ width: task.progress + '%' }}>
                                    <p>
                                        {task.progress}%
                                        </p>
                                </div>
                            </div>
                            <div className="buttons">
                                {
                                    !task.current_task && task.is_completed == 0 ?
                                        <button onClick={() => handleStartTask(task.id)}>
                                            <img src={readyIcon} alt="Готово" />
                                                Започни
                                            </button> :
                                        task.is_completed ?
                                            !isWorker && <button onClick={() => handleRepeatTask(task.id)}>
                                                <img src={returnIcon} alt="Върни" />
                                            Върни за изпълнение
                                            </button>
                                            :
                                            <button onClick={() => handleFinishTask(task.id, task.has_volume || task.need_image ? 1 : 0)}>
                                                <img src={readyIcon} alt="Готово" />
                                                Готово
                                                </button>
                                }
                            </div>
                        </li>
                    )
            }
        </ul>
    </>
)
}

export default List
import React, { createContext, useContext, useState, useMemo } from "react"

export const ProjcetContext = createContext({})

const Project = props => {
    const [project, setProject] = useState({
        data: {
            stages: [],

        }
    })

    const setData = data => {
        setProject(project => ({
            ...project,
            data: data
        }))
    }

    const projectData = useMemo(() => ({
        ...project,
        setData
    }), [project])

    return <ProjcetContext.Provider value={projectData} {...props} />
}

export const useProjcetContext = () => useContext(ProjcetContext)

export default Project
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

//contexts
import { useAuthContext } from '../../../_contexts/AuthContext'

function Tabs(props) {
    const history = useHistory()
    const auth = useAuthContext()

    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    const [tabs, setTabs] = useState({
        data: [
            {
                id: 1,
                name: 'Приходи',
            },
            {
                id: 2,
                name: 'Разходи',
            },
            {
                id: 3,
                name: 'Счетоводство',
            }
        ]
    })

    const handleActiveTab = e => {
        let tabId = e.target.getAttribute('data-tab')

        history.push(`?tab=${tabId}`)

        props.handleCurrentTab(tabId)
    }

    useEffect(() => {
        if (isForeman) {
            history.push(`?tab=2`)
            props.handleCurrentTab(2)
        }
    }, [])

    return (
        <ul className="tabs wrapper">
            {isForeman ? <li
                data-tab={1}
                className="active"
            >
                Разходи
            </li> :
                tabs.data.map(tab =>
                    <li
                        data-tab={tab.id}
                        className={tab.id == props.currentTab ? 'active' : ''}
                        onClick={handleActiveTab}
                    >
                        {tab.name}
                    </li>
                )}
        </ul>
    )
}

export default Tabs
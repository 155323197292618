import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import closeIcon from '../../../../../assets/images/modals/icons/close.png'

//modals
import SuccessModal from '../../../../modals/Success'
import RemoveModal from '../../../../modals/Remove'
import AttentionModal from '../../../../modals/Attention'

//components
import Dropdown from '../../../../app/Dropdown'

//contexts
import { useFinanceContext } from '../../../../../_contexts/FinananceContex'
import { useAuthContext } from '../../../../../_contexts/AuthContext'

function CreateEdit(props, ref) {
    const financeContext = useFinanceContext()
    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    var current_date = new Date();
    var dd = String(current_date.getDate()).padStart(2, '0');
    var mm = String(current_date.getMonth() + 1).padStart(2, '0');
    var yyyy = current_date.getFullYear();
    current_date = yyyy + '-' + mm + '-' + dd;

    const materials = {
        date: new Date(),
        project_id: null,
        expense_id: null,
        user_id: null,
        payment_type_id: 2,
        trip_worker: {
            name: [],
            id: []
        },
        worker: {
            name: ''
        },
        name: '',
        price: '',
    }

    const [createEdit, setCreateEdit] = useState({
        overlay: false,
        modal: false,
        loading: false,
        //edit
        mainLoading: false,
        id: null,
        materials: materials,
        original: materials,
        allUsers: []
    })

    const form = document.getElementById('balance-form')

    //refs
    const successModalRef = useRef(null)
    const removeModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            overlay: true,
            id: id,
            mainLoading: id ? true : false,
        }))
        setTimeout(() => {
            setCreateEdit(createEdit => ({
                ...createEdit,
                modal: true,
            }))
        }, 50)


        Api.get(`workers/foremans`)
            .then(res => {
                setCreateEdit(createEdit => ({
                    ...createEdit,
                    allUsers: res.data
                }))
            })

        if (id) {
            Api.get(`expense/getbyid?id=${id}`)
                .then(res => {
                    setCreateEdit(createEdit => ({
                        ...createEdit,
                        materials: res.data,
                        original: res.data,
                    }))
                })
                .finally(() => stopMainLoading())
        }
    }

    const handleClose = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            modal: false,
        }))
        removeModalRef.current.close()
        setTimeout(() => {
            document.getElementById('balance-form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)

            removeValidations()
            setCreateEdit(createEdit => ({
                ...createEdit,
                overlay: false,
                mainLoading: true,
                id: null,
                materials: materials,
                original: materials
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()

        let url

        if (createEdit.id) {
            data.append('id', createEdit.id)
            url = 'expense/edit'
        }
        else url = 'expense/money-transfer'

        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors
                console.log(fields)
                Object.entries(fields).map(i => {
                    if (i[0]) {
                        console.log(i[0])
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                        let inputsWithClass = form.querySelectorAll("[className='" + i[0] + "']")

                        inputs.forEach(i => i.classList.add('validate'))
                        inputsWithClass.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: false,
        }))
    }

    const stopMainLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            mainLoading: false,
        }))
    }

    const handleDelete = e => {
        e.preventDefault()
        removeModalRef.current.open()
    }
    const handleRemoveCallback = () => {
        startLoading()
        handleClose()
        Api.post('expense/delete', {
            id: createEdit.id
        })
            .then(() => {
                stopLoading()
                props.handleRefreshData()
            })
    }

    const handleChange = e => {
        let name = e.target.name
        let val = e.target.value

        console.log(name)
        console.log(val)

        setCreateEdit(createEdit => ({
            ...createEdit,
            materials: {
                ...createEdit.materials,
                [name]: val
            }
        }))
    }

    const handleRadio = e => {
        let val
        if (e.target) val = e.target.value
        else val = e

        setCreateEdit(createEdit => ({
            ...createEdit,
            materials: {
                ...createEdit.materials,
                payment_type_id: val
            }
        }))
    }

    const handleClickForemans = () => {
        if (!createEdit.materials.project_id) attentionModalRef.current.open()
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <div className={`${createEdit.overlay ? 'visible' : ''} overlay`}>
            <RemoveModal
                mainMessage="Сигурни ли сте, че искате да изтриете този разход?"
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                loading={createEdit.loading}
            />
            <SuccessModal
                mainMessage={createEdit.id ? 'Успешно редактирахте баланс!' : 'Баланса е добавен успешно!'}
                secondaryMessage="Може да откриете промените в списъка с разходи."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Моля изберете проект!"
                secondaryMessage="Проекта и бригадирите са пряко обвързани."
            />
            <div className="scroller">
                <div style={{ width: '390px' }} className={`${createEdit.modal ? 'show' : ''} box box-small`}>
                    <img src={closeIcon} className="close" onClick={handleClose} />
                    <form id="balance-form" onSubmit={handleSubmit} className="create-task">
                        <h3>
                            {createEdit.id ?
                                'Редакция на баланс' :
                                'Добавяне на баланс'
                            }
                        </h3>
                        {createEdit.mainLoading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Проект
                                    </label>
                                        <select
                                            name="project_id"
                                            defaultValue={createEdit.materials.project_id && createEdit.materials.project_id}
                                            onChange={handleChange}
                                        >
                                            <option selected disabled>
                                                Избор
                                        </option>
                                            {financeContext.projects.map(project =>
                                                <option key={project.id} value={project.id}>
                                                    {project.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <input
                                    type='hidden'
                                    name='expense_id'
                                    value='7'
                                />

                                <div className="row">
                                    <div className="col full-width" onClick={handleClickForemans}>
                                        <label>
                                            Бригадир
                                    </label>
                                        <Dropdown
                                            inputIdName="user_id"
                                            url='foremans/getbyproject'
                                            params={{
                                                project_id: createEdit.materials.project_id
                                            }}
                                            loadDataOnFocus={true}
                                            inputClassName="user_id"
                                            inputValue={createEdit.materials.trip_worker.name}//ime
                                            inputIdValue={createEdit.materials.trip_worker.id}//id
                                            renderText={data => {
                                                return <>{data.name}</>
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className={`col ${createEdit.materials.expense_id == 4 ? 'disabled-box' : ''}`}>
                                        <label className="checkbox">
                                            Кеш
                                        <input autoComplete="off" role="presentation"
                                                type="radio"
                                                value="2"
                                                name="payment_type_id"
                                                checked={createEdit.materials.payment_type_id ? createEdit.materials.payment_type_id == 2 : true}
                                                onChange={handleRadio}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className=
                                        {`col ${createEdit.materials.expense_id == 6 || createEdit.materials.expense_id == 4 ? 'disabled-box' : ''}`}>
                                        <label className="checkbox">
                                            Kарта
                                        <input autoComplete="off" role="presentation"
                                                type="radio"
                                                value="3"
                                                name="payment_type_id"
                                                checked={createEdit.materials.payment_type_id == 3}
                                                onChange={handleRadio}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <label>
                                            Дата
                                    </label>
                                        <input
                                            type="date"
                                            defaultValue={createEdit.materials.ymd ? createEdit.materials.ymd : current_date}
                                            name="date"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>
                                            {'Стойност(в лв.)'}
                                        </label>
                                        <input
                                            type="number"
                                            min='0'
                                            defaultValue={createEdit.materials.price && createEdit.materials.price}
                                            name="price"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="buttons">
                                    {createEdit.loading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        <>
                                            {createEdit.id && <button className="btn-delete" onClick={handleDelete}>
                                                Изтриване
                                        </button>}
                                            <button className="btn-add">
                                                {createEdit.id ?
                                                    'Запазване' :
                                                    'Добавяне'
                                                }
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
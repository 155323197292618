import React, { useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//components
import Worker from './Worker'

//modals
import RemoveModal from '../../../../modals/Remove'
import AttentionModal from '../../../../modals/Attention'

function Table(props) {
    const workers = props.workers
    const isForeman = props.isForeman
    //refs
    const removeModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //end refs

    const handleRemoveWorker = id => {
        if (props.count == 1) attentionModalRef.current.open()
        else removeModalRef.current.open(id)
    }
    const handleRemoveCallback = id => {
        Api.get(`brigades/removeworker?id=${id}`)
            .then(() => props.removeWorker(id))
            .finally(() => props.removeWorker(id))
    }

    return (
        <>
            <RemoveModal
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                mainMessage={`Сигурни ли сте, че искате да премахнете този работник от бригадата?`}
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Не може да премахнете последния работник от бригадата"
                secondaryMessage="Може да изтриете бригадата през бутона за редакция."
            />
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Име</th>
                            <th>Длъжност</th>
                            {!isForeman && <th>Дейност</th>}
                            {!isForeman && <th className="option">
                                Опции
                        </th>}
                        </tr>
                    </thead>
                    <tbody>

                        {/* Бригадир */}

                        {props.worker_id == 9 || props.worker_id == '' ? props.foreman.map(t => <tr>
                            <td>
                                {t.name}
                            </td>
                            <td>
                                Бригадир
                        </td>
                            {!isForeman && <td>
                                <button className='btn-transfer' onClick={() => props.handleOpen(t.id)}>
                                    Дейност
                            </button>
                            </td>}
                            {!isForeman && <td className="option">

                            </td>}
                        </tr>) : ''}
                        {/* Край Бригадир */}

                        {workers.map(worker =>
                            <Worker
                                isForeman={isForeman}
                                worker={worker}
                                foreman={props.foreman}
                                handleEdit={props.handleEdit}
                                removeWorker={props.removeWorker}
                                handleRemoveWorker={handleRemoveWorker}
                                handleOpen={props.handleOpen}
                            />
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Table
import React, { useImperativeHandle, forwardRef, useState, useRef } from 'react'
import Api from '../../_helpers/Api'

//images
import loader from '../../assets/images/app/loader.svg'

//modals
import SuccessModal from './Success'

function Question(props, ref) {
    const texatarea = document.getElementById('report')

    const [modal, setModal] = useState({
        blur: false,
        popup: false,
        id: null,
        loading: false,
        reportMessage: '',
    })

    //refs
    const successModalRef = useRef(null)
    //end refs

    useImperativeHandle(ref, () => ({
        open: (id, reportMessage) => handleOpen(id, reportMessage),
        close: () => handleClsoe()
    }))

    const handleOpen = (id, reportMessage) => {
        setModal(modal => ({
            ...modal,
            blur: true,
            id: id,
            reportMessage: reportMessage ? reportMessage : ''
        }))
        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                popup: true,
            }))
        }, 100)
    }

    const handleClsoe = () => {
        setModal(modal => ({
            ...modal,
            popup: false,
        }))
        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                blur: false,
                id: null,
            }))
        }, 50)
    }

    const handleConfirm = () => {
        texatarea.classList.remove('validate')
        setModal(modal => ({
            ...modal,
            loading: true
        }))

        Api.get(`stages/note?id=${modal.id}&note=${texatarea.value}`)
            .then(res => {
                handleClsoe()
                props.handleReportSuccess(modal.id, texatarea.value)
            })
            .catch(() => texatarea.classList.add('validate'))
            .finally(() => {
                setModal(modal => ({
                    ...modal,
                    loading: false
                }))

            })
    }

    return (
        <div className={`overlay ${modal.blur ? 'visible' : ''}`}>
            <SuccessModal
                ref={successModalRef}
                mainMessage="Докладът Ви е изпратен успещно!"
                secondaryMessage="Докладът ще бъде разгледан от адмиистраторите на Prespa."
            />
            <div className={`${modal.popup ? 'show' : ''} box`}>
                <h2>
                    Доклад
                </h2>
                <p style={{ textAlign: 'left' }}>
                    С кратък текст опишете проблема, който искате да докладвате.
                </p>
                <div className="row">
                    <div className="col full-width">
                        <textarea id="report" placeholder="Въведете доклад..." defaultValue={modal.reportMessage}></textarea>
                    </div>
                </div>
                <div className="buttons">
                    {modal.loading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <button onClick={handleClsoe}>
                                Отказ
                            </button>
                            <button className="btn-primary" onClick={handleConfirm}>
                                Изпращане
                            </button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Question)
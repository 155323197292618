import React, { useEffect, useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import { useHistory } from 'react-router-dom'
import Api from '../../../../../_helpers/Api'

//images
import dotsIcon from '../../../../../assets/images/pages/icons/dots.png'
import addressDefaultIcon from '../../../../../assets/images/pages/icons/address-default.png'
import addressCheckedIcon from '../../../../../assets/images/pages/icons/address-checked.png'
import noteIcon from '../../../../../assets/images/pages/icons/note.png'

function Stage(props) {
    const history = useHistory()

    const [stage, setStage] = useState({
        optionsMenu: {
            options: false,
            type: false,
            smr: false,
            road: false,
            firstSide: false,
            secondSide: false,
        },
        currentTask: {},
        filter: {
            job: '',
            process: '',
            number: '',
            note: ''
        },
    })

    const data = props.data

    useEffect(() => {
        let currentTask = data.tasks.filter(task => {
            return task.current_task == 1
        })
        setStage(stage => ({
            ...stage,
            currentTask: currentTask
        }))
    }, [])

    const handleMenuOptions = (options, e) => {
        if (e && e.target.classList.contains('tick')) return
        setStage(stage => ({
            ...stage,
            optionsMenu: {
                ...stage.optionsMenu,
                [options]: stage.optionsMenu[options] ? false : true
            }
        }))
    }

    const handleClose = options => {
        if (stage.optionsMenu[options]) {
            setStage(stage => ({
                ...stage,
                optionsMenu: {
                    ...stage.optionsMenu,
                    [options]: false
                }
            }))
        }
    }

    const handleEdit = (id, col) => props.handleEdit(id, col)

    const handleAddTask = id => history.push(`${history.location.pathname}?stage_id=${id}&create_task=1`)

    const handleChange = (addressId, optionId, parameter) => {
        Api.get(`stages/quickedit?id=${addressId}&${parameter}=${optionId}`)
        props.handleRealtimeChange(addressId, optionId, parameter)
    }

    return (
        <>
            <li key={data.id}>
                <div
                    className="address-holder"
                    style={{
                        backgroundColor: data.job_type.color
                    }}
                >
                    <div id='element-holder' className="element-holder" >
                        <div className="element" onClick={() => handleMenuOptions('type')}>
                            <p>
                                {data.job_type ? data.job_type.name : 'Не е посочен'}
                            </p>
                            <ClickAwayListener onClickAway={() => handleClose('type')}>
                                <div className={`not-clickable options ${stage.optionsMenu.type ? 'active' : ''}`}>
                                    {props.jobTypes.map(type =>
                                        <div
                                            key={type.id}
                                            className="menu-item"
                                            onClick={() => handleChange(data.id, type.id, 'job_id')}
                                        >
                                            {type.name}
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                        </div>
                    </div>
                    <div id='element-holder' className="element-holder small-col report" >
                        <img src={data.note ? noteIcon : data.process == 0 ? addressDefaultIcon : addressCheckedIcon} onClick={() => handleMenuOptions('smr')} />
                        <ClickAwayListener onClickAway={() => handleClose('smr')}>
                            <div className={`not-clickable options ${stage.optionsMenu.smr ? 'active' : ''}`}>
                                {data.note ?
                                    <>
                                        <div
                                            className="menu-item"
                                            data-checked={data.process}
                                            data-value={data.id}
                                            onClick={() => props.handleEditReport(data.id)}
                                        >
                                            Редакция
                                    </div>
                                        <div
                                            className="menu-item"
                                            onClick={() => props.handleRemoveReport(data.id)}
                                        >
                                            Няма проблем
                                    </div>
                                    </>
                                    :
                                    <>
                                        <div
                                            className="menu-item"
                                            data-checked={data.process}
                                            data-value={data.id}
                                            onClick={e => props.handleStageProcess(e)}
                                        >
                                            {data.process == 0 ? 'Начало' : 'Отказ'}
                                        </div>
                                        <div
                                            className="menu-item not-clickable"
                                            onClick={() => props.handleReport(data.id)}
                                        >
                                            Има проблем
                                    </div>
                                    </>
                                }
                            </div>
                        </ClickAwayListener>
                        {data.note && <div className="info">
                            {data.note}
                        </div>}
                    </div>
                    <div id='element-holder' className="element-holder" >
                        <div className="element report" style={data.tasks.length > 0 ? { cursor: 'default' } : {}}>
                            {data.tasks.length > 0 ?
                                <>
                                    <img
                                        src={stage.currentTask[0] && stage.currentTask[0].is_completed == 0 ? addressDefaultIcon : addressCheckedIcon}
                                        className="tick"
                                        onClick={() => stage.currentTask[0] && stage.currentTask[0].is_completed == 0 && props.handleFinishTask(stage.currentTask[0].id, stage.currentTask[0].has_volume || stage.currentTask[0].need_image ? 1 : 0)}
                                        style={stage.currentTask[0] && stage.currentTask[0].is_completed == 1 ? { cursor: 'auto' } : {}}
                                    />
                                    <p title={stage.currentTask[0] ? stage.currentTask[0].name : 'Няма започната задача.'} style={{ maxWidth: '70px' }}>
                                        {stage.currentTask[0] ? stage.currentTask[0].name : 'Няма започната задача.'}
                                    </p>
                                </>
                                :
                                <button className="btn-extra-add" onClick={() => handleAddTask(data.id)}>
                                    Добави
                            </button>
                            }
                            {stage.currentTask.length > 0 && <div className="info">
                                {stage.currentTask.length > 0 && stage.currentTask.map(task =>
                                    <>
                                        <h3>
                                            {task.name ? task.name : ''}
                                        </h3>
                                        <p>
                                            <b>Срок:</b> {task.from_dmy} -  {task.to_dmy}
                                        </p>
                                        <hr style={{ margin: '10px 0px' }} />
                                    </>
                                )}
                            </div>}

                        </div>
                    </div>
                    <div id='element-holder' className="element-holder small-col">
                        <img src={data.clean == 0 ? addressDefaultIcon : addressCheckedIcon} onClick={() => props.handleItsClean(data.id, data.clean)} />
                    </div>
                    {
                        props.project.have_road ?
                            <div id='element-holder' className="element-holder">
                                <div className="element" onClick={e => handleMenuOptions('road', e)}>
                                    {data.road ?
                                        <>
                                            <img
                                                src={data.road_fix == 0 ? addressDefaultIcon : addressCheckedIcon}
                                                className="tick"
                                                onClick={() => props.handleFinishRoad(data.id, data.road_fix)}
                                            />
                                            <p>
                                                {data.road.name}
                                            </p>
                                        </>
                                        :
                                        <button className="btn-extra-add">
                                            Добави
                                </button>
                                    }
                                    <ClickAwayListener onClickAway={() => handleClose('road')}>
                                        <div className={`not-clickable options ${stage.optionsMenu.road ? 'active' : ''}`} >
                                            {props.roads.map(road =>
                                                <div
                                                    key={road.id}
                                                    className="menu-item"
                                                    onClick={() => handleChange(data.id, road.id, 'road_id')}
                                                >
                                                    {road.name}
                                                </div>
                                            )}
                                        </div>
                                    </ClickAwayListener>
                                </div>
                            </div>
                            :
                            ''
                    }
                    {
                        props.project.have_sidewalk ?
                            <div id='element-holder' className="element-holder">
                                <div className="element" onClick={e => handleMenuOptions('firstSide', e)}>
                                    {data.first_side_walk ?
                                        <>
                                            <img
                                                src={data.sidewalk_fix == 0 ? addressDefaultIcon : addressCheckedIcon}
                                                className="tick"
                                                onClick={() => props.handleFinishSidewalk(data.id, data.sidewalk_fix)}
                                            />
                                            <p>
                                                {data.first_side_walk.name}
                                            </p>
                                        </>
                                        :
                                        <button className="btn-extra-add" >
                                            Добави
                            </button>
                                    }
                                    <ClickAwayListener onClickAway={() => handleClose('firstSide')}>
                                        <div className={`not-clickable options ${stage.optionsMenu.firstSide ? 'active' : ''}`}>
                                            {props.sideWalks.map(sidewalk =>
                                                <div
                                                    key={sidewalk.id}
                                                    className="menu-item"
                                                    onClick={() => handleChange(data.id, sidewalk.id, 'firstsidewalk_id')}
                                                >
                                                    {sidewalk.name}
                                                </div>
                                            )}
                                        </div>
                                    </ClickAwayListener>
                                </div>
                            </div>
                            :
                            ''
                    }
                    {
                        props.project.have_sidewalk ?
                            <div id='element-holder' className="element-holder">
                                <div className="element" onClick={e => handleMenuOptions('secondSide', e)} >
                                    {data.second_side_walk ?
                                        <>
                                            <img
                                                src={data.sidewalk_fix == 0 ? addressDefaultIcon : addressCheckedIcon}
                                                className="tick"
                                                onClick={() => props.handleFinishSidewalk(data.id, data.sidewalk_fix)}
                                            />
                                            <p>
                                                {data.second_side_walk.name}
                                            </p>
                                        </>
                                        :
                                        <button className="btn-extra-add">
                                            Добави
                            </button>
                                    }
                                    <ClickAwayListener onClickAway={() => handleClose('secondSide')}>
                                        <div className={`not-clickable options ${stage.optionsMenu.secondSide ? 'active' : ''}`}>
                                            {props.sideWalks.map(sidewalk =>
                                                <div
                                                    key={sidewalk.id}
                                                    className="menu-item"
                                                    onClick={() => handleChange(data.id, sidewalk.id, 'secondsidewalk_id')}
                                                >
                                                    {sidewalk.name}
                                                </div>
                                            )}
                                        </div>
                                    </ClickAwayListener>
                                </div>
                            </div>
                            :
                            ''
                    }
                    <i onClick={() => handleMenuOptions('options')} className="not-clickable" >
                        <img src={dotsIcon} alt="Меню" className="not-clickable" />
                        <ClickAwayListener onClickAway={() => handleClose('options')}>
                            <div className={`not-clickable options ${stage.optionsMenu.options ? 'active' : ''}`}>
                                <div className="menu-item" onClick={e => props.handleShowBrigade(data.id, e)}>
                                    Преглед
                            </div>
                                <div className="menu-item not-clickable" onClick={() => handleEdit(data.id)}>
                                    Редакция
                            </div>
                            </div>
                        </ClickAwayListener>
                    </i>
                </div>
                <div className="date">
                    <span className="start-date">
                        начало: <time>{data.from_dmy ? data.from_dmy : 'Не е посочено'}</time>
                    </span>
                    <span className="end-date">
                        край: <time>{data.to_dmy ? data.to_dmy : 'Не е посочен'}</time>
                    </span>
                </div>
            </li >
        </>
    )
}

export default Stage
import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Api from '../../_helpers/Api'

//components
import Pagination from '../app/Pagination'
import Table from './partials/Table'
import Filter from './partials/Filter'

//imags
import loader from '../../assets/images/app/loader.svg'
import notFoundIcon from '../../assets/images/pages/icons/not-found.png'

//modals
import CreateEdit from './modals/CreateEdit'

function Index() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const [workers, setWorkers] = useState({
        data: [],
        pages: 1,
        page: searchParams.get('page') || 1,
        loading: true,
        refresh: null,
        types: [],
        filter: {
            name: ''
        }
    })

    //refs
    const createEditRef = useRef(null)
    //end refs

    useEffect(() => {
        startLoading()
        Api.get(`clients/getall?page=${workers.page}&name=${workers.filter.name}`)
            .then(res => {
                setWorkers(workers => ({
                    ...workers,
                    data: res.data.result,
                    pages: res.data.page,
                }))
            })
            .finally(() => stopLoading())
    }, [workers.refresh, workers.page, workers.filter])

    const startLoading = () => {
        setWorkers(workers => ({
            ...workers,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setWorkers(workers => ({
            ...workers,
            loading: false,
        }))
    }

    //refresh
    const handleRefreshData = () => {
        setWorkers(workers => ({
            ...workers,
            refresh: new Date()
        }))
    }

    const handlePage = page => {
        setWorkers(workers => ({
            ...workers,
            page: page.selected + 1
        }))
    }

    const handleFilter = (name, id) => {
        setWorkers(workers => ({
            ...workers,
            filter: {
                ...workers.filter,
                [name]: id
            }
        }))
    }

    const handleAddWorker = () => createEditRef.current.open()

    const handleEditWorker = id => createEditRef.current.open(id)

    return (
        <section>
            <div className="wrapper">
                <CreateEdit
                    ref={createEditRef}
                    handleRefreshData={handleRefreshData}
                />
                <div className="top row">
                    <h1>Клиенти</h1>
                    <button className="btn-add" onClick={handleAddWorker}>
                        Клиент
                    </button>
                </div>
                <div className="row">
                    <Filter
                        handleFilter={handleFilter}
                    />
                </div>
                <hr />
                {workers.loading ?
                    <img src={loader} alt="loading" />
                    :
                    workers.data.length == 0 ?
                        <div className="no-results">
                            <img src={notFoundIcon} alt="Няма резултати" />
                            Не бяха намерени резултати.
                        </div>
                        :
                        <div className="row">
                            <Table
                                workers={workers.data}
                                handleEdit={handleEditWorker}
                            />
                        </div>}

                {!workers.loading && <div className="row">
                    <Pagination
                        page={workers.page}
                        pages={workers.pages}
                        handlePage={handlePage}
                    />
                </div>
                }
            </div >
        </section>
    )
}

export default Index
import React from 'react'

function Filter(props) {

    const handleFilter = e => {
        let name = e.target.name
        let val = e.target.value

        props.handleFilter(name, val)
    }
    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            <div className="col">
                <label htmlFor="name">
                    Име
                </label>
                <input
                    type="search"
                    id="name"
                    name="name"
                    placeholder="Въведете име..."
                    onChange={handleFilter}
                />
            </div>
        </form>
    )
}

export default Filter
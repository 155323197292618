import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import Api from '../../_helpers/Api'

//images
import loader from '../../assets/images/app/loader.svg'
import workerIcon from '../../assets/images/pages/icons/worker.png'
import notFoundIcon from '../../assets/images/pages/icons/not-found.png'

//contexts
import { useProjcetContext } from '../../_contexts/ProjectContext'

//components
import Worker from './partials/Worker'

function SelectWorkers(props, ref) {
    const projectContext = useProjcetContext()

    const brigade = props.isBrigade
    const task = props.isTask

    const [modal, setModal] = useState({
        overlay: false,
        modal: false,
        mainLoading: true,
        loading: false,
        //worker type data
        data: [],
        selectedTypeId: null,
        stageId: null,
        //worker data
        workersData: [],
        workersLoading: false,

        // избрани работници
        selectedWorkers: {},

        // брой работници, групирани по типове
        // общ работник => 1, багерист => 2
        workersCount: {},

        //Всички данни за избрания етап
        selectedStage: {}
    })

    const form = document.getElementById('form')

    useEffect(() => {
        let workers = props.workers
        let selectedWorkers = {}

        if (Array.isArray(workers)) {
            workers.map(u => {
                selectedWorkers[u.id] = u
            })
        }

        setModal(prev => ({
            ...prev,
            selectedWorkers: selectedWorkers
        }))

    }, [props.workers])

    useEffect(() => {
        let workersCount = {}

        Object.values(modal.selectedWorkers).map(u => {
            if (!workersCount[u.worker_id]) {
                workersCount[u.worker_id] = 0
            }

            workersCount[u.worker_id]++
        })

        setModal(prev => ({
            ...prev,
            workersCount: workersCount
        }))

    }, [modal.selectedWorkers])

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = stageId => {
        startMainLoading()

        // const selectedStage = projectContext.data.stages.filter(stage => {
        //     return stage.id == stageId
        // })[0]

        let url
        if (brigade) url = `workers/types/get?with_workers_count=1`
        else if (task) url = `brigades/workers/types?brigade_id=${props.brigade_id}&project_id=${projectContext.data.id}`

        Api.get(url)
            .then(res => {
                setModal(modal => ({
                    ...modal,
                    data: res.data,
                }))
            })
            .finally(() => stopMainLoading())

        setModal(modal => ({
            ...modal,
            overlay: true,
            stageId: stageId,
            selectedStage: {
                from_dmy: projectContext.data.from_dmy,
                to_dmy: projectContext.data.to_dmy
            },
        }))
        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setModal(modal => ({
            ...modal,
            modal: false,
        }))

        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                overlay: false,
                stageId: null,
            }))
        }, 100)
    }

    const handleGetWorkers = typeId => {
        setModal(modal => ({
            ...modal,
            selectedTypeId: typeId,
        }))

        startWorkersLoading()

        let url
        if (brigade) url = `workers/get?type_id=${typeId}`
        else if (task) url = `brigades/workers?brigade_id=${props.brigade_id}&project_id=${projectContext.data.id}&type_id=${typeId}`

        Api.get(url)
            .then(res => {
                setModal(modal => ({
                    ...modal,
                    workersData: res.data,
                }))
            })
            .finally(() => stopWorkersLoading())
    }

    const startMainLoading = () => {
        setModal(modal => ({
            ...modal,
            mainLoading: true,
        }))
    }

    const stopMainLoading = () => {
        setModal(modal => ({
            ...modal,
            mainLoading: false,
        }))
    }

    const startWorkersLoading = () => {
        setModal(modal => ({
            ...modal,
            workersLoading: true,
        }))
    }

    const stopWorkersLoading = () => {
        setModal(modal => ({
            ...modal,
            workersLoading: false,
        }))
    }

    const handleSelectWorker = e => {
        let id = Number(e.target.value)

        let workers = modal.workersData
        let selected = Object.assign({}, modal.selectedWorkers)

        let user = workers.filter(u => u.id === id)[0]

        if (selected[id]) {
            delete selected[id]
        } else {
            selected[id] = user
        }

        setModal(prev => ({
            ...prev,
            selectedWorkers: selected,
        }))
    }

    const handleSave = e => {
        e.preventDefault()

        let selected = modal.selectedWorkers

        if (typeof props.setWorkers === 'function') {
            props.setWorkers(Object.values(selected))
            handleClose()
        }
    }

    return (
        <div className={`${modal.overlay ? 'visible' : ''} overlay`}>
            <div className="scroller">
                <div className={`${modal.modal ? 'show' : ''} box box-large`}>
                    <h2>
                        Избор на работници
                </h2>
                    <p className="left">
                        Изберете членове за форимиране на бригадата
                </p>
                    {modal.mainLoading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            {modal.data.length == 0 ?
                                <div className="no-results">
                                    <img src={notFoundIcon} alt="Няма резултати" />
                                Не бяха намерени резултати.
                            </div>
                                :
                                <>
                                    <ul className="checkbox-filter">
                                        {modal.data.filter(each => task || each.id != 9).map(each =>
                                            <li
                                                key={each.id}
                                                onClick={() => handleGetWorkers(each.id)}
                                                className={modal.selectedTypeId == each.id ? 'selected' : ''}
                                            >
                                                <img src={each.image ? each.image : workerIcon} alt={each.name} />
                                                <h5>
                                                    {each.name}
                                                </h5>
                                                <span>
                                                    {each.users_count} {each.users_count == 1 ? 'човек' : 'човека'}
                                                </span>
                                                <i>
                                                    {modal.workersCount[each.id] || 0}
                                                </i>
                                            </li>
                                        )}
                                    </ul>
                                    <hr />
                                    {modal.workersLoading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        modal.workersData.length == 0 ?
                                            <div className="no-results">
                                                Изберете тип работник.
                                        </div>
                                            :
                                            <ul className="checkbox-list">
                                                {modal.workersData.map(each =>
                                                    <Worker
                                                        each={each}
                                                        handleSelectWorker={handleSelectWorker}
                                                        selectedWorkers={modal.selectedWorkers[each.id] ? true : false}
                                                        selectedStage={modal.selectedStage}
                                                    />
                                                )}
                                            </ul>
                                    }
                                </>
                            }
                            <div className="buttons">
                                {modal.loading ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        <button className="btn-cancel" onClick={handleClose}>
                                            Отказ
                                    </button>
                                        <button className="btn-add" onClick={handleSave}>
                                            Добавяне
                                    </button>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(SelectWorkers), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})
import React, { useState, useImperativeHandle, forwardRef } from 'react'

//images
import successIcon from '../../assets/images/modals/icons/success.png'

function Success(props, ref) {

    const [success, setSuccess] = useState({
        overlay: false,
        modal: false,
        onClose: null
    })

    useImperativeHandle(ref, () => ({
        open: () => {
            setSuccess(success => ({
                ...success,
                overlay: true,
            }))
            setTimeout(() => {
                setSuccess(success => ({
                    ...success,
                    modal: true,
                }))
            }, 50)
        },
        close: () => handleClose(),
        onClose: fn => {
            setSuccess(prev => ({
                ...prev,
                onClose: fn
            }))
        }
    }))

    const handleClose = e => {
        if (e) {
            e.preventDefault()
        }
        setSuccess(success => ({
            ...success,
            modal: false,
        }))

        setTimeout(() => {
            setSuccess(success => ({
                ...success,
                overlay: false,
            }))
        }, 100)

        if (typeof props.handleSuccessAction === 'function') props.handleSuccessAction()
        else if (typeof success.onClose === 'function') success.onClose()
    }

    return (
        <div className={`${success.overlay ? 'visible' : ''} overlay`}>
            <div className={`${success.modal ? 'show' : ''} box`}>
                <img src={successIcon} alt="success" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">
                    <button className="btn-primary" onClick={(e) => handleClose(e)}>
                        Добре
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Success)
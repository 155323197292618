import React, { createContext, useContext, useState, useMemo } from "react"

export const AuthContext = createContext({})

const Auth = props => {
    const [authData, setAuthData] = useState({
        isLogged: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).isLogged : false,
        user: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).user : {},
    })

    const login = (data, logged) => {

        let state = {
            isLogged: logged ? true : false,
            user: data
        }

        localStorage.setItem('auth', JSON.stringify(state))
        setAuthData(state)
    }



    const logout = () => {
        let state = {
            isLogged: false,
            user: {}
        }

        localStorage.setItem('auth', JSON.stringify(state))
        setAuthData(state)
    }

    const auth = useMemo(() => ({
        ...authData,
        logout,
        login,
    }), [authData])

    return <AuthContext.Provider value={auth} {...props} />
}

export const useAuthContext = () => useContext(AuthContext)

export default Auth
import React, { useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//components
import Machine from './Machine'

//modals
import RemoveModal from '../../../../modals/Remove'
import AttentionModal from '../../../../modals/Attention'

function Table(props) {
    const machines = props.machines
    const isForeman = props.isForeman
    //refs
    const removeModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //end refs

    const handleRemoveMachine = id => {
        if (props.count == 1) attentionModalRef.current.open()
        else removeModalRef.current.open(id)
    }
    const handleRemoveCallback = id => {
        Api.get(`brigades/removemachine?id=${id}`)
            .then(() => props.removeMachine(id))
    }

    return (
        <>
            <RemoveModal
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                mainMessage={`Сигурни ли сте, че искате да премахнете тази машина от бригадата?`}
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Не може да премахнете последната машина от бригадата"
                secondaryMessage="Може да изтриете бригадата през бутона за редакция."
            />
            <table>
                <thead>
                    <tr>
                        <th>Рег. номер</th>
                        <th>Вид</th>
                        {!isForeman &&
                            <th className="option">
                                Опции
                            </th>}
                    </tr>
                </thead>
                <tbody>
                    {machines.map(machine =>
                        <Machine
                            isForeman={props.isForeman}
                            machines={machine}
                            handleEdit={props.handleEdit}
                            handleRemoveMachine={handleRemoveMachine}
                        />
                    )}
                </tbody>
            </table>
        </>
    )
}

export default Table
import React, { useState, useEffect } from 'react'
import Api from '../../_helpers/Api'

//images
import headingIcon from '../../assets/images/pages/icons/stage.png'
import loader from '../../assets/images/app/loader.svg'


//components
import Tabs from './tabs/Bar'
import Materials from './tabs/materials/Index'
import LastActivities from './partials/LastActivities'

//contexts
import { useStorageContext } from '../../_contexts/StorageContext'

function Index() {
    const storageContext = useStorageContext()

    const [storage, setStorage] = useState({
        currentTab: 1,
        data: [],
        loading: true,
        refresh: ''
    })

    const handleCurrentTab = currentTab => {
        setStorage(storage => ({
            ...storage,
            currentTab: currentTab
        }))
    }

    useEffect(() => {
        Api.get(`storage/getallstorages`)
            .then(res => {
                setStorage(storage => ({
                    ...storage,
                    data: res.data,
                    loading: false,
                }))
                storageContext.setData(res.data)
            })
    }, [storage.refresh])

    const refresh = () => {
        setStorage(storage => ({
            ...storage,
            loading: true,
            refresh: new Date()
        }))
    }

    return (
        <section id="view-project">
            <aside className="storage-aside">
                <div className="wrapper">
                    <div className="row">
                        <h2>
                            <img src={headingIcon} alt="Дейности" />
                            Последни дейности
                        </h2>
                    </div>
                    {storage.loading ?
                        <img src={loader} alt="loading" />
                        :
                        storage.data.length == 0 ?
                            <div className="no-results">
                                Не бяха намерени резултати
                            </div>
                            :
                            <LastActivities
                                refresh={storage.refresh}
                            />
                    }
                </div>
            </aside>

            <div className="main wrapper">
                <Tabs
                    currentTab={storage.currentTab}
                    handleCurrentTab={handleCurrentTab}
                />
                {storage.currentTab == 1 ?
                    <Materials
                        refreshData={refresh}
                    />
                    :
                    ''
                }
            </div>
        </section>
    )
}

export default Index
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import closeIcon from '../../../../../assets/images/modals/icons/close.png'

//modals
import SuccessModal from '../../../../modals/Success'
import RemoveModal from '../../../../modals/Remove'

function CreateEdit(props, ref) {
    //success modal message

    const worker = {
        name: '',
        email: '',
        type: {
            id: null
        },
    }

    const [createEdit, setCreateEdit] = useState({
        overlay: false,
        modal: false,
        types: [],
        clients: [],
        loading: false,
        //edit
        mainLoading: false,
        id: null,
        worker: worker,
        original: worker,
    })

    const form = document.getElementById('edit-form')

    //refs
    const successModalRef = useRef(null)
    const removeModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            overlay: true,
            id: id,
            mainLoading: id ? true : false,
        }))
        setTimeout(() => {
            setCreateEdit(createEdit => ({
                ...createEdit,
                modal: true,
            }))
        }, 50)

        if (id) {
            Api.get(`workers/show?id=${id}`)
                .then(res => {
                    setCreateEdit(createEdit => ({
                        ...createEdit,
                        worker: res.data,
                        original: res.data,
                    }))
                }).finally(() => stopMainLoading())
        }
    }

    const handleClose = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            modal: false,
        }))
        removeModalRef.current.close()
        setTimeout(() => {
            document.getElementById('edit-form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)

            removeValidations()
            setCreateEdit(createEdit => ({
                ...createEdit,
                overlay: false,
                mainLoading: true,
                id: null,
                worker: worker,
                original: worker
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()

        data.append('id', createEdit.id)

        let url
        if (createEdit.id) url = 'workers/edit'
        else url = 'workers/add'

        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors

                Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")

                        inputs.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: false,
        }))
    }

    const stopMainLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            mainLoading: false,
        }))
    }

    const handleDelete = e => {
        e.preventDefault()
        removeModalRef.current.open()
    }
    const handleRemoveCallback = () => {
        startLoading()
        handleClose()
        Api.post('workers/delete', {
            id: createEdit.id
        })
            .then(() => {
                stopLoading()
                props.handleRefreshData()
            })
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <div className={`${createEdit.overlay ? 'visible' : ''} overlay`}>
            <RemoveModal
                mainMessage="Сигурни ли сте, че искате да изтриете този служител?"
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                loading={createEdit.loading}
            />
            <SuccessModal
                mainMessage={createEdit.id ? 'Успешно редактирахте служител!' : 'Служителят е добавен успешно!'}
                secondaryMessage="Може да откриете промените в списъка със служители."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <div className="scroller">
                <div className={`${createEdit.modal ? 'show' : ''} box box-small`}>
                    <img src={closeIcon} className="close" onClick={handleClose} />
                    <form id="edit-form" onSubmit={handleSubmit} className="create-task">
                        <h3>
                            {createEdit.id ?
                                'Редакция на работник' :
                                'Добавяне на работник'
                            }
                        </h3>
                        {createEdit.mainLoading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Име и фамилия
                                    </label>
                                        <input
                                            type="text"
                                            name="name"
                                            defaultValue={createEdit.worker.name}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Е-поща
                                    </label>
                                        <input
                                            type="text"
                                            name="email"
                                            defaultValue={createEdit.worker.email}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Длъжност
                                    </label>
                                        <select
                                            name="type_id"
                                            defaultValue={createEdit.worker.type.id}
                                        >
                                            <option>
                                                Избор
                                        </option>
                                            {props.types.map(type =>
                                                <option value={type.id} key={type.id}>
                                                    {type.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {!createEdit.id ? <>
                                    <div className="row">
                                        <div className="col full-width">
                                            <label>
                                                Парола
                                            </label>
                                            <input type="password" name="password" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col full-width">
                                            <label>
                                                Повтаряне на паролата
                                            </label>
                                            <input type="password" name="password_confirmation" />
                                        </div>
                                    </div>
                                </> :
                                    <>
                                        <div className="row">
                                            <div className="col full-width">
                                                <label>
                                                    Нова Парола
                                            </label>
                                                <input type="password" name="password" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col full-width">
                                                <label>
                                                    Повтаряне на паролата
                                            </label>
                                                <input type="password" name="password_confirmation" />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="buttons">
                                    {createEdit.loading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        <>
                                            {createEdit.id && <button className="btn-delete" onClick={handleDelete}>
                                                Изтриване
                                        </button>}
                                            <button className="btn-add">
                                                {createEdit.id ?
                                                    'Запазване' :
                                                    'Добавяне'
                                                }
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
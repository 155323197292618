import React from "react"
import Donut from 'react-svg-donuts'



const Chart = props => {

    const progress = props.progress

    const renderProgress = progress => <strong>
        {progress}
        <i>%</i>
        <span>
            {props.tasksCompleted} / {props.tasksCount}
        </span>
    </strong>

    return (
        <>
            <p className="centered">
                Изпълнени
                <br />
                задачи
            </p>
            <Donut
                progress={progress}
                onRender={renderProgress}
            />
        </>
    )
}

export default Chart
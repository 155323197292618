import React from 'react'
import { Link } from 'react-router-dom'

//components
import Activities from './partials/activities/Index'
import Projects from './partials/projects/Index'
import Materials from './partials/materials/Index'
import Finances from './partials/finances/Index'

//images
import planningIcon from '../../assets/images/pages/icons/planning.png'
import currentProjectIcon from '../../assets/images/pages/icons/current-projects.png'
import materialsIcon from '../../assets/images/pages/icons/materials.png'
import activitiesIcon from '../../assets/images/pages/icons/activities.png'

function Index() {
    return (
        <section id="dashboard">
            <div className="wrapper">
                <div className="activities">
                    <div className="row">
                        <h2>
                            <img src={planningIcon} />
                            Планиране
                        </h2>
                    </div>
                    <Activities />
                </div>
                <div className="right-part">
                    <div className="row">
                        <h2>
                            <img src={currentProjectIcon} />
                            Текущите проекти
                           <Link to="/projects">
                                Виж всички
                            </Link>
                        </h2>
                    </div>
                    <Projects />
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <h2>
                                    <img src={materialsIcon} />
                                    Материали на изчерпване
                                    <Link to="/storage?tab=1">
                                        Виж всички
                                    </Link>
                                </h2>
                            </div>
                            <Materials />
                        </div>
                        <div className="col">
                            <div className="row">
                                <h2>
                                    <img src={activitiesIcon} />
                                    Финансови операции
                                    <Link to="/finance?tab=1">
                                        Виж всички
                                    </Link>
                                </h2>
                            </div>
                            <Finances />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Index
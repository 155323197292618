import React, { useState, useEffect } from 'react'
import Api from '../../../../_helpers/Api'

//components
import Project from './partials/Project'

//images
import loader from '../../../../assets/images/app/loader.svg'
import notStarted from '../../../../assets/images/pages/icons/not-started.png'
import leftArrowIcon from '../../../../assets/images/pages/icons/scroll-left-arrow.png'
import rightArrowIcon from '../../../../assets/images/pages/icons/scroll-right-arrow.png'

let years = []
function Index() {

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const currentDate = new Date().getDate()

    const lastDayOfMonth = (y, m) => {
        return new Date(y, m + 1, 0).getDate()
    }
    const lastDayOfCurrentMonth = lastDayOfMonth(currentYear, currentMonth - 1)

    const [projects, setProjects] = useState({
        data: {
            items: []
        },
        loading: true,
        months: [
            'Януари',
            'Февруари',
            'Март',
            'Април',
            'Май',
            'Юни',
            'Юли',
            'Август',
            'Септември',
            'Октомври',
            'Ноември',
            'Декември'
        ],
        fromYear: null,
        fromMonth: null,
        toMonth: null,
        refresh: null,
    })

    const handleScrollForward = () => {
        const scrollSection = document.querySelector('.stages .right')

        scrollSection.scrollBy({
            top: 0,
            left: +150,
            behavior: 'smooth'
        })
    }

    const handleScrollBack = () => {
        const scrollSection = document.querySelector('.stages .right')

        scrollSection.scrollBy({
            top: 0,
            left: -150,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        startLoading()
        Api.get('dashboard/notcompleted')
            .then(res => {
                setProjects(prev => ({
                    ...prev,
                    data: res.data,
                    fromYear: res.data.start_year,
                    fromMonth: res.data.start_month,
                    toMonth: res.data.end_month
                }))
                const fromDate = new Date(`${res.data.start_year}-${res.data.start_month}-${res.data.start_day}`)
                const toDate = new Date(`${res.data.end_year}-${Number(res.data.end_month)}-${res.data.end_day}`)
                years = []
                
                for (let d = fromDate; d <= toDate; d.setMonth(d.getMonth() + 1)) {
                    years.push(<li><div className="months">{projects.months[new Date(d).getMonth()]}</div></li>)
                }

                setTimeout(() => {
                    const currentDateEl = document.getElementsByClassName('current-date')[0]

                    currentDateEl && currentDateEl.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start'
                    })
                }, 500)
            })
            .finally(() => stopLoading())
    }, [projects.refresh])
    const startLoading = () => {
        setProjects(prev => ({
            ...prev,
            loading: true
        }))
    }
    const stopLoading = () => {
        setProjects(prev => ({
            ...prev,
            loading: false
        }))
    }

    return (
        <div className="row holder">
            {projects.loading ?
                <img src={loader} alt="loading" />
                :
                projects.data.items.length == 0 ?
                    <div className="no-results">
                        <img src={notStarted} alt="Няма резултати" />
                        Няма проекти в прогрес.
                    </div>
                    :
                    <div className="stages">
                        <div className="move-scroller left-arrow" onClick={handleScrollBack}>
                            <img src={leftArrowIcon} alt="Назад" />
                        </div>
                        <div className="move-scroller right-arrow" onClick={handleScrollForward}>
                            <img src={rightArrowIcon} alt="Напред" />
                        </div>
                        <div className="top-bar">

                        </div>
                        <div className="aside">
                            <div className="head">
                                Текущи проекти
                            </div>
                            <ul>
                                {projects.data.items.map((project, i) =>
                                    <li key={project.id}>
                                        <i style={{ backgroundColor: '#1298FF' }}>
                                            {i + 1}
                                        </i>
                                        {project.name}
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div className="right">
                            <div
                                className="current-date"
                                style={{
                                    left: `${((Number(currentMonth) - Number(projects.fromMonth)) * 115) + (115 / lastDayOfCurrentMonth * Number(currentDate) - 5) + ((Number(currentYear) - Number(projects.fromYear) > 0 ? (Number(currentYear) - Number(projects.fromYear)) * 115 * 12 : 0))}px`,
                                }}
                            >
                                {currentYear}-{currentMonth}-{currentDate}
                            </div>
                            <ul className="main-stages">
                                {projects.data.items.map(project =>
                                    <Project
                                        key={project.id}
                                        data={project}
                                        fromYear={projects.fromYear}
                                        fromMonth={projects.fromMonth}
                                    />
                                )}
                            </ul>
                            <ul className="calendar">
                                {years}
                            </ul>
                        </div>
                    </div>}
        </div>
    )
}

export default Index
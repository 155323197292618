import React from 'react'
import { useLocation } from 'react-router-dom'

//components
import Table from './partials/Table'
import View from './View'

function Stages(props) {
    const location = useLocation()
    const isStageViewed = new URLSearchParams(location.search).get('stage_id')

    return (
        <>
            {isStageViewed ?
                <View
                    stageId={isStageViewed}
                    handleRefreshData={() => props.handleRefreshData()}
                />
                :
                <Table
                    handleRefreshData={() => props.handleRefreshData()}
                />
            }
        </>
    )
}

export default Stages
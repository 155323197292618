import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from "react-router-dom"
import AuthContext from './_contexts/AuthContext'

const history = require("history").createLocation;

ReactDOM.render(
  <Router history={history}>
    <AuthContext>
      <App />
    </AuthContext>
  </Router>,
  document.getElementById('root')
)

serviceWorker.unregister()



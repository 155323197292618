import React from 'react'

function Filter(props) {

    const handleFilter = e => {
        let name = e.target.name
        let val = e.target.value

        props.handleFilter(name, val)
    }
    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            <div className="col">
                <label htmlFor="type">
                    Длъжност
                    </label>
                <select
                    id="type"
                    name="type_id"
                    onChange={handleFilter}
                >
                    <option value="">Всички</option>
                    {props.types.map(type =>
                        <option key={type.id} value={type.id}>
                            {type.name}
                        </option>
                    )}
                </select>
            </div>
        </form>
    )
}

export default Filter
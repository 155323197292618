import React from 'react'
import { Link, useHistory } from 'react-router-dom'

//contexts
import { useAuthContext } from '../../_contexts/AuthContext'

//images
import brand from '../../assets/images/app/brand.png'
import dashboardIcon from '../../assets/images/app/icons/dashboard.png'
import projectsIcon from '../../assets/images/app/icons/projects.png'
import storageIcon from '../../assets/images/app/icons/storage.png'
import financesIcon from '../../assets/images/app/icons/finances.png'
import clientsIcon from '../../assets/images/app/icons/clients.png'
import administrationIcon from '../../assets/images/app/icons/administration.png'
import arrowIcon from '../../assets/images/elements/icons/filter-arrow.png'

function Header() {
    const auth = useAuthContext()
    const location = useHistory().location.pathname
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2


    return (
        <header>
            <div className="wrapper">
                <Link to="/" className="brand">
                    <img src={brand} alt="Prespa Invest" />
                </Link>
                <nav className={isForeman ? 'is-foreman' : ''}>
                    {(isAdmin || isForeman) && <Link to="/" className={location == '/' ? 'active' : ''}>
                        <img src={dashboardIcon} alt="dashboard" />
                        Табло
                    </Link>}
                    <Link to="/projects" className={location.includes('/projects') ? 'active' : ''}>
                        <img src={projectsIcon} alt="Проекти" />
                        Проекти
                    </Link>
                    {(isAdmin) && <Link to="/storage" className={location.includes('/storage') ? 'active' : ''}>
                        <img src={storageIcon} alt="Склад" />
                        Склад
                    </Link>}
                    {(isAdmin || isForeman) && <Link to="/finance" className={location.includes('/finance') ? 'active' : ''}>
                        <img src={financesIcon} alt="Финанси" />
                        Финанси
                    </Link>}
                    {(isAdmin) && <Link to="/clients" className={location.includes('/clients') ? 'active' : ''}>
                        <img src={clientsIcon} alt="Клиенти" />
                        Клиенти
                    </Link>}
                    {(isAdmin) && <Link to="/administration" className={location.includes('/administration') ? 'active' : ''}>
                        <img src={administrationIcon} alt="Администрация" />
                        Администрация
                    </Link>}
                </nav>
                <ul className="user-menu">
                    <li>
                        {auth.user.data ? auth.user.data.name : 'Профил'}
                        <img src={arrowIcon} alt="Меню" />
                        <ul>
                            <li onClick={() => auth.logout()}>
                                Изход
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </header>
    )
}

export default Header
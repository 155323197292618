import React, { useEffect, useState, useRef } from 'react'
import Api from '../../../../_helpers/Api'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../../../_contexts/AuthContext'

//components
import WorkersTable from './partials/WorkersTable'
import MachinesTable from './partials/MachinesTable'

//images
import loader from '../../../../assets/images/app/loader.svg'
import notFoundIcon from '../../../../assets/images/pages/icons/not-found.png'

//modals
import CreateEdit from './modals/CreateEdit'
import Activity from './modals/Activity'

function Index(props) {
    const activityRef = useRef(null)
    const projectId = useParams().id
    const brigade_id = props.tab || 1

    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    const [brigade, setBrigade] = useState({
        data: {},
        loading: true,
        brigadeId: null,
        workers: {
            data: {
                workers: []
            },
            types: [],
            loading: false,
            filter: {
                name: '',
                worker_id: ''
            },
            refresh: null,
        },
        machines: {
            data: {
                machines: []
            },
            types: [],
            loading: false,
            filter: {
                type_id: '',
            },
            refresh: null,
        },
        refresh: null,
    })

    //refs
    const createEditRef = useRef(null)
    //end refs

    const handleAdd = (id = null, project_id, check) => createEditRef.current.open(id, project_id, check)
    const handleEdit = (id, project_id) => createEditRef.current.edit(id, project_id)

    useEffect(() => {
        Api.get('workers/types/get')
            .then(res => {
                setBrigade(prev => ({
                    ...prev,
                    workers: {
                        ...prev.workers,
                        types: res.data,
                    },
                }))
            })

        Api.get('machines/types/get')
            .then(res => {
                setBrigade(prev => ({
                    ...prev,
                    machines: {
                        ...prev.machines,
                        types: res.data,
                    }
                }))
            })
    }, [])

    useEffect(() => {
        setBrigade(prev => ({
            ...prev,
            loading: true,
        }))
    }, brigade_id)

    useEffect(() => {
        Api.get(`brigades/getbybrigadeid?brigade_id=${brigade_id}`)
            .then(res => {
                setBrigade(prev => ({
                    ...prev,
                    data: res.data.brigade,
                    brigadeId: res.data.id,
                    workers: {
                        ...prev.workers,
                        data: res.data.brigade,
                        loading: false,
                    },
                    machines: {
                        ...prev.machines,
                        data: res.data.brigade,
                        loading: false,
                    },
                    loading: false,
                }))
            })
    }, [brigade.refresh, brigade_id])

    useEffect(() => {
        setBrigade(prev => ({
            ...prev,
            workers: {
                ...prev.workers,
                loading: true,
            }
        }))

        Api.get(`brigades/workersbrigade?project_id=${projectId}&name=${brigade.workers.filter.name}&worker_id=${brigade.workers.filter.worker_id}`)
            .then(res => {
                setBrigade(prev => ({
                    ...prev,
                    workers: {
                        ...prev.workers,
                        data: res.data,
                        loading: false,
                    }
                }))
            })
    }, [brigade.workers.filter, brigade.workers.refresh])
    const handleWorkersFilter = e => {
        let name = e.target.name
        let val = e.target.value

        setBrigade(prev => ({
            ...prev,
            workers: {
                ...prev.workers,
                filter: {
                    ...prev.workers.filter,
                    [name]: val,
                }
            }
        }))
    }

    useEffect(() => {
        setBrigade(prev => ({
            ...prev,
            machines: {
                ...prev.machines,
                loading: true,
            }
        }))

        Api.get(`brigades/machinesbrigade?project_id=${projectId}&type_id=${brigade.machines.filter.type_id}`)
            .then(res => {
                setBrigade(prev => ({
                    ...prev,
                    machines: {
                        ...prev.machines,
                        data: res.data,
                        loading: false,
                    }
                }))
            })
    }, [brigade.machines.filter, brigade.machines.refresh])
    const handleMachinesFilter = e => {
        let name = e.target.name
        let val = e.target.value

        setBrigade(prev => ({
            ...prev,
            machines: {
                ...prev.machines,
                filter: {
                    ...prev.machines.filter,
                    [name]: val,
                }
            }
        }))
    }

    const handleRefreshData = () => {
        setBrigade(prev => ({
            ...prev,
            refresh: new Date()
        }))
    }

    const handleRefreshWorkers = () => {
        setBrigade(prev => ({
            ...prev,
            workers: {
                ...prev.workers,
                refresh: new Date()
            }
        }))
    }

    const handleRefreshMachines = () => {
        setBrigade(prev => ({
            ...prev,
            machines: {
                ...prev.machines,
                refresh: new Date()
            }
        }))
    }

    const handleOpen = id => {
        activityRef.current.open(id, projectId)
    }
    return (
        <div className="wrapper task brigade">
            <CreateEdit
                ref={createEditRef}
                handleRefreshData={() => {
                    handleRefreshData()
                    props.handleRefreshData()
                }}
                brigade_id={brigade_id}
            />
            <Activity
                ref={activityRef}
                handleRefreshData={handleRefreshData}
            />

            <div style={{ justifyContent: 'flex-end' }} className="top row">
                <span>

                </span>

                {!isForeman && brigade.data && brigade.data.id > 4 && <div className='col'>
                    <button className="btn-edit" onClick={() => handleEdit(brigade_id, projectId,)}>
                        Бригада
                    </button>
                </div>}
                {!isForeman && brigade.data && brigade.data.id > 4 && <div className='col'>
                    <button className="btn-add" onClick={() => handleAdd(null, projectId, true)}>
                        Добави
                    </button>
                </div>}
            </div>

            {brigade.loading ?
                <img src={loader} alt="loading" />
                :
                !brigade.data ?
                    <div className="no-results">
                        <img src={notFoundIcon} alt="Няма резултати" />
                            Няма добавена бригада
                        <br />
                            към този проект
                        <br />
                        <br />
                        {!isForeman && <button className="btn-extra-add" onClick={() => handleAdd(null, projectId, false)}>
                            Добави
                        </button>}
                    </div>
                    :
                    <>
                        <div className="row">
                            <div className="col">
                                <h2 className={isForeman && 'has-foreman'}>
                                    Работници
                                </h2>
                                <form autoComplete="off" role="presentation" className="filter-standard">
                                    <div className="row">
                                        <div className="col">
                                            <label>
                                                Длъжност
                                            </label>
                                            <select
                                                name="worker_id"
                                                onChange={handleWorkersFilter}
                                            >
                                                <option value="">
                                                    Всички
                                                </option>
                                                {brigade.workers.types.map(type =>
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label>
                                                Име
                                            </label>
                                            <input
                                                type="search"
                                                placeholder="Търсене на работници..."
                                                name="name"
                                                onChange={handleWorkersFilter}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {brigade.workers.loading ?
                            <img src={loader} alt="loading" />
                            :
                            brigade.workers.data.workers.length == 0 ?
                                <div className="no-results">
                                    <img src={notFoundIcon} alt="Няма резултати" />
                                    Не бяха намерени резултати.

                                </div>
                                :
                                <WorkersTable
                                    isForeman={isForeman}
                                    workers={brigade.workers.data.workers}
                                    worker_id={brigade.workers.filter.worker_id}
                                    count={brigade.workers.data.workers_count}
                                    foreman={brigade.data.foremans}
                                    removeWorker={handleRefreshWorkers}
                                    handleOpen={handleOpen}
                                />
                        }
                        <hr />
                        <div className="row">
                            <div className="col">
                                <h2>
                                    Машини
                                </h2>
                                <form autoComplete="off" role="presentation" className="filter-standard">
                                    <div className="row">
                                        <div className="col">
                                            <label>
                                                Вид
                                            </label>
                                            <select
                                                name="type_id"
                                                onChange={handleMachinesFilter}
                                            >
                                                <option value="">
                                                    Всички
                                                </option>
                                                {brigade.machines.types.items && brigade.machines.types.items.map(type =>
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {brigade.machines.loading ?
                            <img src={loader} alt="loading" />
                            :
                            brigade.machines.data.machines.length == 0 ?
                                <div className="no-results">
                                    <img src={notFoundIcon} alt="Няма резултати" />
                                    Не бяха намерени резултати.
                                </div>
                                :
                                <MachinesTable
                                    isForeman={isForeman}
                                    machines={brigade.machines.data.machines}
                                    count={brigade.machines.data.machines_count}
                                    removeMachine={handleRefreshMachines}
                                />
                        }
                    </>
            }
        </div>
    )
}

export default Index
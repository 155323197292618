import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import closeIcon from '../../../../../assets/images/modals/icons/close.png'

//modals
import SuccessModal from '../../../../modals/Success'

//components
import Dropdown from '../../../../app/Dropdown'

function CreateEdit(props, ref) {

    const materials = {
        quantity: null,
        to: 0,
    }

    const [createEdit, setCreateEdit] = useState({
        overlay: false,
        modal: false,
        types: [],
        data: [],
        clients: [],
        loading: false,
        //edit
        mainLoading: false,
        id: null,
        materials: materials,
        original: materials,
    })

    const form = document.getElementById('transfer-form')

    //refs
    const successModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        Api.get(`projects/find-item?id=${id}`)
            .then((res => {
                setCreateEdit(createEdit => ({
                    ...createEdit,
                    data: res.data,
                }))
            }))

        setCreateEdit(createEdit => ({
            ...createEdit,
            overlay: true,
            id: id,
        }))
        setTimeout(() => {
            setCreateEdit(createEdit => ({
                ...createEdit,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            modal: false,
        }))

        setTimeout(() => {
            form.reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)

            removeValidations()
            setCreateEdit(createEdit => ({
                ...createEdit,
                overlay: false,
                id: null,
                materials: materials,
                original: materials
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()

        let url = createEdit.materials.to == 1 ? 'projects/transfer-address' : 'projects/return-material'

        data.append('id', createEdit.id)
        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors

                Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                        let inputsWithClass = form.querySelectorAll("[className='" + i[0] + "']")
                        createEdit.materials.to == 0 && form.querySelectorAll("[name='to']")[0].classList.add('validate')

                        inputs.forEach(i => i.classList.add('validate'))
                        inputsWithClass.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: false,
        }))
    }


    const handleChange = e => {
        let name = e.target.name
        let val = e.target.value

        setCreateEdit(createEdit => ({
            ...createEdit,
            materials: {
                ...createEdit.materials,
                [name]: val
            }
        }))
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <div className={`${createEdit.overlay ? 'visible' : ''} overlay`}>

            <SuccessModal
                mainMessage="Трансферът беше извършен успешно!"
                secondaryMessage="Може да откриете промените в списъка с материали."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <div className={`${createEdit.modal ? 'show' : ''} box box-small`}>
                <img src={closeIcon} className="close" onClick={handleClose} />
                <form id="transfer-form" onSubmit={handleSubmit} className="create-task">
                    <h3>
                        Трансфер на материал
                    </h3>
                    {createEdit.mainLoading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Към
                                    </label>
                                    <select
                                        name="to"
                                        onChange={handleChange}
                                        value={createEdit.materials.to}
                                    >
                                        <option selected disabled value="0">
                                            Избор
                                        </option>
                                        <option value="1">
                                            Адрес/Междушахите
                                        </option>
                                        <option value="2">
                                            Склад
                                        </option>
                                    </select>
                                </div>
                            </div>
                            {createEdit.materials.to == 1 ? <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Адрес/Междушахите
                                    </label>
                                    <Dropdown
                                        inputIdName="stage_id"
                                        inputClassName="stage_id"
                                        url='projects/getstage'
                                        params={{
                                            project_id: createEdit.data.project_id
                                        }}
                                        // inputValue={createEdit.materials.stage.name}
                                        // inputIdValue={createEdit.materials.stage_id}
                                        renderText={data => {
                                            return <>{data.name ? data.name : 'от: ' + data.from + ' до ' + data.to}</>
                                        }}
                                    />
                                </div>
                            </div> : ''}
                            {createEdit.materials.to == 2 ? <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Склад
                                    </label>
                                    <Dropdown
                                        inputIdName="storage_id"
                                        inputClassName="storage_id"
                                        url={`projects/getbyproject`}
                                        params={{
                                            project_id: createEdit.data.project_id
                                        }}
                                        // inputValue={createEdit.materials.storage.name}
                                        inputIdValue={createEdit.materials.storage_id}
                                        renderText={data => {
                                            return <>{data.name}</>
                                        }}
                                    />
                                </div>
                            </div> : ''}
                            <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Количество
                                    </label>
                                    <input
                                        type="number"
                                        defaultValue={createEdit.materials.quantity}
                                        name="quantity"
                                        min="0"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="buttons">
                                {createEdit.loading ?
                                    <img src={loader} alt="loading" />
                                    :

                                    <button className="btn-transfer">
                                        Трансфер
                                    </button>
                                }
                            </div>
                        </>
                    }
                </form>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
import React, { useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//images
import dotsBlack from '../../../../../assets/images/pages/icons/dots-black.png'

function Material(props) {
    const each = props.material

    const [material, setMaterial] = useState({
        optionsMenu: false,
    })

    const handleMenuOptions = () => {
        setMaterial(material => ({
            ...material,
            optionsMenu: material.optionsMenu ? false : true
        }))
    }

    const handleClose = () => {
        if (material.optionsMenu) {
            setMaterial(material => ({
                ...material,
                optionsMenu: false
            }))
        }
    }

    const handleEdit = id => {
        props.handleEdit(id)
        handleClose()
    }

    const handleTransfer = id => {
        props.handleTransfer(id)
        handleClose()
    }

    return (
        <tr key={each.id}>
            <td>{each.material.name}</td>
            <td>{each.material.specification}</td>
            <td>{each.storage.name}</td>
            {props.dataType == 2 ?
                <td>{each.supplier ? each.supplier.name : 'Не е посочен'}</td>
                :
                ''
            }
            <td className={Number(each.material.min_quantity) >= each.quantity ? 'low-quantity' : ''}>{each.quantity} {each.material.volumetype}</td>
            {props.dataType == 2 ?
                <td>{each.price} лв.</td>
                :
                ''
            }
            <td className="option">
                <img
                    src={dotsBlack}
                    alt="Опции"
                    title="Опции"
                    onClick={handleMenuOptions}
                />
                <ClickAwayListener onClickAway={handleClose}>
                    <div className={`options ${material.optionsMenu ? 'active' : ''}`}>
                        <div className="menu-item" onClick={() => handleTransfer(each.id)}>
                            Трансфер
                        </div>
                        <div className="menu-item" onClick={() => handleEdit(each.id)}>
                            Редакция
                        </div>
                    </div>
                </ClickAwayListener>
            </td>
        </tr>
    )
}

export default Material
import React, { useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//images
import dotsBlack from '../../../../../assets/images/pages/icons/dots-black.png'

function Material(props) {
    const each = props.material

    const [material, setMaterial] = useState({
        optionsMenu: false,
    })

    const handleMenuOptions = () => {
        setMaterial(material => ({
            ...material,
            optionsMenu: material.optionsMenu ? false : true
        }))
    }

    const handleClose = () => {
        if (material.optionsMenu) {
            setMaterial(material => ({
                ...material,
                optionsMenu: false
            }))
        }
    }

    const handleEdit = (id, expense_id) => {
        props.handleEdit(id, expense_id)
        handleClose()
    }

    const handlePayment = (id, paid) => {
        props.handlePayment(id, paid)
        handleClose()
    }
    return (

        <tr key={each.id}>
            <td>
                {each.expense_type.name + ' '}
                {each.expense_type.id == 4 ? `№ ${each.facture_number} / ${each.facture_dmy} ` : ''}
                {each.expense_type.id == 4 ? <br /> : ''}
                {each.expense_type.id == 4 ? `${each.facture_description}` : ''}
                {each.expense_type.id == 5 ? ` за ${each.trip_worker.name}` : ''}
                {each.expense_type.id == 6 ? ` за ${each.trip_worker.name} - ${each.from_dmy} до ${each.to_dmy}` : ''}
                {each.expense_type.id == 7 ? each.trip_worker.name : ''}
            </td>

            <td>{each.dmy}</td>
            <td className="expense">{each.price} лв.</td>
            <td>{each.payment ? each.payment.name : '-'}{each.facture ? '/фактура' : ''}</td>
            <td style={{ color: each.paid ? '#11ca80' : '#ff6195' }}>{each.paid ? 'Платен' : 'Неплатен'}</td>
            <td className="option">
                <>
                    <img
                        src={dotsBlack}
                        alt="Опции"
                        title="Опции"
                        onClick={handleMenuOptions}
                    />
                    <ClickAwayListener onClickAway={handleClose}>
                        <div style={{ width: '100px' }} className={`options ${material.optionsMenu ? 'active' : ''}`}>
                            <div className="menu-item" onClick={() => handlePayment(each.id, each.paid)}>
                                {each.paid == 0 ? 'Маркирай като платена' : 'Маркирай като неплатена'}
                            </div>
                            {/* {each.editable ?
                                <div className="menu-item" onClick={() => handleEdit(each.id, each.expense_id)}>
                                    Редакция
                                </div>
                                :
                                ''
                            } */}
                        </div>
                    </ClickAwayListener>
                </>

            </td>
        </tr>
    )
}

export default Material
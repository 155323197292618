import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'
import { useParams } from 'react-router-dom'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import deleteIcon from '../../../../../assets/images/modals/icons/delete.png'

//modals
import SuccessModal from '../../../../modals/Success'
import DeleteModal from '../../../../modals/Remove'

//contexts
import { useProjcetContext } from '../../../../../_contexts/ProjectContext'

//components

function CreateEdit(props, ref) {
    const projectContext = useProjcetContext()
    const inCity = useParams().inCity == 1

    const stage = {
        project_id: '',
        name: '',
        from_ymd: '',
        to_ymd: '',
        job_id: '',
        road_id: '',
        number: '',
        firstsidewalk_id: '',
        secondsidewalk_id: '',
    }

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        //data
        id: null,
        stage: stage,
        original: stage,
        mainLoading: true,
    })

    const form = document.getElementById('form')

    //refs
    const successModalRef = useRef(null)
    const deleteModalRef = useRef(null)
    //end refs

    useImperativeHandle(ref, () => ({
        open: () => handleOpen(),
        edit: (id, col) => handleEdit(id, col),
        close: () => handleClose(),
    }))

    const handleOpen = (id = null, col) => {
        if (id) loadData(id, col)
        setState(state => ({
            ...state,
            id: id,
            overlay: true,
            stage: stage,
            original: stage,
            mainLoading: id ? true : false,
        }))

        setTimeout(() => {
            setState(state => ({
                ...state,
                modal: true,
            }))
        }, 50)
    }

    const handleEdit = (id, col) => handleOpen(id, col)

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(state => ({
            ...state,
            modal: false,
        }))

        setTimeout(() => {
            document.getElementById('form').reset()

            removeValidations()

            setState(state => ({
                ...state,
                overlay: false,
                stage: stage,
                mainLoading: true,
            }))
        }, 100)
    }

    const loadData = (id, col) => {
        Api.get(`stages/getbyid?id=${id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    stage: res.data,
                    original: res.data,
                    mainLoading: false
                }))
                const el = form.querySelector("[name='" + col + "']")
                if (el) el.classList.add('focused')
                if (Array.isArray(col)) {
                    form.querySelector("[name='" + col[0] + "']").classList.add('focused')
                    form.querySelector("[name='" + col[1] + "']").classList.add('focused')
                }
            })
    }

    const handleSubmit = e => {
        e.preventDefault()

        removeValidations()

        startLoading()

        let data = new FormData(e.target)

        if (state.id) data.append('id', state.id)
        data.append('color', state.stage.color)

        let url = state.id ? 'stages/update' : 'stages/add'

        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response && err.response.data && err.response.data.errors

                if (typeof fields === 'object') {
                    Object.entries(fields).map(i => {
                        if (i[0]) {
                            let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                            inputs.forEach(i => i.classList.add('validate'))
                        }
                    })
                }

            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setState(state => ({
            ...state,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setState(state => ({
            ...state,
            loading: false,
        }))
    }

    const handleInputChange = e => {
        let name = e.target.name
        let val = e.target.value


        setState(prev => ({
            ...prev,
            stage: {
                ...prev.stage,
                [name]: val
            }
        }))
    }
    const handleFromDateChange = e => {
        let val = e.target.value

        setState(prev => ({
            ...prev,
            stage: {
                ...prev.stage,
                from_ymd: val
            }
        }))
    }
    const handleToDateChange = e => {
        let val = e.target.value

        setState(prev => ({
            ...prev,
            stage: {
                ...prev.stage,
                to_ymd: val
            }
        }))
    }

    const handleDelete = e => {
        e.preventDefault()
        deleteModalRef.current.open()
    }
    const handleConfirmDelete = () => {
        deleteModalRef.current.close()
        handleClose()
        Api.post('stages/delete', {
            id: state.stage.id
        })
            .then(() => props.handleRefreshData())
    }

    const removeValidations = () => {
        if (form) form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <SuccessModal
                ref={successModalRef}
                mainMessage={inCity ? 'Адреса е запазен успешно!' : 'Междушахтието е запазен успешно!'}
                secondaryMessage=""
                handleSuccessAction={handleClose}
            />
            <DeleteModal
                ref={deleteModalRef}
                mainMessage={inCity ? 'Потвърждавате ли изтриванието на адреса?' : 'Потвърждавате ли изтриванието на междушахтието?'}
                handleRemoveCallback={handleConfirmDelete}
            />
            <div className="scroller">
                <div className={`${state.modal ? 'show' : ''} box box-large`}>
                    <h2>
                        {state.id ? `Редактиране на ${inCity ? 'адрес' : 'междушахтие'}` : `Добавяне на ${inCity ? 'адрес' : 'междушахтие'}`}
                    </h2>
                    <p className="left">
                        {inCity ?
                            'Опишете основната информация по новият адрес'
                            :
                            'Опишете основната информация по новото междушахтие'}

                    </p>

                    <form id="form" onSubmit={handleSubmit}>
                        {state.mainLoading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <div className="row x2">
                                    <div className="col">
                                        <label>
                                            Проект
                                    </label>
                                        <select
                                            name="project_id"
                                            value={projectContext.data.id}
                                            readOnly
                                        >
                                            <option value={projectContext.data.id}>
                                                {projectContext.data.name}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <div className="col col-auto">
                                                <label>
                                                    №
                                            </label>
                                                <input
                                                    name="number"
                                                    type="text"
                                                    className="field-small"
                                                    value={state.stage && state.stage.number ? state.stage.number : ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            {inCity ?
                                                <div className="col full-width">
                                                    <label>
                                                        Име
                                                </label>
                                                    <input
                                                        name="name"
                                                        type="text"
                                                        value={state.stage && state.stage.name ? state.stage.name : ''}
                                                        onChange={handleInputChange}
                                                    />
                                                </div> :
                                                <>
                                                    <div className="col col-auto">
                                                        <label>
                                                            От
                                                    </label>
                                                        <input
                                                            name="from"
                                                            type="text"
                                                            className="field-small"
                                                            value={state.stage && state.stage.from ? state.stage.from : ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                    <div className="col col-auto">
                                                        <label>
                                                            До
                                                    </label>
                                                        <input
                                                            name="to"
                                                            type="text"
                                                            className="field-small"
                                                            value={state.stage && state.stage.to ? state.stage.to : ''}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="row x2">
                                    <div className="row x2">
                                        <div className="col">
                                            <label>
                                                Вид работа
                                        </label>
                                            <select
                                                name="job_id"
                                                value={state.stage && state.stage.job_id ? state.stage.job_id : ''}
                                                onChange={handleInputChange}
                                                key={state.stage.job_id}
                                            >
                                                <option selected disabled value="">
                                                    Избор
                                            </option>
                                                {projectContext.data.job.map(item =>
                                                    <option value={item.id} key={item.job}>
                                                        {item.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        {projectContext.data.have_road ? <div className="col">
                                            <label>
                                                Вид път
                                        </label>
                                            <select
                                                name="road_id"
                                                value={state.stage && state.stage.road_id ? state.stage.road_id : ''}
                                                onChange={handleInputChange}
                                            >
                                                <option selected disabled value="">
                                                    Избор
                                            </option>
                                                {projectContext.data.road.map(item =>
                                                    <option value={item.id} key={item.job}>
                                                        {item.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                            :
                                            ''}
                                    </div>
                                    {projectContext.data.have_sidewalk ? <div className="row x2">
                                        <div className="col">
                                            <label>
                                                Ляв тротоар
                                        </label>
                                            <select
                                                name="firstsidewalk_id"
                                                value={state.stage && state.stage.firstsidewalk_id ? state.stage.firstsidewalk_id : ''}
                                                onChange={handleInputChange}
                                            >
                                                <option selected disabled value="">
                                                    Избор
                                            </option>
                                                {projectContext.data.sidewalk.map(item =>
                                                    <option value={item.id} key={item.job}>
                                                        {item.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label>
                                                Десен тротоар
                                        </label>
                                            <select
                                                name="secondsidewalk_id"
                                                value={state.stage && state.stage.secondsidewalk_id ? state.stage.secondsidewalk_id : ''}
                                                onChange={handleInputChange}
                                            >
                                                <option selected disabled value="">
                                                    Избор
                                            </option>
                                                {projectContext.data.sidewalk.map(item =>
                                                    <option value={item.id} key={item.job}>
                                                        {item.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                        :
                                        ''}
                                </div>
                                <div className="row x2">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="date-from">
                                                Начало
                                            </label>
                                            <input
                                                id="date-from"
                                                name='fromdate'
                                                type="date"
                                                value={state.stage.from_ymd ? state.stage.from_ymd : ''}
                                                onChange={handleFromDateChange}
                                            />
                                        </div>
                                        <div className="col">
                                            <label htmlFor="deadline">
                                                Краен срок
                                            </label>
                                            <input
                                                id="deadline"
                                                name='todate'
                                                type="date"
                                                value={state.stage.to_ymd ? state.stage.to_ymd : ''}
                                                onChange={handleToDateChange}
                                            />
                                        </div>

                                        <div className="col full-width">
                                            <label>
                                                Коментар
                                            </label>
                                            <input
                                                name="comment"
                                                type="text"
                                                value={state.stage && state.stage.comment ? state.stage.comment : ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>


                                    </div>
                                </div>
                                {state.stage.id &&
                                    <>
                                        <hr />
                                        <p className="delete" onClick={handleDelete}>
                                            <img src={deleteIcon} alt="Изтриване" />
                                        Изтриване на {inCity ? 'адреса' : 'междушахтието'}
                                        </p>
                                    </>
                                }
                                <div className="buttons">
                                    {state.loading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        <>
                                            <button className="btn-cancel" onClick={handleClose}>
                                                Отказ
                                    </button>
                                            <button className="btn-add">
                                                {state.id ? 'Запазване' : 'Добавяне'}
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </form>

                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
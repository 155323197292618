import React from 'react'

//contexts
import { useFinanceContext } from '../../../../../_contexts/FinananceContex'
import { useAuthContext } from '../../../../../_contexts/AuthContext'

function Filter(props) {
    const financeContext = useFinanceContext()
    const auth = useAuthContext()
    const isForeman = auth.user.data.role_id == 2
    const data = props.data
    const handleFilter = e => {
        let name = e.target.name
        let val = e.target.value
        isForeman && props.handleFail(val)
        props.handleFilter(name, val)
    }
    return (
        <form
            autoComplete="off"
            role="presentation"
            className="filter-standard"
            style={{
                flexDirection: 'initial',
                flexWrap: 'wrap',
            }}
        >
            <div className="row">
                <div className="col">
                    <label htmlFor="project_id">
                        Проект
                    </label>
                    <select
                        id="project_id"
                        name="project_id"
                        onChange={handleFilter}
                    >
                        {auth.user.data.role_id != 2 && <option value="">Всички</option>}
                        {financeContext.projects.map(project =>
                            <option key={project.id} value={project.id}>
                                {project.name}
                            </option>
                        )}
                    </select>
                </div>

                <div className="col">
                    <label htmlFor="fromdate">
                        От
                    </label>
                    <input
                        type="date"
                        onChange={handleFilter}
                        name="fromdate"
                        id="fromdate"
                    />
                </div>
                <div className="col">
                    <label htmlFor="todate">
                        До
                    </label>
                    <input
                        type="date"
                        onChange={handleFilter}
                        name="todate"
                        id="todate"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label htmlFor="expense_id">
                        Тип Разход
                    </label>
                    <select
                        id="expense_id"
                        name="expense_id"
                        onChange={handleFilter}
                    >
                        <option value="">Всички</option>
                        {financeContext.data.expense_type && financeContext.data.expense_type.map(exp =>
                            <option key={exp.id} value={exp.id}>
                                {exp.name}
                            </option>
                        )}
                    </select>
                </div>

                <div className="col">
                    <label htmlFor="name">
                        Търсене
                    </label>
                    <input
                        type="search"
                        placeholder="Въведете име"
                        onChange={handleFilter}
                        name="name"
                        id="name"
                    />
                </div>
            </div>
        </form>
    )
}

export default Filter
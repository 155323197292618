import React, { createContext, useContext, useState, useMemo } from "react"

export const StorageContext = createContext({})

const Project = props => {
    const [storage, setStorage] = useState({
        data: []
    })

    const setData = data => {
        setStorage(storage => ({
            ...storage,
            data: data
        }))
    }

    const storageData = useMemo(() => ({
        ...storage,
        setData
    }), [storage])

    return <StorageContext.Provider value={storageData} {...props} />
}

export const useStorageContext = () => useContext(StorageContext)

export default Project
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'
import { useAuthContext } from '../../../../../_contexts/AuthContext'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import closeIcon from '../../../../../assets/images/modals/icons/close.png'

//modals
import SuccessModal from '../../../../modals/Success'
import AttentionModal from '../../../../modals/Attention'

//components
import Dropdown from '../../../../app/Dropdown'


function AddMaterial(props, ref) {

    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    const storage = {
        storage_id: null,
        material_id: null,
        current_price: null,
        single_price: null,
        quantity: 0,
        total_quantity: 0,
    }

    const [createEdit, setCreateEdit] = useState({
        overlay: false,
        modal: false,
        loading: false,
        storages: [],
        materials: [],
        //edit
        mainLoading: false,
        id: null,
        storage: storage,
        original: storage,
    })

    const form = document.getElementById('edit-form')

    //refs
    const successModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: (id) => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = (id) => {
        Api.get(`projects/getbyproject?project_id=${id}`)
            .then(res => {
                setCreateEdit(createEdit => ({
                    ...createEdit,
                    storages: res.data,
                }))
                stopMainLoading()
            })
        setCreateEdit(createEdit => ({
            ...createEdit,
            overlay: true,
            id: id,
            mainLoading: true,
            storage: {
                ...createEdit.storages,
                // total_quantity: quantity ? quantity : 0
            }
        }))
        setTimeout(() => {
            setCreateEdit(createEdit => ({
                ...createEdit,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            modal: false,
        }))
        setTimeout(() => {
            document.getElementById('edit-form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)

            removeValidations()
            setCreateEdit(createEdit => ({
                ...createEdit,
                overlay: false,
                mainLoabg: true,
                id: null,
                storage: storage,
                original: storage
            }))
        }, 100)
    }

    const handleChange = e => {
        let name = e.target.name
        let val = e.target.value
        setCreateEdit(createEdit => ({
            ...createEdit,
            storage: {
                ...createEdit.storage,
                [name]: val
            }
        }))
        if (name == 'storage_id') {
            Api.get(`storage/getitemstorage?id=${val}`)
                .then(res => {
                    setCreateEdit(createEdit => ({
                        ...createEdit,
                        materials: res.data,
                    }))
                })
        } else if (name == 'quantity') {
            setCreateEdit(prev => ({
                ...prev,
                storage: {
                    ...prev.storage,
                    current_price: createEdit.storage.single_price * Number(val)
                }
            }))
        }
    }
    const handleChangeMaterials = data => {
        if (data.id) {
            setCreateEdit(createEdit => ({
                ...createEdit,
                storage: {
                    ...createEdit.storage,
                    material_id: data.id,
                    current_price: Number(data.price),
                    single_price: Number(data.price)
                }
            }))

            Api.get(`stages/getitemquantity?id=${data.id}`)
                .then(res => {
                    setCreateEdit(createEdit => ({
                        ...createEdit,
                        storage: {
                            ...createEdit.storage,
                            total_quantity: res.data.quantity
                        }
                    }))

                })
        }
    }

    const handleClickMaterials = () => {
        if (!createEdit.storage.storage_id) attentionModalRef.current.open()
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()


        if (createEdit.id) data.append('project_id', createEdit.id)

        let url = 'projects/add-material'

        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors
                Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                        let inputsWithClass = form.querySelectorAll("[className='" + i[0] + "']")

                        inputs.forEach(i => i.classList.add('validate'))
                        inputsWithClass.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: false,
        }))
    }

    const stopMainLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            mainLoading: false,
        }))
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <div className={`${createEdit.overlay ? 'visible' : ''} overlay`}>
            <SuccessModal
                mainMessage={'Материалът е добавен успешно!'}
                secondaryMessage="Може да откриете промените в списъка с материали."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Моля изберете склад!"
                secondaryMessage="Склада и материалите са пряко обвързани."
            />
            <div className={`${createEdit.modal ? 'show' : ''} box box-small`}>
                <img src={closeIcon} className="close" onClick={handleClose} />
                <form id="edit-form" onSubmit={handleSubmit} className="create-task">
                    <h3>
                        Добавяне на материал
                    </h3>
                    {createEdit.mainLoading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Склад
                                    </label>
                                    <select
                                        defaultValue={createEdit.storage.storage_id}
                                        onChange={handleChange}
                                        name="storage_id"
                                    >
                                        <option selected disabled>
                                            Избор
                                        </option>
                                        {createEdit.storages.map(storage =>
                                            <option key={storage.id} value={storage.id}>
                                                {storage.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col full-width" onClick={handleClickMaterials}>
                                    <label>
                                        Материал
                                    </label>
                                    <Dropdown
                                        inputIdName="material_id"
                                        inputName="material_id"
                                        url='storage/getitemstorage'
                                        params={{
                                            id: createEdit.storage.storage_id,
                                        }}
                                        renderText={data => {
                                            return <>{data.material.name} - {data.material.specification} - {data.supplier && data.supplier.name + ' - '}{data.quantity} {data.material.volumetype}</>
                                        }}
                                        onChange={handleChangeMaterials}
                                    />
                                </div>
                            </div>

                            {createEdit.storage.material_id && <div className="row">
                                {!isForeman && <div className="col full-width">
                                    <label>
                                        Цена
                                    </label>
                                    <input
                                        type="text"
                                        readOnly
                                        value={`${createEdit.storage.current_price ? createEdit.storage.current_price : createEdit.storage.single_price} лв.`}
                                        name="current_price"
                                        onChange={handleChange}
                                    />
                                </div>}
                                <div className="col full-width">
                                    <label>
                                        Количество
                                    </label>
                                    <input
                                        type="number"
                                        defaultValue={createEdit.storage.quantity}
                                        name="quantity"
                                        min="1"
                                        max={createEdit.storage.total_quantity}
                                        className={createEdit.storage.total_quantity == 0 && 'validate'}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>}
                            <div className="buttons">
                                {createEdit.loading ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        <button className="btn-add">
                                            Добавяне
                                        </button>
                                    </>
                                }
                            </div>
                        </>
                    }
                </form>
            </div>
        </div >
    )
}

export default forwardRef(AddMaterial)
import React, { useImperativeHandle, forwardRef, useState } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'

function Activity(props, ref) {
    const [view, setView] = useState({
        overlay: false,
        modal: false,
        loading: true,
        data: []
    })

    useImperativeHandle(ref, () => ({
        open: (id,projectId) => handleOpen(id,projectId),
        close: () => handleClose(),
    }))

    const handleOpen = (id,projectId) => {
        setView(prev => ({
            ...prev,
            overlay: true,
        }))

        Api.get(`brigades/usertasks?project_id=${projectId}&user_id=${id}`)
            .then(res => {
                setView(prev => ({
                    ...prev,
                    data: res.data,
                    loading:false
                }))
            })

        setTimeout(() => {
            setView(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setView(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setView(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)
    }

    return (
        <div className={`${view.overlay ? 'visible' : ''} overlay`}>
            <div className={`${view.modal ? 'show' : ''} box box-small`}>
                <h3>
                    Дейност
                </h3>
                {view.loading 
                ? <img style={{width:'50px'}} src={loader} alt="loading" />:
                <>
                <div className='scroller-b'>
                {view.data.length > 0 ? view.data.map(task =>
                <>
                    <table className="statistic-table backgcolor">
                        <tbody>
                            <>
                                <tr>
                                    <td>
                                        Адрес: 
                                    </td>
                                    <td>
                                        {task.stage.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Задача: 
                                    </td>
                                    <td>
                                        {task.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Срок:
                                    </td>
                                    <td>
                                        <time>
                                            <span style={task.hasTask?{color:"forestgreen"}:{}}>
                                                {task.from_dmy} г.
                                                <br />
                                                {task.to_dmy} ч.
                                            </span>
                                        </time>
                                    </td>
                                </tr>
                            </>
                        </tbody>
                    </table>
                    {/* <hr/> */}
                </>
                ) : <><br/><h3>Все още няма добавени задачи<br/> за този потребител</h3></>}
                </div>
                <div className="buttons">
                    <button onClick={handleClose}>
                        Назад
                     </button>
                </div>
                </>}
            </div>
        </div>
        
    )
}

export default forwardRef(Activity)
import React from 'react'

//components
import Material from './Material'

//images
import tableFilterIcon from '../../../../../assets/images/pages/icons/table-filter.png'

function Table(props) {
    const materials = props.materials

    const handleQuantityFilter = () => props.quantityFilter()

    return (
        <div className="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Материал</th>
                        <th>Характ.</th>
                        <th>Склад</th>

                        {props.dataType == 2 ?
                            <th>Доставчик</th>
                            :
                            ''
                        }
                        <th>
                            <span
                                className={`quantity-filter ${props.quantityFilterApplied == 'quantity-desc' ? 'rotate' : ''}`}
                                onClick={handleQuantityFilter}
                            >
                                Наличност
                            <img src={tableFilterIcon} alt="Наличност" />
                            </span>
                        </th>
                        {props.dataType == 2 ?
                            <th>Цена</th>
                            :
                            ''
                        }
                        <th className="option">
                            Опции
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {materials.map(material =>
                        <Material
                            key={material.id}
                            material={material}
                            handleEdit={props.handleEdit}
                            dataType={props.dataType}
                            handleTransfer={props.handleTransfer}
                        />
                    )}
                </tbody>
            </table >
        </div>
    )
}

export default Table
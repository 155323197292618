import React, { useState } from 'react'

function Worker(props) {
    const [worker, setWorker] = useState({
        info: true,
    })
    const each = props.each

    const handleOk = e => {
        e.preventDefault()
        setWorker(worker => ({
            ...worker,
            info: false,
        }))
    }
    return (
        <li key={each.id}>
            <label className="checkbox">
                {each.name}
                <input autoComplete="off" role="presentation"
                    type="checkbox"
                    value={each.id}
                    checked={props.selectedWorkers}
                    onChange={props.handleSelectWorker}
                />
                <span className="checkmark"></span>
                <div className={`info ${props.selectedWorkers && worker.info && each.has_engagement ? 'show' : ''}`}>
                    <p>
                        {each.name} е зает в периода
                    </p>
                    <h3>
                        ({props.selectedStage.from_dmy} - {props.selectedStage.to_dmy})
                    </h3>
                    <button onClick={handleOk}>
                        Добре
                    </button>
                </div>
            </label>
        </li>
    )
}

export default Worker
import React, { useEffect, useState, useRef } from 'react'
import Api from '../../../../_helpers/Api'
import { useParams, useLocation } from 'react-router-dom'

//images
import loader from '../../../../assets/images/app/loader.svg'
import stageIcon from '../../../../assets/images/pages/icons/stage.png'
import leftArrowIcon from '../../../../assets/images/pages/icons/left-arrow-list.png'
import rightArrowIcon from '../../../../assets/images/pages/icons/right-arrow-list.png'
import priceCountIcon from '../../../../assets/images/pages/icons/price-count.png'
import removingIcon from '../../../../assets/images/pages/icons/removing.png'
import notFoundIcon from '../../../../assets/images/pages/icons/not-found.png'

//modals
import CreateEditModal from '../tasks/modals/CreateEdit'
import GalleryModal from '../../../modals/Gallery'
import CreateEditMaterialModal from '../../../modals/CreateEditMaterial'

//components
import List from '../tasks/partials/List'
import Pagination from '../../../app/Pagination'

function View(props) {
    const projectId = useParams().id
    const inCity = useParams().inCity == 1
    const location = useLocation()
    const stageId = new URLSearchParams(location.search).get('stage_id')
    const searchParams = new URLSearchParams(location.search)

    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    const currentDate = new Date().getDate()

    const [stage, setStage] = useState({
        loading: {
            main: true,
            tasks: true,
            materials: true,
        },
        data: {},
        tasks: [],
        tasksFilter: {
            clear: true,
            completed: true,
        },
        page: 1,
        workers: {
            totalWorkers: 0
        },
        original: {
            totalWorkers: 0
        },
        materials: [],
        materialsFilter: {
            name: ''
        },
        materialsPage: 1,
        materialsPages: searchParams.get('page') || 1,
        refresh: {
            main: null,
            tasks: null,
            materials: null,
        },
        tasksLeftArrow: false,
        tasksRightArrow: true,
        scrollPosition: 0,
        hasMoreItems: true,
        initial: true,
        currentMiniTab: 1,
    })

    //refs
    const createEditModalRef = useRef(null)
    const galleryModalRef = useRef(null)
    const createEditMaterialRef = useRef(null)
    //end refs

    useEffect(() => {
        Api.get(`stages/getbystage/data?id=${props.stageId}`)
            .then(res => {
                setStage(stage => ({
                    ...stage,
                    data: res.data,
                    loading: {
                        ...stage.loading,
                        main: false,
                    },
                }))
            })

        if (searchParams.get('create_task')) createEditModalRef.current.open()
    }, [])

    useEffect(() => {
        loadTasks(1, true)

        setStage(stage => ({
            ...stage,
            initial: false,
        }))

    }, [stage.refresh.tasks, stage.tasksFilter])
    const loadTasks = (page, initial) => {
        if (initial) startTasksLoading()

        Api.get(`stages/getbystage/task?id=${props.stageId}&page=${page}&sort=${!stage.tasksFilter.clear ? stage.tasksFilter.completed ? 'notcompleted' : 'completed' : ''}`)
            .then(res => {
                setStage(stage => ({
                    ...stage,
                    tasks: initial ? res.data.tasks : stage.tasks.concat(res.data.tasks),
                    hasMoreItems: res.data.tasks.length == 0 ? false : true,
                    tasksRightArrow: stage.hasMoreItems ? true : false,
                }))
            })
            .finally(() => stopTasksLoading())
    }
    const startTasksLoading = () => {
        setStage(stage => ({
            ...stage,
            loading: {
                ...stage.loading,
                tasks: true
            },
        }))
    }
    const stopTasksLoading = () => {
        setStage(stage => ({
            ...stage,
            loading: {
                ...stage.loading,
                tasks: false
            },
        }))
    }

    useEffect(() => {
        startMaterialsLoading()

        Api.get(`stages/getbystage/materials?id=${props.stageId}&page=${stage.materialsPage}&name=${stage.materialsFilter.name}`)
            .then(res => {
                setStage(stage => ({
                    ...stage,
                    materials: res.data,
                    materialsPages: res.data.page,
                }))
            })
            .finally(() => stopMaterialsLoading())
    }, [stage.refresh.materials, stage.materialsPage, stage.materialsFilter.name])
    const startMaterialsLoading = () => {
        setStage(stage => ({
            ...stage,
            loading: {
                ...stage.loading,
                materials: true
            },
        }))
    }
    const stopMaterialsLoading = () => {
        setStage(stage => ({
            ...stage,
            loading: {
                ...stage.loading,
                materials: false
            },
        }))
    }
    const handleRefreshMaterials = () => {
        setStage(stage => ({
            ...stage,
            refresh: {
                ...stage.refresh,
                materials: new Date().getTime()
            }
        }))
    }
    const handleCreateMaterial = (id, quantity) => createEditMaterialRef.current.open(id, quantity)
    const handleMaterialsPage = page => {
        setStage(stage => ({
            ...stage,
            materialsPage: page.selected + 1
        }))
    }
    const handleSearchMaterials = e => {
        let val = e.target.value

        setStage(stage => ({
            ...stage,
            materialsFilter: {
                ...stage.materialsFilter,
                name: val
            }
        }))
    }

    const handleEditTask = id => createEditModalRef.current.edit(id)
    const handleCreateTask = () => createEditModalRef.current.open(projectId)
    const handleUpdateTask = data => {
        let updated = stage.tasks.map(t => {
            if (Number(t.id) === Number(data.id)) {
                t = data
            }
            return t
        })

        props.handleRefreshData()

        setStage(prev => ({
            ...prev,
            tasks: updated
        }))
    }
    const handleRefreshTasks = () => {
        setStage(stage => ({
            ...stage,
            refresh: {
                ...stage.refresh,
                tasks: new Date().getTime()
            }
        }))
        props.handleRefreshData()
    }

    const handleViewImages = id => {
        let filteredImages = stage.tasks.filter(each => {
            return each.id == id
        })[0].images

        const images = filteredImages.map(image => {
            return image.url
        })
        galleryModalRef.current.open(images)
    }

    const handleScrollForward = () => {
        const scrollSection = document.querySelector('.tasks-list')
        scrollSection.scrollBy({
            top: 0,
            left: +150,
            behavior: 'smooth'
        })
        toggleArrows(scrollSection)
        setStage(stage => ({
            ...stage,
            tasksLeftArrow: true,
        }))
    }
    const handleScrollBack = () => {
        const scrollSection = document.querySelector('.tasks-list')

        scrollSection.scrollBy({
            top: 0,
            left: -150,
            behavior: 'smooth'
        })
        toggleArrows(scrollSection)
        setStage(stage => ({
            ...stage,
            tasksRightArrow: true
        }))
    }
    const toggleArrows = scrollSection => {
        setStage(stage => ({
            ...stage,
            scrollPosition: scrollSection.scrollLeft
        }))

        if (stage.scrollPosition == scrollSection.scrollLeft && scrollSection.scrollLeft == 0) {
            setStage(stage => ({
                ...stage,
                tasksLeftArrow: false
            }))
        } else if (stage.scrollPosition == scrollSection.scrollLeft && scrollSection.scrollLeft != 0) {
            setStage(stage => ({
                ...stage,
                tasksRightArrow: false,
                page: stage.page + 1
            }))

            if (stage.hasMoreItems) loadTasks(stage.page + 1)
        }
    }

    const handleCompletedTasksFilter = e => {
        let val = e.target.value

        setStage(stage => ({
            ...stage,
            tasksFilter: {
                ...stage.tasksFilter,
                clear: val == 0 ? true : false,
                completed: val != 0 ? val == 1 ? true : false : true
            }
        }))
    }

    const start = `${stage.data.from_year}-${stage.data.from_month}-${stage.data.from_day}`
    const end = `${stage.data.to_year}-${stage.data.to_month}-${stage.data.to_day}`
    const current = `${currentYear}-${currentMonth}-${currentDate}`

    const periodDaysDiff = (new Date(end) - new Date(start))

    const totalPeriod = periodDaysDiff / (1000 * 60 * 60 * 24)

    const periodToCurrentDate = (new Date(current) - new Date(start))

    const currentDayPeriod = periodToCurrentDate / (1000 * 60 * 60 * 24)

    const left = ((320 / totalPeriod) * currentDayPeriod) + 8

    return (
        <div className="wrapper" id="stage">
            <CreateEditModal
                ref={createEditModalRef}
                handleRefreshData={handleRefreshTasks}
                stageId={stageId}
                disabledStage={true}
                projectsFieldReadOnly={true}
            />
            <GalleryModal
                ref={galleryModalRef}
            />
            <CreateEditMaterialModal
                ref={createEditMaterialRef}
                handleRefreshData={handleRefreshMaterials}
                stageId={stageId}
            />
            {stage.loading.main
                ?
                <img src={loader} alt="loading" />
                :
                <>
                    <div className="top row">
                        <h1>
                            <span style={{ backgroundColor: stage.data.job_type.color }}>
                            </span>
                            {stage.data.name ? stage.data.name : 'от ' + stage.data.from + ' ' + 'до ' + stage.data.to}
                        </h1>
                        <h1></h1>
                        {stage.data.from_dmy ? <div className="period" style={{ backgroundColor: stage.data.job_type.color }}>
                            <div className="current-period">
                                <time className="left">
                                    {stage.data.from_dmy}
                                </time>
                                <time className="right">
                                    {stage.data.to_dmy}
                                </time>
                            </div>
                            {(left > 330 || left < 0) ?
                                ''
                                :
                                <div
                                    className={`current-date ${Number(stage.data.to_day) - Number(currentDate) < 10 ? 'mirror' : ''}`}
                                    style={{
                                        left: `${left}px`,
                                    }}
                                >
                                    <span className="date">
                                        {currentDate}-{currentMonth}-{currentYear}
                                    </span>
                                </div>}
                        </div> : ''}
                    </div>
                    <div className="row">
                        <form autoComplete="off" role="presentation" className="filter-standard">
                            <div className="row">
                                <h2>
                                    <img src={stageIcon} alt="stage" />
                                    Задачи за {inCity ? 'адреса' : 'междушахтието'}
                                </h2>
                                <select onChange={handleCompletedTasksFilter}>
                                    <option selected value="0">
                                        Всички
                                    </option>
                                    <option value="1">
                                        Незавършени
                                    </option>
                                    <option value="2">
                                        Завършени
                                    </option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className={`row horizontal-scroller ${stage.tasksLeftArrow ? 'left-fog' : ''} ${stage.tasksRightArrow ? 'right-fog' : ''} ${stage.tasks.length == 0 ? 'no-bg no-results' : ''}`}>
                        <img
                            src={leftArrowIcon}
                            alt="Назад"
                            className={`arrow left-arrow ${stage.tasksLeftArrow ? '' : 'hide'}`}
                            onClick={handleScrollBack}
                        />
                        <List
                            tasks={stage.tasks}
                            loading={stage.loading.tasks}
                            handleEditTask={handleEditTask}
                            handleRefreshData={handleRefreshTasks}
                            handleUpdateTask={handleUpdateTask}
                            handleViewImages={handleViewImages}
                        />
                        <img
                            src={rightArrowIcon}
                            alt="Напред"
                            className={`arrow right-arrow ${stage.tasksRightArrow ? '' : 'hide'}`}
                            onClick={handleScrollForward}
                        />
                        <button className="btn-extra-add" onClick={handleCreateTask}>
                            Добави
                        </button>
                    </div>
                    <hr />
                    <div className="row">
                        <form autoComplete="off" role="presentation" className="filter-standard">
                            <div className="row">
                                <h2>
                                    <img src={stageIcon} alt="stage" />
                                    Материали за {inCity ? 'адреса' : 'междушахтието'}
                                </h2>
                                <input
                                    type="search"
                                    placeholder="Търсене на материал..."
                                    onChange={handleSearchMaterials}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="row stretch">
                        <div className="col full-width">
                            <div className={`holder ${!stage.loading.materials && stage.materials.stageMaterials ? stage.materials.stageMaterials.length == 0 ? 'no-bg no-results' : '' : ''}`}>
                                {stage.loading.materials ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        <div className="table-wrapper">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Материал</th>
                                                        <th>Характ.</th>
                                                        <th>Доставчик</th>
                                                        <th>Кол.</th>
                                                        <th>Цена за бр.</th>
                                                        <th>Общо.</th>
                                                        <th className="option">

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {stage.materials.stageMaterials.map(material =>
                                                        <tr key={material.id}>
                                                            <td>
                                                                {material.materials.name}
                                                            </td>
                                                            <td>
                                                                {material.materials.specification}
                                                            </td>
                                                            <td>
                                                                {material.supplier ? material.supplier.name : 'Не е посочен'}
                                                            </td>
                                                            <td>
                                                                {material.quantity} бр.
                                                        </td>
                                                            <td>
                                                                {material.price} лв.
                                                        </td>
                                                            <td>
                                                                {material.sum} лв.
                                                         </td>
                                                            <td className="option">
                                                                <button
                                                                    className="return-button"
                                                                    onClick={() => handleCreateMaterial(material.id, material.quantity)}
                                                                >
                                                                    <img src={removingIcon} alt="Връщане" />
                                                                Връщане
                                                            </button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {!stage.loading.materials && <div className="row">
                                            <Pagination
                                                page={stage.materialsPage}
                                                pages={stage.materialsPages}
                                                handlePage={handleMaterialsPage}
                                            />
                                        </div>
                                        }
                                    </>
                                }
                                <hr />
                                {(!stage.loading.materials && stage.materials.stageMaterials.length == 0) && <div className="no-results">
                                    <img src={notFoundIcon} alt="Няма резултати" />
                                        Не бяха намерени резултати <br />
                                        от Вашето търсене.
                                    </div>}
                                <div className="row">
                                    <button className="btn-extra-add" onClick={() => handleCreateMaterial(null)}>
                                        Добави
                                    </button>

                                    <span className="sum">
                                        <img src={priceCountIcon} alt="Сума" />
                                        {stage.materials.totalPrice ? stage.materials.totalPrice : '0'} лв.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default View
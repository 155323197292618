import React, { useImperativeHandle, forwardRef, useState } from 'react'

//images
import questionImage from '../../assets/images/modals/icons/question.png'

function Question(props, ref) {

    const [modal, setModal] = useState({
        blur: false,
        popup: false,
        id: null,
    })

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClsoe()
    }))

    const handleOpen = id => {
        setModal(modal => ({
            ...modal,
            blur: true,
            id: id,
        }))
        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                popup: true,
            }))
        }, 100)
    }

    const handleClsoe = () => {
        setModal(modal => ({
            ...modal,
            popup: false,
        }))
        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                blur: false,
                id: null,
            }))
        }, 50)
    }

    const handleConfirm = () => {
        props.handleCallback(modal.id)
        handleClsoe()
    }

    return (
        <div className={`overlay ${modal.blur ? 'visible' : ''}`}>
            <div className={`${modal.popup ? 'show' : ''} box`}>
                <img src={questionImage} className="main-image" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">
                    <button onClick={handleClsoe}>
                        Отказ
                    </button>
                    <button className="btn-primary" onClick={handleConfirm}>
                        Да
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Question)
import React, { useImperativeHandle, forwardRef, useState } from 'react'

//images
import removingIcon from '../../../assets/images/pages/icons/removing.png'
import addingIcon from '../../../assets/images/pages/icons/adding.png'

function View(props, ref) {
    const [view, setView] = useState({
        overlay: false,
        modal: false,
        activity: {
            material: {
                name: ''
            },
            storage: {
                name: ''
            },
            dmy: null,
            his: null,
            quantity: 0,
            price: 0,
        }
    })

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        let activity = props.data.filter(each => {
            return each.id == id
        })[0]

        setView(prev => ({
            ...prev,
            overlay: true,
            activity: activity
        }))
        setTimeout(() => {
            setView(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setView(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setView(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)
    }

    return (
        <div className={`${view.overlay ? 'visible' : ''} overlay`}>
            <div className={`${view.modal ? 'show' : ''} box box-small`}>
                <h3>
                    Преглед на последни дейности
                </h3>
                <table className="statistic-table">
                    <tbody>
                        <tr>
                            <td>
                                Процедура:
                            </td>
                            <td>
                                <time>
                                    {view.activity.type == 0 ?
                                        <img src={addingIcon} alt="Приемане" />
                                        :
                                        <img src={removingIcon} alt="Изписване" />
                                    }
                                    <span>
                                        {view.activity.dmy} г.
                                        <br />
                                        {view.activity.his} ч.
                                    </span>
                                </time>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Склад:
                            </td>
                            <td>
                                {view.activity.storage.name ? view.activity.storage.name : 'Склада е изтрит'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Материал:
                            </td>
                            <td> {view.activity.material.name}

                            </td>
                        </tr>
                        <tr>
                            <td>
                                Количество:
                            </td>
                            <td>
                                {view.activity.quantity}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Ед. цена:
                            </td>
                            <td>
                                {view.activity.price} лв
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>
                                    На обща стойност:
                                </b>
                            </td>
                            <td>
                                <b>
                                    {(view.activity.quantity * view.activity.price).toFixed(2)} лв
                                </b>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="buttons">
                    <button onClick={handleClose}>
                        Назад
                     </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(View)
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'
import { useHistory } from 'react-router-dom'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import deleteIcon from '../../../../../assets/images/modals/icons/delete.png'

//modals
import SuccessModal from '../../../../modals/Success'
import DeleteModal from '../../../../modals/Remove'

//contexts
import { useProjcetContext } from '../../../../../_contexts/ProjectContext'

//componets
import SelectWorkersField from '../../../../modals/partials/SelectWorkersField'
import SelectMachinesField from '../../../../modals/partials/SelectMachinesField'
import Dropdown from '../../../../app/Dropdown'

function CreateEdit(props, ref) {
    const projectContext = useProjcetContext()
    const date = new Date()

    const history = useHistory()

    const brigade = {
        name: '',
        workers: [],
        machines: []
    }

    const materials = {
        foreman_name: [],
        foreman_id: []
    }

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        //data
        id: null,
        brigadeId: null,
        project_id: null,
        brigade: brigade,
        original: brigade,
        mainLoading: false,
        foremans: [],
        materials: materials,
        оriginals: materials,
    })

    const form = document.getElementById('formBrigade')

    //refs
    const successModalRef = useRef(null)
    const deleteModalRef = useRef(null)
    //end refs

    useImperativeHandle(ref, () => ({
        open: (id, project_id, check) => handleOpen(id, project_id, check),
        edit: (id, project_id) => handleOpen(id, project_id),
        close: () => handleClose(),
    }))

    const handleOpen = (id = null, project_id, check) => {
        if (id) loadData(id)

        Api.get('foremans/get')
            .then(res => {
                setState(prev => ({
                    ...prev,
                    foremans: res.data
                }))
            })

        setState(state => ({
            ...state,
            checked: check,
            brigadeId: id,
            project_id: project_id,
            overlay: true,
            brigade: brigade,
            original: brigade,
            materials: materials,
            originals: materials,
            mainLoading: id ? true : false,
        }))

        setTimeout(() => {
            setState(state => ({
                ...state,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setState(state => ({
            ...state,
            modal: false,
        }))

        setTimeout(() => {
            form.reset()

            removeValidations()

            setState(state => ({
                ...state,
                overlay: false,
                brigade: brigade,
                materials: materials,
                originals: materials,
                mainLoading: true,
            }))
        }, 100)
    }

    const loadData = id => {
        Api.get(`brigades/getbybrigadeid?brigade_id=${id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    brigadeId: id,
                    brigade: res.data.brigade.id ? res.data.brigade : brigade,
                    original: res.data.brigade.id ? res.data.brigade : brigade,
                    materials: res.data,
                    originals: res.data,
                    mainLoading: false
                }))
            })
    }

    // console.log()

    const handleSubmit = e => {
        e.preventDefault()

        removeValidations()

        startLoading()

        let data = new FormData(e.target)
        if (state.brigadeId) {
            data.append('brigade_id', state.brigadeId)
        }
        data.append('project_id', state.project_id)

        if (!state.checked) {
            window.history.back()
        }

        Api.post('brigades/addedit', data)
            .then(() => {
                successModalRef.current.open()
                if (!state.checked) {
                    window.location.reload()
                }
                props.handleRefreshData()

            })
            .catch(err => {
                let fields = err.response && err.response.data && err.response.data.errors

                if (typeof fields === 'object') {

                    Object.entries(fields).map(i => {
                        if (i[0]) {
                            let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                            inputs.forEach(i => i.classList.add('validate'))
                        }
                    })
                }

            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setState(state => ({
            ...state,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setState(state => ({
            ...state,
            loading: false,
        }))
    }

    const removeValidations = () => {
        if (form) form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))
    }

    const setWorkers = data => {
        if (Array.isArray(data)) {
            setState(prev => ({
                ...prev,
                brigade: {
                    ...prev.brigade,
                    workers: data
                },
                original: {
                    ...prev.original,
                    workers: data
                }
            }))
        }
    }

    const setMachines = data => {
        if (Array.isArray(data)) {
            setState(prev => ({
                ...prev,
                brigade: {
                    ...prev.brigade,
                    machines: data
                },
                original: {
                    ...prev.original,
                    machines: data
                }
            }))
        }
    }

    const handleInputChange = e => {
        let name = e.target.name
        let val = e.target.value

        setState(prev => ({
            ...prev,
            brigade: {
                ...prev.brigade,
                [name]: val
            }
        }))
    }

    const handleDelete = e => {
        e.preventDefault()
        deleteModalRef.current.open()
    }
    const handleConfirmDelete = () => {
        window.history.back()
        deleteModalRef.current.close()
        handleClose()
        Api.post('brigades/delete', {
            id: state.brigade.id
        })
            .then(() => {
                window.location.reload()
                props.handleRefreshData()
            })
    }

    return (
        <div className={`${state.overlay ? 'visible' : ''} overlay`}>
            <SuccessModal
                ref={successModalRef}
                mainMessage="Бригадата е запазена успешно!"
                secondaryMessage=""
                handleSuccessAction={handleClose}
            />
            <DeleteModal
                ref={deleteModalRef}
                mainMessage="Потвърждавате ли изтриванието на бригадата?"
                handleRemoveCallback={handleConfirmDelete}
            />
            <div className="scroller">
                <div className={`${state.modal ? 'show' : ''} box box-large`}>
                    <h2>
                        {state.brigade.id ? 'Редактиране на бригада' : 'Добавяне на бригада'}
                    </h2>
                    <p className="left">
                        Опишете основната информация по новата бригада
                </p>

                    <form id="formBrigade" onSubmit={handleSubmit}>
                        {state.mainLoading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <div className="row x2">
                                    <div className="col">
                                        <label>
                                            Име
                                    </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={state.brigade && state.brigade.name ? state.brigade.name : ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="col full-width">
                                        <label>
                                            Бригадири
                                    </label>
                                        <Dropdown
                                            inputIdName="foreman_id"
                                            url='foremans/get'
                                            multiple={true}
                                            inputClassName="foreman_id"
                                            multipleInputValues={state.materials.foreman_name}
                                            multipleInputIdValues={state.materials.foreman_id}
                                            renderText={data => {
                                                return <>{data.name}</>
                                            }}
                                        />
                                    </div>

                                </div>

                                <div className="row">
                                    <SelectMachinesField
                                        stageId={state.project_id}
                                        machines={state.brigade.machines}
                                        setMachines={setMachines}
                                        brigade_id={state.brigadeId}
                                        isBrigade={true}
                                        isTask={false}
                                    />

                                    <SelectWorkersField
                                        stageId={state.project_id}
                                        workers={state.brigade.workers}
                                        brigade_id={state.brigadeId}
                                        setWorkers={setWorkers}
                                        isBrigade={true}
                                        isTask={false}
                                    />
                                </div>
                                {state.brigade.id &&
                                    <>
                                        <hr />
                                        <p className="delete" onClick={handleDelete}>
                                            <img src={deleteIcon} alt="Изтриване" />
                                        Изтриване на бригадата
                                    </p>
                                    </>
                                }
                                <div className="buttons">
                                    {state.loading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        <>
                                            <button className="btn-cancel" onClick={handleClose}>
                                                Отказ
                                    </button>
                                            <button className="btn-add">
                                                {state.brigade.id ? 'Запазване' : 'Добавяне'}
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </form>

                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
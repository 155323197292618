import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthContext } from '../_contexts/AuthContext'

function ProtectedRoute({ component: Component, ...rest }) {
    const auth = useAuthContext()

    return (
        <Route
            {...rest}
            render={props => {
                if (auth.isLogged) {
                    if (rest.view !== 'undefined') {
                        if (!rest.view) {
                            return <h1>Unauthorized</h1>
                        }
                    }
                    return <Component {...props} />
                } else {
                    return <Redirect to="/login" />
                }
            }}
        />
    )
}

export default ProtectedRoute

import React from 'react'

//components
import Worker from './Worker'

function Table(props) {
    const workers = props.workers

    return (
        <div className="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Име</th>
                        <th>ЕИК/Булстат</th>
                        <th>Адрес</th>
                        <th>Тел. номер</th>
                        <th>Е-поща</th>
                        <th className="option">
                            Опции
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {workers.map(worker =>
                        <Worker
                            worker={worker}
                            handleEdit={props.handleEdit}
                        />
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default Table
import React from 'react'

//components
import Material from './Material'

//images
import tableFilterIcon from '../../../../../assets/images/pages/icons/table-filter.png'
import { useAuthContext } from '../../../../../_contexts/AuthContext'

function Table(props) {
    const materials = props.materials


    return (
        <div className="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>Разход</th>
                        <th>От</th>
                        {/* <th>Дата</th> */}

                        <th>
                            <span
                                className={`quantity-filter ${props.dateFilterApplied == 'date-desc' ? 'rotate' : ''}`}
                                onClick={() => props.dateFilter()}
                            >
                                Дата
                            <img src={tableFilterIcon} alt="Наличност" />
                            </span>
                        </th>

                        <th>
                            <span
                                className={`quantity-filter ${props.priceFilterApplied == 'price-desc' ? 'rotate' : ''}`}
                                onClick={() => props.priceFilter()}
                            >
                                На стойност
                            <img src={tableFilterIcon} alt="Наличност" />
                            </span>
                        </th>
                        <th>
                            Вид плащане
                    </th>
                        <th className="option">
                            Опции
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {materials.map(material =>
                        <Material
                            material={material}
                            handleEdit={props.handleEdit}
                            key={material.id}
                        />
                    )}
                </tbody>
            </table >
        </div>
    )
}

export default Table
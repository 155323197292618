import React from 'react'

function Filter(props) {

    const handleFilter = e => {
        let name = e.target.name
        let val = e.target.value

        props.handleFilter(name, val)
    }
    const handleProjectType = e => {
        const projectTypeId = e.target.value
   
        props.handleProjectType(projectTypeId)
    }
    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            <div className="col">
                <label htmlFor="name">
                    Име
                </label>
                <input
                    type="search"
                    id="name"
                    name="name"
                    placeholder="Въведете име..."
                    onChange={handleFilter}
                />
            </div>
            <div className="col">
                <label htmlFor="name">
                    Тип проект
                </label>
                <select
                    id="type"
                    name="project_type_id"
                    onChange={handleProjectType}
                >
                    <option value="0">Всички</option>
                    {props.types.map(type =>
                        <option key={type.id} value={type.id}>
                            {type.name}
                        </option>
                    )}
                </select>
            </div>
        </form>
    )
}

export default Filter
import React, { useImperativeHandle, forwardRef, useState } from 'react'

//images
import closeIcon from '../../assets/images/modals/icons/close.png'


function Gallery(props, ref) {

    const [modal, setModal] = useState({
        blur: false,
        popup: false,
        images: [],
        url: '',
        current: 0,
        total: 0,
    })

    useImperativeHandle(ref, () => ({
        open: images => handleOpen(images),
        close: () => handleClsoe()
    }))

    const handleOpen = images => {
        setModal(modal => ({
            ...modal,
            blur: true,
            images: images,
            url: images[0],
            total: images.length,
        }))
        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                popup: true,
            }))
        }, 100)
    }

    const handleClsoe = () => {
        setModal(modal => ({
            ...modal,
            popup: false,
        }))
        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                blur: false,
            }))
        }, 50)
    }

    const handleNext = () => {
        let next = modal.current += 1
        if (modal.current == modal.total) next = 0
        let nextImage = modal.images[next]

        setModal(modal => ({
            ...modal,
            url: nextImage,
            current: next,
        }))
    }

    const handlePrev = () => {
        let prev = modal.current -= 1
        if (modal.current < 0 || prev < 0) prev = modal.total - 1
        let prevImage = modal.images[prev]

        setModal(modal => ({
            ...modal,
            url: prevImage,
            current: prev,
        }))
    }

    return (
        <div className={`overlay ${modal.blur ? 'visible' : ''}`}>
            <div className={`box gallery ${modal.popup ? 'show' : ''}`}>
                <img src={closeIcon} className="close" onClick={handleClsoe} />
                <picture>
                    <img src={modal.url} />
                </picture>
                <div className="buttons">
                    <button onClick={handlePrev}>
                        Предишна
                    </button>
                    <button onClick={handleNext}>
                        Следваща
                    </button>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(Gallery)
import React, { useState, useEffect, useRef } from 'react'
import Api from '../../../_helpers/Api'

//images
import removingIcon from '../../../assets/images/pages/icons/removing.png'
import addingIcon from '../../../assets/images/pages/icons/adding.png'
import removingFilledIcon from '../../../assets/images/pages/icons/removing-filled.png'
import addingFilledIcon from '../../../assets/images/pages/icons/adding-filled.png'
import loader from '../../../assets/images/app/loader.svg'

//modals
import ViewActivity from '../modals/ViewActivity'

function LastActivities(props) {
    const [activities, setActivities] = useState({
        data: [],
        loading: false,
        filter: {
            type: 2
        },
    })

    //refs
    const viewActivityRef = useRef(null)
    //end refs

    useEffect(() => {
        setActivities(prev => ({
            ...prev,
            loading: true
        }))

        Api.get(`storage/procedures/get?type=${activities.filter.type}`)
            .then(res => {
                setActivities(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false
                }))
            })
    }, [activities.filter.type, props.refresh])


    //filter
    const handleAdding = () => {
        setActivities(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                type: activities.filter.type == 0 ? 2 : 0
            }
        }))
    }
    const handleRemoving = () => {
        setActivities(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                type: activities.filter.type == 1 ? 2 : 1
            }
        }))
    }

    const handleViewLastActivities = id => viewActivityRef.current.open(id)

    return (
        <>
            <ViewActivity
                ref={viewActivityRef}
                data={activities.data}
            />
            <div className="row filter-acitivites">
                <button
                    onClick={handleAdding}
                    className={activities.filter.type == 0 ? 'active' : ''}
                >
                    <img src={addingFilledIcon} alt="Приемане" />
                    Приемане
                </button>
                <button
                    onClick={handleRemoving}
                    className={activities.filter.type == 1 ? 'active' : ''}
                >
                    <img src={removingFilledIcon} alt="Изписване" />
                    Изписване
                </button>
            </div>
            <hr />
            {activities.loading ?
                <img src={loader} className="loading-activities" alt="loading" />
                :
                activities.data.length == 0 ?
                    <p className="no-results">
                        Няма дейности в склада
                    </p>
                    :
                    <table className="main-table">
                        <thead>
                            <tr>
                                <th>
                                    Процедура
                                </th>
                                <th>
                                    Склад
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {activities.data.map(activity =>
                                <tr key={activity.id} onClick={() => handleViewLastActivities(activity.id)}>
                                    <td>
                                        <time>
                                            {activity.type == 0 ?
                                                <img src={addingIcon} alt="Приемане" />
                                                :
                                                <img src={removingIcon} alt="Изписване" />
                                            }
                                            <span>
                                                {activity.dmy} г.
                                            </span>
                                        </time>
                                    </td>
                                    <td>
                                        <div>
                                            {activity.storage.name ? activity.storage.name : 'Няма данни'}
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
            }
        </>
    )
}

export default LastActivities
import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'

//images
import loader from '../../../assets/images/app/loader.svg'

//contexts
import { useFinanceContext } from '../../../_contexts/FinananceContex'
import { useAuthContext } from '../../../_contexts/AuthContext'

function LastActivities(props, ref) {
    const financeContext = useFinanceContext()
    const auth = useAuthContext()

    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1
    const tab = props.tab
    const [activities, setActivities] = useState({
        data: {},
        loading: false,
        title: 'Баланс общо'
    })

    useImperativeHandle(ref, () => ({
        curr: (val) => handleCheck(val),
    }))

    useEffect(() => {
        setActivities(prev => ({
            ...prev,
            data: financeContext.data,
            paid: financeContext.paid,
            unpaid: financeContext.unpaid,
        }))
        setTimeout(() => {
            setActivities(createEdit => ({
                ...createEdit,
            }))
        }, 50)
    }, [financeContext.data, financeContext.paid, financeContext.unpaid])

    const handleCheck = (val) => {
        let row = Array.from(document.getElementsByClassName('bt'))
        row.forEach(t => {
            t.classList.remove('active')
        });
        let balance;
        if (val == 0) {
            balance = 'Баланс общо'
        }
        if (val == 2 || val == 4) {
            let cash = document.getElementById('btn-2')
            cash.classList.add('active')
            balance = 'Баланс кеш'
        }
        if (val == 3) {
            let card = document.getElementById('btn-3')
            card.classList.add('active')
            balance = 'Баланс карта'
        }

        setActivities(prev => ({
            ...prev,
            title: balance
        }))
    }

    const handleClick = (e, id) => {
        let balance;

        if (id == 3) {
            balance = 'Баланс карта'
        } else {
            balance = 'Баланс кеш'
        }

        setActivities(prev => ({
            ...prev,
            title: balance,
        }))

        let active = e.target
        let row = Array.from(document.getElementsByClassName('bt'))
        row.forEach(t => {
            t.classList.remove('active')
        });
        active.classList.add('active')
        props.card(id)
    }

    return (

        <>
            {activities.data.length == 0 ?
                <p className="no-results">
                    Няма финансова активност
            </p>
                :
                isForeman &&
                <>
                    <div className="row filter-acitivites">
                        <button
                            id='btn-3'
                            name='btn-3'
                            onClick={(e) => handleClick(e, 3)}
                            className='bt'
                        >
                            Баланс карта
                        </button>
                        <button
                            id='btn-2'
                            name='btn-2'
                            onClick={(e) => handleClick(e, 2)}
                            className='bt'
                        >
                            Баланс кеш
                        </button>
                    </div>
                </>}

            {activities.loading ?
                <img src={loader} className="loading-activities" alt="loading" />
                : <table className="main-table finances-table">

                    <thead>
                        <tr>
                            {isAdmin && tab != 3 && <>
                                <th>
                                    Общ приход
                            </th>
                                <th>
                                    Общ разход
                            </th>
                            </>}
                            {tab == 3 &&
                                <>
                                    <th>
                                        Платени:
                                </th>
                                    <th>
                                        Неплатени:
                                </th>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {isAdmin && tab != 3 ? <td className="income">
                                {financeContext.data.income} лв.
                                </td> :
                                <>
                                    {tab != 3 && <td>
                                        Общ разход:
                                </td>}
                                </>
                            }
                            {tab != 3 && <td
                                className="expense"
                                style={isForeman ? { textAlign: 'right' } : {}}
                            >
                                {financeContext.data.expense} лв.
                        </td>}
                        </tr>

                        {isForeman &&
                            <>
                                <tr>
                                    <td>
                                        Общ превод:
                                    </td>
                                    <td className="income" style={{ textAlign: 'right' }}>
                                        {financeContext.data.income} лв.
                                    </td>
                                </tr>
                            </>
                        }

                        <tr>
                            {tab == 3 ?
                                <>
                                    <td className="income">
                                        {financeContext.paid}
                                    </td>

                                    <td className="expense">
                                        {financeContext.unpaid}
                                    </td>
                                </>
                                : ''}
                        </tr>

                    </tbody>
                </table>}

            {financeContext.data.remaining && <div className="finances-remaining">
                <h4 id='type'>
                    {isForeman ? activities.title : 'Разлика'}
                </h4>
                <hr />
                <span>
                    {activities.loading ?
                        <img src={loader} className="loading-activities" alt="loading" />
                        : financeContext.data.remaining + 'лв.'}
                </span>
            </div>
            }
        </>
    )
}

export default forwardRef(LastActivities)

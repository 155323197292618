import React, { useState, useRef } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import dotsBlack from '../../../../../assets/images/pages/icons/dots-black.png'

//images
import deleteIcon from '../../../../../assets/images/modals/icons/delete.png'


function Material(props) {
    const material = props.material

    const [mat, setMat] = useState({
        optionsMenu: false,
    })

    const [hover, setHover] = useState(false)

    const handleMenuOptions = () => {
        setMat(material => ({
            ...material,
            optionsMenu: material.optionsMenu ? false : true
        }))
    }

    const handleClose = () => {
        if (mat.optionsMenu) {
            setMat(material => ({
                ...material,
                optionsMenu: false
            }))
        }
    }

    const handleTransfer = id => {
        props.handleTransfer(id)
        handleClose()
    }

    const handleOpen = id => {
        props.handleOpen(id)
        // handleClose()
    }

    const handleEnterHover = () => {
        setHover(true)
    }

    const handleLeaveHover = () => {
        setHover(false)
    }

    return (
        <>
            <tr key={material.id} >
                <td className={hover ? 'project-mats' : ''} onClick={() => handleOpen(material.id)} onMouseEnter={handleEnterHover} onMouseLeave={handleLeaveHover}>
                    {material.materials.name}
                </td>
                <td className={hover ? 'project-mats' : ''} onClick={() => handleOpen(material.id)} onMouseEnter={handleEnterHover} onMouseLeave={handleLeaveHover}>
                    {material.materials.specification}
                </td>
                <td className={hover ? 'project-mats' : ''} onClick={() => handleOpen(material.id)} onMouseEnter={handleEnterHover} onMouseLeave={handleLeaveHover}>
                    {material.quantity}
                </td>
                <td className={hover ? 'project-mats' : ''} onClick={() => handleOpen(material.id)} onMouseEnter={handleEnterHover} onMouseLeave={handleLeaveHover}>
                    {material.storagequantity.filter(t => material.materials.id == t.material_id)[0].quantity
                        + material.materials.volumetype
                        + ' / '
                        + material.storage.name
                    }
                </td>
                <td className={hover ? 'option project-mats' : 'option'}>
                    <img
                        src={dotsBlack}
                        alt="Опции"
                        title="Опции"
                        onClick={handleMenuOptions}
                        style={{ width: '14px' }}
                    />
                    <ClickAwayListener onClickAway={handleClose}>
                        <div className={`options ${mat.optionsMenu ? 'active' : ''}`}>
                            <div className="menu-item" onClick={() => handleTransfer(material.id)}>
                                Трансфер
                            </div>
                        </div>
                    </ClickAwayListener>
                </td>
            </tr>
        </>
    )
}

export default Material
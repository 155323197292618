import React, { useState, useRef } from 'react'


//images
import deleteIcon from '../../../../../assets/images/modals/icons/delete.png'


function Worker(props) {
    const worker = props.worker
    const isForeman = props.isForeman
    const handleOpen = id => {
        props.handleOpen(id)
    }
    return (
        worker.role_id != 2 &&
        <>
            <tr key={worker.id}>
                <td>
                    {worker.name}
                </td>
                <td>
                    <img
                        src={worker.type.image}
                        alt={worker.type.name}
                        title={worker.type.name}
                        className="icon"
                    />
                    {worker.type && worker.type.name}
                </td>

                {!isForeman && <td>
                    <button className='btn-transfer' onClick={() => handleOpen(worker.id)}>
                        Дейност
                    </button>
                </td>}

                {!isForeman && <td className="option">
                    <img
                        src={deleteIcon}
                        alt="Премахване"
                        title="Премахване"
                        onClick={() => props.handleRemoveWorker(worker.id)}
                    />
                </td>}
            </tr>
        </>
    )
}

export default Worker
import React from 'react'

//components
import Chart from './Chart'

//images
import deadlineIcon from '../../../assets/images/app/icons/deadline.png'
import clientIcon from '../../../assets/images/app/icons/client.png'

function ProjectData(props) {
    const projectData = props.data

    return (
        <div className="project-data">
            {projectData.type ? <img src={projectData.type.image} className="type-icon" alt={projectData.type.name} /> : ''}
            <h1>
                {projectData.name}
            </h1>
            <p className="client">
                <img src={clientIcon} alt="Клиент" />
                <span>
                    Клиент:
                </span>
                {projectData.client.name}
            </p>
            <p className="client">
                <img src={deadlineIcon} alt="Краен срок" />
                <span>
                    Краен срок:
                </span>
                {projectData.to_dmy}
            </p>
            <Chart
                progress={projectData.progress}
                tasksCount={projectData.tasks_count}
                tasksCompleted={projectData.completed_tasks_count}
            />
        </div>
    )
}

export default ProjectData
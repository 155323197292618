import React, { useEffect, useState, useRef } from 'react'
import Api from '../../../../_helpers/Api'
import { useParams } from 'react-router-dom'


//images
import loader from '../../../../assets/images/app/loader.svg'
import notFoundIcon from '../../../../assets/images/pages/icons/not-found.png'

//modals
import AddMaterial from './modals/AddMaterial'
import Transfer from './modals/Transfer'
import MaterialsTable from './partials/MaterialsTable'
import Quantity from './modals/Quantity'
import StorageTable from './partials/StorageTable'

function Index() {
    const projectId = useParams().id

    const [materials, setMaterials] = useState({
        data: {
            data: [],
            filter: {
                name: ''
            }
        },
        loading: true,
        refresh: null,
    })

    const [storage, setStorage] = useState({
        data: {
            data: [],
            filter: {
                storage_id: ''
            }
        },
        storage: [],
        loading: true,
        refresh: null,
    })

    //refs
    const createEditRef = useRef(null)
    const transferModalRef = useRef(null)
    const viewQuantity = useRef(null);
    //end refs

    const handleAddEdit = (e) => {
        e.preventDefault()
        createEditRef.current.open(projectId)
    }

    useEffect(() => {
        Api.get(`projects/get-materials?id=${projectId}`)
            .then(res => {
                setMaterials(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        data: res.data.projectMaterials
                    },
                    loading: false,
                }))
            })
    }, [materials.refresh])

    useEffect(() => {
        Api.get(`projects/getprojectstorage?project_id=${projectId}&storage_id=${storage.data.filter.storage_id}`)
            .then(res => {
                setStorage(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        data: res.data
                    },
                    loading: false,
                }))
            })
    }, [storage.refresh])

    useEffect(() => {
        Api.get(`projects/getbyproject?project_id=${projectId}`)
            .then(res => {
                setStorage(prev => ({
                    ...prev,
                    storage: res.data,
                    loading: false,
                }))
            })
    }, [storage.refresh])

    const handleMaterialsFilter = e => {
        let name = e.target.name
        let val = e.target.value

        setMaterials(prev => ({
            ...prev,
            data: {
                ...prev.data,
                filter: {
                    ...prev.data.filter,
                    [name]: val,
                }
            }
        }))
    }

    const handleStoragesFilter = e => {
        let name = e.target.name
        let val = e.target.value

        setStorage(prev => ({
            ...prev,
            data: {
                ...prev.data,
                filter: {
                    ...prev.data.filter,
                    [name]: val,
                }
            },
            loading: true,
            refresh: new Date().getTime()
        }))
    }

    const handleRefreshData = () => {
        setMaterials(prev => ({
            ...prev,
            refresh: new Date()
        }))
        setStorage(prev => ({
            ...prev,
            refresh: new Date()
        }))
    }

    const handleTransfer = id => transferModalRef.current.open(id)
    const handleOpen = id => viewQuantity.current.open(id)

    return (
        <div className="wrapper task brigade">

            <AddMaterial
                ref={createEditRef}
                handleRefreshData={handleRefreshData}
            />

            <Transfer
                ref={transferModalRef}
                handleRefreshData={handleRefreshData}
            />

            <Quantity
                ref={viewQuantity}
            />

            {materials.loading ?
                <img src={loader} alt="loading" />
                :
                materials.data.data.length == 0 ?
                    <div className="no-results">
                        <img src={notFoundIcon} alt="Няма резултати" />
                            Няма добавени материали
                        <br />
                            към този проект
                        <br />
                        <br />
                        <button className="btn-extra-add" onClick={handleAddEdit}>
                            Добави
                        </button>
                    </div>
                    :
                    <>

                        <div style={{ marginTop: '75px' }} className="row">
                            <div className="col" style={{ width: '100%' }}>
                                <h2>
                                    Материали в проекта
                                </h2>
                                <form autoComplete="off" role="presentation" className="filter-standard">
                                    <div className="row" style={{ width: '100%' }}>
                                        <div className="col">
                                            <label>
                                                Име
                                            </label>
                                            <input
                                                type="search"
                                                placeholder="Търсене на материали..."
                                                name="name"
                                                onChange={handleMaterialsFilter}
                                            />
                                        </div>
                                        <div className='col' style={{ marginLeft: 'auto', marginTop: 'auto' }}>
                                            <button className="btn-add" onClick={handleAddEdit}>
                                                Добави
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        {materials.loading ?
                            <img src={loader} alt="loading" />
                            :
                            materials.data.data.length == 0 ?
                                <div className="no-results">
                                    <img src={notFoundIcon} alt="Няма резултати" />
                                    Не бяха намерени резултати.

                                </div>
                                :
                                <MaterialsTable
                                    materials={materials.data.data}
                                    handleTransfer={handleTransfer}
                                    handleOpen={handleOpen}
                                />
                        }
                        <hr />
                        <div className="row">
                            <div className="col">
                                <h2>
                                    Материали в склада
                                </h2>
                                <form autoComplete="off" role="presentation" className="filter-standard">
                                    <div className="row">
                                        <div className="col">
                                            <label>
                                                Склад
                                            </label>
                                            <select
                                                name="storage_id"
                                                onChange={handleStoragesFilter}
                                            >
                                                <option value="">
                                                    Всички
                                                </option>
                                                {storage.storage && storage.storage.map(store =>
                                                    <option key={store.id} value={store.id}>
                                                        {store.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {storage.loading ?
                            <img src={loader} alt="loading" />
                            :
                            storage.data.length == 0 ?
                                <div className="no-results">
                                    <img src={notFoundIcon} alt="Няма резултати" />
                                    Не бяха намерени резултати.
                                </div>
                                :
                                <StorageTable
                                    materials={storage.data}
                                />
                        }
                    </>
            }
        </div>
    )
}

export default Index
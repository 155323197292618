import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Api from '../../_helpers/Api'

//components
import Filter from './partials/Filter'
import List from './partials/List'
import Pagination from '../app/Pagination'

//modals
import CreateModal from './modals/Create'

//contexts
import { useAuthContext } from '../../_contexts/AuthContext'

function Index() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    const [projects, setProjects] = useState({
        data: [],
        loading: true,
        pages: 1,
        page: searchParams.get('page') || 1,
        filter: {
            name: '',
            date: 'desc'
        },
        refresh: null,
    })

    //refs
    const createModalRef = useRef(null)
    //end refs

    useEffect(() => {
        startLoading()
        Api.get(`projects/get?page=${projects.page}&data=${projects.filter.name}&sort=date_${projects.filter.date}`)
            .then(res => {
                setProjects(projects => ({
                    ...projects,
                    data: res.data.items,
                    pages: res.data.pages,
                }))
            })
            .finally(() => stopLoading())
    }, [projects.page, projects.filter, projects.refresh])

    const startLoading = () => {
        setProjects(projects => ({
            ...projects,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setProjects(projects => ({
            ...projects,
            loading: false,
        }))
    }

    const handlePage = page => {
        setProjects(projects => ({
            ...projects,
            page: page.selected + 1
        }))
    }

    //filters
    const handleFilterByName = value => {
        setProjects(projects => ({
            ...projects,
            filter: {
                ...projects.filter,
                name: value
            }
        }))
    }
    const handleSortByDate = () => {
        setProjects(projects => ({
            ...projects,
            filter: {
                ...projects.filter,
                date: projects.filter.date == 'desc' ? 'asc' : 'desc'
            }
        }))
    }
    //end filters

    const handleCreateProject = () => createModalRef.current.open()

    //refresh
    const handleRefreshData = () => {
        setProjects(projects => ({
            ...projects,
            refresh: new Date()
        }))
    }

    return (
        <section id="projects">
            <CreateModal
                ref={createModalRef}
                handleRefreshData={handleRefreshData}
            />
            <div className="wrapper">
                <div className="wrapper top row">
                    <h1>
                        Проекти
                    </h1>
                    {isAdmin && <button className="btn-add" onClick={handleCreateProject}>
                        Проект
                    </button>}
                </div>
                <div className="row">
                    <Filter
                        handleFilterByName={handleFilterByName}
                        handleSortByDate={handleSortByDate}
                        filterByDate={projects.filter.date}
                    />
                </div>
                <hr />
                <List
                    projects={projects.data}
                    loading={projects.loading}
                    handleRefreshData={handleRefreshData}
                />
                {!projects.loading && <div className="row">
                    <Pagination
                        page={projects.page}
                        pages={projects.pages}
                        handlePage={handlePage}
                    />
                </div>}
            </div>
        </section>
    )
}

export default Index
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import closeIcon from '../../../../../assets/images/modals/icons/close.png'

//modals
import SuccessModal from '../../../../modals/Success'
import RemoveModal from '../../../../modals/Remove'

//contexts
import { useStorageContext } from '../../../../../_contexts/StorageContext'

//components
import Dropdown from '../../../../app/Dropdown'

function CreateEdit(props, ref) {
    const storageContext = useStorageContext()

    const materials = {
        storage_id: null,
        material: {
            name: '',
            specification: ''
        },
        material_id: null,
        supplier: {
            name: '',
        },
        supplier_id: null,
        price: null,
        quantity: null
    }

    const [createEdit, setCreateEdit] = useState({
        overlay: false,
        modal: false,
        types: [],
        clients: [],
        loading: false,
        //edit
        mainLoading: false,
        id: null,
        materials: materials,
        original: materials,
    })

    const form = document.getElementById('edit-form')

    //refs
    const successModalRef = useRef(null)
    const removeModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            overlay: true,
            id: id,
            mainLoading: id ? true : false,
        }))
        setTimeout(() => {
            setCreateEdit(createEdit => ({
                ...createEdit,
                modal: true,
            }))
        }, 50)

        if (id) {
            Api.get(`storage/getbyid?id=${id}`)
                .then(res => {
                    setCreateEdit(createEdit => ({
                        ...createEdit,
                        materials: res.data,
                        original: res.data,
                    }))
                })
                .finally(() => stopMainLoading())
        }
    }

    const handleClose = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            modal: false,
        }))
        removeModalRef.current.close()
        setTimeout(() => {
            document.getElementById('edit-form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)

            removeValidations()
            setCreateEdit(createEdit => ({
                ...createEdit,
                overlay: false,
                mainLoading: true,
                id: null,
                materials: materials,
                original: materials
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()

        let url

        if (createEdit.id) {
            data.append('id', createEdit.id)
            url = 'storage/update'
        }
        else url = 'storage/add'

        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors

                Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                        let inputsWithClass = form.querySelectorAll("[className='" + i[0] + "']")

                        inputs.forEach(i => i.classList.add('validate'))
                        inputsWithClass.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: false,
        }))
    }

    const stopMainLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            mainLoading: false,
        }))
    }

    const handleDelete = e => {
        e.preventDefault()
        removeModalRef.current.open()
    }
    const handleRemoveCallback = () => {
        startLoading()
        handleClose()
        Api.post('storage/delete', {
            id: createEdit.id
        })
            .then(() => {
                stopLoading()
                props.handleRefreshData()
            })
    }

    const handleChange = e => {
        let name = e.target.name
        let val = e.target.value

        setCreateEdit(createEdit => ({
            ...createEdit,
            materials: {
                ...createEdit.materials,
                [name]: val
            }
        }))
    }
    const handleChangeMaterials = data => {

    }

    const handleChangeSuppliers = data => {

    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <div className={`${createEdit.overlay ? 'visible' : ''} overlay`}>
            <RemoveModal
                mainMessage="Сигурни ли сте, че искате да изтриете тоз материал?"
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                loading={createEdit.loading}
            />
            <SuccessModal
                mainMessage={createEdit.id ? 'Успешно редактирахте материал!' : 'Материала е добавен успешно!'}
                secondaryMessage="Може да откриете промените в списъка с материали."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <div className={`${createEdit.modal ? 'show' : ''} box box-small`}>
                <img src={closeIcon} className="close" onClick={handleClose} />
                <form id="edit-form" onSubmit={handleSubmit} className="create-task">
                    <h3>
                        {createEdit.id ?
                            'Редакция на материал' :
                            'Добавяне на материал'
                        }
                    </h3>
                    {createEdit.mainLoading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Склад
                                    </label>
                                    <select
                                        defaultValue={createEdit.materials.storage_id}
                                        name="storage_id"
                                        onChange={handleChange}
                                    >
                                        <option selected disabled>
                                            Избор
                                        </option>
                                        {storageContext.data.map(storage =>
                                            <option key={storage.id} value={storage.id}>
                                                {storage.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Материал
                                    </label>
                                    <Dropdown
                                        inputIdName="material_id"
                                        inputClassName="material_id"
                                        url='materials/get'
                                        params={{
                                            id: createEdit.materials.material_id
                                        }}
                                        inputValue={`${createEdit.materials.material.name}${createEdit.materials.material.name && createEdit.materials.material.specification ? ' - ' : ''}${createEdit.materials.material.specification}`}
                                        inputIdValue={createEdit.materials.material_id}
                                        renderText={data => {
                                            return <>{data.name} - {data.specification}</>
                                        }}
                                        onChange={handleChangeMaterials}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Доставчик
                                    </label>
                                    <Dropdown
                                        inputIdName="supplier_id"
                                        inputClassName="supplier_id"
                                        url='suppliers/get'
                                        params={{
                                            id: createEdit.materials.supplier_id
                                        }}
                                        inputValue={createEdit.materials.supplier.name}
                                        inputIdValue={createEdit.materials.supplier_id}
                                        renderText={data => {
                                            return <>{data.name}</>
                                        }}
                                        onChange={handleChangeSuppliers}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col full-width">
                                    <label>
                                        Цена за бр.
                                    </label>
                                    <input
                                        type="number"
                                        defaultValue={createEdit.materials.price && createEdit.materials.price}
                                        name="price"
                                        step="0.01"
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="col full-width">
                                    <label>
                                        Количество
                                    </label>
                                    <input
                                        type="number"
                                        defaultValue={createEdit.materials.quantity}
                                        name="quantity"
                                        min="0"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="buttons">
                                {createEdit.loading ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        {createEdit.id && <button className="btn-delete" onClick={handleDelete}>
                                            Изтриване
                                        </button>}
                                        <button className="btn-add">
                                            {createEdit.id ?
                                                'Запазване' :
                                                'Добавяне'
                                            }
                                        </button>
                                    </>
                                }
                            </div>
                        </>
                    }
                </form>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
import React from 'react'

function Material(props) {
    const each = props.material
    return (
        <tr key={each.id}>
            <td title={each.type == 1
                ? each.income_type.name
                : each.type == 2 ? each.expense_type.name
                    : each.type == 3 ? each.income_type.name : '--'}>

                {each.type == 1
                    ? each.income_type.name.length > 10 ? each.income_type.name.substring(0, 10) + '...' : each.income_type.name : each.type == 2
                        ? each.expense_type.name.length > 10 ? each.expense_type.name.substring(0, 10) + '...' : each.expense_type.name : each.type == 3
                            ? each.income_type.name : 'Превод'}

            </td>
            <td title={each.type == 1 ? each.client.name && each.client.name : each.type == 2 ? each.worker && each.worker.name : each.type == 3 ? 'Връщане в склад' : 'Не е посочен'}>
                {each.type == 1 ? each.client && each.client.name.length > 10 ?
                    each.client && each.client.name.substring(0, 10) + '...' : each.project && each.project.name : '-' ?
                        each.type == 2 ? each.worker && each.worker.name.length > 10 ?
                            each.worker && each.worker.name.substring(0, 10) + '...' : each.worker && each.worker.name : '-' : '-' ?
                            each.type == 3 ? 'Склад' : '-' : '-'}
            </td>
            <td title={each.dmy}>
                {each.dmy}
            </td>
            <td
                className={`${each.type == 1 || each.type == 3 ? 'income' : 'expense'}`}
                title={`${each.price} лв.`}
            >
                {each.price} лв.
            </td>
        </tr>
    )
}

export default Material
import React, { useState, useImperativeHandle, forwardRef } from 'react'

//images
import removeIcon from '../../assets/images/modals/icons/remove.png'
import loader from '../../assets/images/app/loader.svg'

function Remove(props, ref) {

    const [remove, setRemove] = useState({
        overlay: false,
        modal: false,
        onSuccess: null,
        onCancel: null,
        id: null,
    })

    useImperativeHandle(ref, () => ({
        open: id => {
            setRemove(remove => ({
                ...remove,
                overlay: true,
                id: id
            }))
            setTimeout(() => {
                setRemove(remove => ({
                    ...remove,
                    modal: true,
                }))
            }, 50)
        },
        close: () => handleClose(),
        onSuccess: fn => {
            setRemove(prev => ({
                ...prev,
                onSuccess: fn
            }))
        },
        onCancel: fn => {
            setRemove(prev => ({
                ...prev,
                onCancel: fn
            }))
        }
    }))

    const handleClose = () => {
        setRemove(remove => ({
            ...remove,
            modal: false,
        }))

        setTimeout(() => {
            setRemove(remove => ({
                ...remove,
                overlay: false,
                id: null,
            }))
        }, 100)
    }

    const handleCancel = () => {
        handleClose()

        if (typeof remove.onCancel === 'function') remove.onCancel()
    }

    const handleRemove = e => {
        e.preventDefault()

        if (typeof props.handleRemoveCallback === 'function') props.handleRemoveCallback(remove.id)
        else if (typeof remove.onSuccess === 'function') remove.onSuccess()

        handleClose()
    }

    return (
        <div className={`${remove.overlay ? 'visible' : ''} overlay`}>
            <div className={`${remove.modal ? 'show' : ''} box delete-modal`}>
                <img src={removeIcon} alt="remove" />
                <h3>
                    {props.mainMessage}
                </h3>
                <p>
                    {props.secondaryMessage}
                </p>
                <div className="buttons">

                    <button onClick={handleCancel}>
                        Отказ
                    </button>
                    <button className="btn-delete" onClick={handleRemove}>
                        Изтриване
                    </button>

                </div>
            </div>
        </div>
    )
}

export default forwardRef(Remove)
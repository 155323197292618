import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from 'react'
import Api from '../../../../../_helpers/Api'
import { Link, useParams } from 'react-router-dom'
// import { useParams } from 'react-router-dom'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import deleteIcon from '../../../../../assets/images/modals/icons/delete.png'

//modals
import SuccessModal from '../../../../modals/Success'
import DeleteModal from '../../../../modals/Remove'
import AttentionModal from '../../../../modals/Attention'
import AttentionNoStagesModal from '../../../../modals/Attention'

//components
import SelectWorkersField from '../../../../modals/partials/SelectWorkersField'
import SelectMachinesField from '../../../../modals/partials/SelectMachinesField'

//contexts
import { useProjcetContext } from '../../../../../_contexts/ProjectContext'

function CreateEdit(props, ref) {
    const projectContext = useProjcetContext()
    const projectId = useParams().id

    const task = {
        id: '',
        stage_id: props.stageId ? props.stageId : '',
        workers: [],
        machines: [],
        position: 0,
    }

    const brigade = {
        name: '',
        workers: [],
        machines: []
    }

    const [state, setState] = useState({
        overlay: false,
        modal: false,
        loading: false,
        mainLoading: false,
        //data
        id: null,
        task: task,
        original: task,
        selectedProjectId: null,
        brigades: [],
        brigadeId: null,
        brigade: brigade,
        original: brigade,
        mainLoading: false,
    })

    const [tasks, setTasks] = useState([])
    const [volumes, setVolumes] = useState([])

    const form = document.getElementById('form')

    //refs
    const successModalRef = useRef(null)
    const deleteModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const attentionNoStagesModalRef = useRef(null)
    //end refs

    useImperativeHandle(ref, () => ({
        open: () => handleOpen(),
        edit: id => handleEdit(id),
        close: () => handleClose(),
    }))

    useEffect(() => {
        if (state.task.stage_id) {
            Api.get('tasks/getbystageid?stage_id=' + state.task.stage_id)
                .then(res => setTasks(res.data))
        }

    }, [state.task.stage_id, state.modal])

    useEffect(() => {
        Api.get('volume/get')
            .then(res => setVolumes(res.data))

        Api.get(`brigades/getbyprojectid?project_id=${projectId}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    brigades: res.data
                }))
            })
    }, [])

    const handleOpen = (id = null) => {
        if (id) loadData(id)

        if (form) form.reset()

        removeValidations()

        setState(state => ({
            ...state,
            id: id,
            task: task,
            mainLoading: id ? true : false
        }))

        handleShow()
    }

    const handleEdit = id => handleOpen(id)

    const handleShow = () => {
        setState(state => ({
            ...state,
            overlay: true,
        }))

        setTimeout(() => {
            setState(state => ({
                ...state,
                modal: true,
            }))
            !props.projectsFieldReadOnly && (document.querySelector('select[name="project_id"]').value = 0)
        }, 50)
    }

    const handleClose = e => {
        if (e) e.preventDefault()
        setState(state => ({
            ...state,
            modal: false,
            brigadeId: null,
            brigade: brigade,
        }))

        setTimeout(() => {
            setState(state => ({
                ...state,
                overlay: false,
            }))
        }, 100)
    }

    const loadData = id => {
        Api.get('tasks/getid?id=' + id)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    task: res.data,
                    original: res.data,
                    mainLoading: false,
                }))
            })
    }

    const handleSubmit = e => {
        e.preventDefault()

        removeValidations()

        setState(state => ({
            ...state,
            loading: true,
        }))

        let data = new FormData(e.target)

        if (state.id) data.append('id', state.id)

        let url = state.id ? 'tasks/update' : 'tasks/add'

        Api.post(url, data)
            .then(() => {
                handleClose()
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response && err.response.data && err.response.data.errors

                if (typeof fields === 'object') {
                    Object.entries(fields).map(i => {
                        if (i[0]) {
                            let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                            inputs.forEach(i => i.classList.add('validate'))
                        }
                    })
                }
            })
            .finally(() => {
                setState(state => ({
                    ...state,
                    loading: false,
                }))
            })
    }

    const handleDelete = e => {
        e.preventDefault()

        handleClose()

        let modal = deleteModalRef.current

        modal.open()

        modal.onSuccess(() => {
            Api.post('tasks/delete', {
                id: state.task.id
            })
                .then(() => props.handleRefreshData())

            modal.close()
        })

        modal.onCancel(() => handleShow())
    }

    const setWorkers = data => {
        if (Array.isArray(data)) {
            setState(prev => ({
                ...prev,
                task: {
                    ...prev.task,
                    workers: data
                },
                original: {
                    ...prev.original,
                    workers: data
                }
            }))
        }
    }

    const setMachines = data => {
        if (Array.isArray(data)) {
            setState(prev => ({
                ...prev,
                task: {
                    ...prev.task,
                    machines: data
                },
                original: {
                    ...prev.original,
                    machines: data
                }
            }))
        }
    }

    const handleInputChange = e => {
        let name = e.target.name
        let val = e.target.value

        setState(prev => ({
            ...prev,
            task: {
                ...prev.task,
                [name]: val
            }
        }))
    }

    const handleStageChange = e => {
        let name = e.target.name
        let val = e.target.value

        let task = state.task
        let original = state.original

        if (task) {
            let oldStageId = state.original.stage_id

            if (Number(oldStageId) === Number(val)) {
                setState(prev => ({
                    ...prev,
                    task: {
                        ...prev.task,
                        workers: original.workers,
                        machines: original.machines,
                    }
                }))
            } else {
                setState(prev => ({
                    ...prev,
                    task: {
                        ...prev.task,
                        workers: [],
                        machines: [],
                    }
                }))
            }
        }

        setState(prev => ({
            ...prev,
            task: {
                ...prev.task,
                [name]: val
            },
        }))
    }

    const handleFromDateChange = e => {
        let val = e.target.value

        setState(prev => ({
            ...prev,
            task: {
                ...prev.task,
                from_ymd: val
            }
        }))
    }

    const handleToDateChange = e => {
        let val = e.target.value

        setState(prev => ({
            ...prev,
            task: {
                ...prev.task,
                to_ymd: val
            }
        }))
    }

    const handleCheckboxChange = e => {
        let name = e.target.name
        name == "required_priority" && (name = "position")

        setState(prev => ({
            ...prev,
            task: {
                ...prev.task,
                [name]: !prev.task[name]
            }
        }))
    }

    const handlePositionChange = e => {
        let val = e.target.value

        setState(prev => ({
            ...prev,
            task: {
                ...prev.task,
                position: val
            }
        }))
    }

    const removeValidations = () => {
        if (form) form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))
    }


    //if projects are enabled
    const handleChangeProject = e => {
        if (props.projectsFieldReadOnly) return
        const val = e.target.value
        const getProject = props.projects.filter(project => {
            return project.id == val
        })[0]

        setState(prev => ({
            ...prev,
            selectedProjectId: val
        }))

        projectContext.setData({
            id: getProject.id,
            stages: getProject.stages
        })
    }

    const handleAlertStage = () => {
        if (props.projectsFieldReadOnly) {
            if (projectContext.data.stages.length == 0) attentionNoStagesModalRef.current.open()
            return
        }

        if (projectContext.data.stages.length == 0 && !state.selectedProjectId) attentionModalRef.current.open()
        else if (projectContext.data.stages.length == 0 && state.selectedProjectId) attentionNoStagesModalRef.current.open()

    }

    const handleBrigadeChange = e => {
        // alert
        let brigade_id = e.target.value

        Api.get(`brigades/getbybrigadeid?brigade_id=${brigade_id}`)
            .then(res => {
                setState(prev => ({
                    ...prev,
                    brigadeId: brigade_id,
                    brigade: res.data.brigade.id ? res.data.brigade : brigade,
                    original: res.data.brigade.id ? res.data.brigade : brigade,
                    mainLoading: false
                }))
            })
    }

    //if projects are enabled end

    return (
        <>
            <SuccessModal
                ref={successModalRef}
                mainMessage="Задачата е запазена успешно!"
                secondaryMessage=""
                handleSuccessAction={handleClose}
            />
            <DeleteModal
                ref={deleteModalRef}
                mainMessage="Потвърждавате ли изтриванието на задачата?"
            />
            <div className={`${state.overlay ? 'visible' : ''} overlay`}>
                <AttentionModal
                    ref={attentionModalRef}
                    mainMessage="Моля, изберете проект!"
                    secondaryMessage="Проекта и адресите са пряко обвързани."
                />
                <AttentionNoStagesModal
                    ref={attentionNoStagesModalRef}
                    mainMessage="Моля, създайте адрес!"
                    secondaryMessage="За да продължите трябва първо да създадете адрес към избрания проект."
                />
                <div className="scroller">
                    <div className={`${state.modal ? 'show' : ''} box box-large`}>
                        <h2>
                            {state.id ? 'Редактиране на задача' : 'Добавяне на задача'}
                        </h2>
                        <p className="left">
                            Опишете основната информация по задачата
                        </p>
                        <form id="form" onSubmit={handleSubmit}>
                            {state.mainLoading
                                ?
                                <img src={loader} alt="loading" />
                                :
                                <>
                                    <div className="row x2">
                                        <div className="col">
                                            <label>
                                                Проект
                                            </label>
                                            <select
                                                name="project_id"
                                                value={projectContext.data.id}
                                                readOnly={props.projectsFieldReadOnly}
                                                onChange={handleChangeProject}
                                                defaultValue="0"
                                            >
                                                {props.projectsFieldReadOnly ?
                                                    <option value={projectContext.data.id}>
                                                        {projectContext.data.name}
                                                    </option>
                                                    :
                                                    <>
                                                        <option value="0" disabled selected>
                                                            Избор
                                                        </option>
                                                        {props.projects.map(project =>
                                                            <option value={project.id}>
                                                                {project.name}
                                                            </option>
                                                        )}
                                                    </>
                                                }
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label>
                                                Адрес
                                            </label>
                                            <select
                                                name="stage_id"
                                                value={state.task.stage_id}
                                                onChange={handleStageChange}
                                                onClick={handleAlertStage}
                                                readOnly={props.disabledStage ? true : false}
                                            >
                                                <option value="" selected disabled>
                                                    Избор
                                                </option>
                                                {projectContext.data.stages.map(stage =>
                                                    <option
                                                        key={'stage-' + stage.id}
                                                        value={stage.id}
                                                    >
                                                        {stage.name ? stage.name : stage.from + '-' + stage.to}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="date-from">
                                                        Начало
                                                    </label>
                                                    <input
                                                        name='fromdate'
                                                        type="date"
                                                        value={state.task.from_ymd ? state.task.from_ymd : ''}
                                                        onChange={handleFromDateChange}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="deadline">
                                                        Краен срок
                                                    </label>
                                                    <input
                                                        name='todate'
                                                        type="date"
                                                        value={state.task.to_ymd ? state.task.to_ymd : ''}
                                                        onChange={handleToDateChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col full-width">
                                            <label>
                                                Описание
                                            </label>
                                            <textarea
                                                name="name"
                                                value={state.task && state.task.name ? state.task.name : ''}
                                                onChange={handleInputChange}
                                            >
                                            </textarea>
                                        </div>
                                    </div>



                                    {state.id ?
                                        <>
                                            <hr />

                                            <div className='row'>
                                                <div className="col">
                                                    <label>
                                                        Бригада
                                                </label>
                                                    <select
                                                        name="brigade_id"
                                                        onChange={handleBrigadeChange}
                                                    >
                                                        <option value="" selected disabled>
                                                            Избор
                                                        </option>
                                                        {state.brigades.map(brigade =>
                                                            <option
                                                                key={'brigade-' + brigade.id}
                                                                value={brigade.id}
                                                            >
                                                                {brigade.name}
                                                            </option>
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                            <br />

                                            <div className="row x2">
                                                <SelectMachinesField
                                                    stageId={state.task.stage_id}
                                                    brigade_id={state.brigadeId}
                                                    machines={state.task.machines}
                                                    setMachines={setMachines}
                                                    isTask={true}
                                                />
                                                <SelectWorkersField
                                                    stageId={state.task.stage_id}
                                                    brigade_id={state.brigadeId}
                                                    workers={state.task.workers}
                                                    setWorkers={setWorkers}
                                                    isTask={true}
                                                />
                                            </div>
                                            <hr />
                                        </>
                                        :
                                        ''}

                                    <div className="row">
                                        <div className="col">
                                            <select
                                                name="position"
                                                value={state.task.position ? state.task.position : ''}
                                                onChange={handlePositionChange}
                                            >
                                                <option value="" >Избор</option>
                                                {tasks.filter(t => t.id !== state.task.id).map(t =>
                                                    <option key={'task-' + t.id} value={t.id}>
                                                        След {t.name}
                                                    </option>
                                                )}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className="checkbox">
                                            За завършване на задачата се изисква изображение
                                            <input autoComplete="off" role="presentation"
                                                type="checkbox"
                                                name="need_image"
                                                value="1"
                                                checked={state.task.need_image ? true : false}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    <div className="row">
                                        <label className="checkbox">
                                            Описване на обема
                                            <input autoComplete="off" role="presentation"
                                                type="checkbox"
                                                name="has_volume"
                                                value="1"
                                                checked={state.task.has_volume ? true : false}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    {state.task.has_volume &&
                                        <div className="row">
                                            <div className="col">
                                                <label>
                                                    Тип
                                                </label>
                                                <select
                                                    name="volume_id"
                                                    value={state.task.volume_id}
                                                    onChange={handleInputChange}
                                                >
                                                    {volumes.map(v =>
                                                        <option
                                                            value={v.id}
                                                            key={v.id}
                                                        >
                                                            {v.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col">
                                                <label>
                                                    Количество
                                                </label>
                                                <input
                                                    type="text"
                                                    name="size"
                                                    value={state.task.size ? state.task.size : ''}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    }
                                    {state.task.id &&
                                        <>
                                            <hr />
                                            <p className="delete" onClick={handleDelete}>
                                                <img src={deleteIcon} alt="Изтриване" />
                                                Изтриване на задачата
                                            </p>
                                        </>
                                    }
                                    <div className="buttons">
                                        {state.loading ?
                                            <img src={loader} alt="loading" />
                                            :
                                            <>
                                                <button className="btn-cancel" onClick={handleClose}>
                                                    Отказ
                                                </button>
                                                <button className="btn-add">
                                                    {state.id ? 'Запазване' : 'Добавяне'}
                                                </button>
                                            </>
                                        }
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default forwardRef(CreateEdit)
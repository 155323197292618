import React from 'react'

//images
import deleteIcon from '../../../../../assets/images/modals/icons/delete.png'

function Machine(props) {
    const machine = props.machines
    const isForeman = props.isForeman
    return (
        <tr key={machine.id}>
            <td>
                {machine.no}
            </td>
            <td>
                <img
                    src={machine.type.image}
                    alt={machine.type.name}
                    title={machine.type.name}
                    className="icon"
                />
                {machine.type.name}
            </td>
            {!isForeman && <td className="option">
                <img
                    src={deleteIcon}
                    alt="Премахване"
                    title="Премахване"
                    onClick={() => props.handleRemoveMachine(machine.id)}
                />
            </td>}
        </tr>
    )
}

export default Machine
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../_helpers/Api'
import Dropdown from '../../app/DropdownMultiple'

//images
import loader from '../../../assets/images/app/loader.svg'
import closeIcon from '../../../assets/images/modals/icons/close.png'
import deleteIcon from '../../../assets/images/modals/icons/delete.png'

//modals
import SuccessModal from '../../modals/Success'
import RemoveModal from '../../modals/Remove'

function Edit(props, ref) {
    //success modal message
    const initialMessage = 'Успешно редактиране на проект!'

    const defaultData = {
        storages: [],
        original: [],
    };

    const [edit, setEdit] = useState({
        overlay: false,
        modal: false,
        types: [],
        clients: [],
        loading: false,
        //edit
        storages: [],
        data: [],
        store: defaultData,
        storage: [],
        mainLoading: true,
        id: null,
        //success modal message
        mainMessage: initialMessage
    })

    const form = document.getElementById('edit-form')

    //refs
    const successModalRef = useRef(null)
    const removeModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        setEdit(edit => ({
            ...edit,
            overlay: true,
            id: id,
        }))
        setTimeout(() => {
            setEdit(edit => ({
                ...edit,
                modal: true,
            }))
        }, 50)


        Api.get('types/get')
            .then(res => {
                setEdit(edit => ({
                    ...edit,
                    types: res.data.items,
                }))

                Api.get('clients/get')
                    .then(res => {
                        setEdit(edit => ({
                            ...edit,
                            clients: res.data.items,
                        }))

                        Api.get(`projects/findbyid?id=${id}`)
                            .then(res => {
                                setEdit(edit => ({
                                    ...edit,
                                    storages: res.data.storages,
                                    data: res.data.projects,
                                    mainLoading: false,
                                }))
                            })
                    })
            })


    }

    const handleClose = () => {
        setEdit(edit => ({
            ...edit,
            modal: false,
            id: null,
        }))
        removeModalRef.current.close()
        setTimeout(() => {
            document.getElementById('form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)
            removeValidations()
            setEdit(edit => ({
                ...edit,
                overlay: false,
                mainLoading: true,
                mainMessage: initialMessage
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()

        data.append('id', edit.id)

        Api.post('projects/update', data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors

                Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")

                        inputs.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setEdit(edit => ({
            ...edit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setEdit(edit => ({
            ...edit,
            loading: false,
        }))
    }

    const handleDelete = e => {
        e.preventDefault()
        removeModalRef.current.open()
    }
    const handleRemoveCallback = () => {
        startLoading()
        Api.post('projects/delete', {
            id: edit.id
        })
            .then(() => {
                setEdit(edit => ({
                    ...edit,
                    mainMessage: 'Успешно изтрихте проект!'
                }))
                stopLoading()
                props.handleRefreshData()
                successModalRef.current.open()
            })
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))


    const handleChange = e => {
        const val = e.target.checked
        const name = e.target.name
        setEdit(prev => ({
            ...prev,
            data: {
                ...prev.data,
                [name]: val
            },
        }))
    }

    const handleChangeStorages = data => {
        let storages = [];
        data.map(storage => {
            storages.push(storage);
        });

        setEdit(prev => ({
            ...prev,
            storages: storages
        }));

    }


    const handleRemoveStorages = storageId => {
        let storages = [...edit.storages];

        storages = storages.filter(r => {
            return Number(r.id) !== Number(storageId);
        });

        setEdit(prev => ({
            ...prev,
            storages: storages
        }));
    }
    return (
        <div className={`${edit.overlay ? 'visible' : ''} overlay`}>
            <RemoveModal
                mainMessage="Сигурни ли сте, че искате да изтриете този проект?"
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                loading={edit.loading}
            />
            <SuccessModal
                mainMessage={edit.mainMessage}
                secondaryMessage="Може да откриете промените в списъка с проекти."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <div className="scroller">
                <div className={`${edit.modal ? 'show' : ''} box box-small`}>
                    <img src={closeIcon} className="close" onClick={handleClose} />
                    <form id="edit-form" onSubmit={handleSubmit} className="create-task">
                        <h3>
                            Редакция на проект
                    </h3>
                        {edit.mainLoading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <div className="row">
                                    <div className="col full-width">
                                        <label htmlFor="name">
                                            Име на проекта
                                    </label>
                                        <input autoComplete="off" role="presentation"
                                            type="text"
                                            placeholder="Въведете име..."
                                            id="name"
                                            name="name"
                                            defaultValue={edit.data.name}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col full-width">
                                        <label htmlFor="type_id">
                                            Тип
                                    </label>
                                        <select
                                            id="type_id"
                                            name="type_id"
                                            defaultValue={edit.data.type_id}
                                        >
                                            <option selected disabled>
                                                Избор
                                        </option>
                                            {edit.types.map(type =>
                                                <option key={type.id} value={type.id}>
                                                    {type.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col full-width">
                                        <label htmlFor="client_id">
                                            Клиенти
                                    </label>
                                        <select
                                            name="client_id"
                                            id="client_id"
                                            defaultValue={edit.data.client_id}
                                        >
                                            <option selected disabled>
                                                Избор
                                        </option>
                                            {edit.clients.map(client =>
                                                <option key={client.id} value={client.id}>
                                                    {client.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Склад
                                    </label>
                                        <Dropdown
                                            inputIdName="storage_id"
                                            inputClassName="storage_id"
                                            url={`storages/get-nopage`}
                                            multiple={true}
                                            selectedValues={edit.storages}
                                            onChange={handleChangeStorages}
                                            renderText={data => {
                                                return <>{data.name}</>
                                            }}
                                        />
                                    </div>
                                </div>
                                {edit.storages.length > 0 && <div className="row">
                                    <div className="col">
                                        <label>Избрани складове:</label>
                                        <ul className="scroller-list">
                                            {edit.storages && edit.storages.map(m =>
                                                <>
                                                    <li key={'storage-' + m.id} className="selected-list" >
                                                        {m ? m.name : ''}
                                                        <img
                                                            src={deleteIcon}
                                                            alt="Изтриване"
                                                            onClick={() => handleRemoveStorages(m.id)}
                                                        />
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>}

                                <div className="row">
                                    <div className="col">
                                        <label className="checkbox">
                                            Населено място
                                        <input
                                                autoComplete="off"
                                                role="presentation"
                                                type="checkbox"
                                                name="address_type"
                                                onChange={handleChange}
                                                checked={edit.data.address_type}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row x2">
                                    <div className="col">
                                        <label className="checkbox">
                                            Път
                                        <input
                                                autoComplete="off"
                                                role="presentation"
                                                type="checkbox"
                                                name="have_road"
                                                onChange={handleChange}
                                                checked={edit.data.have_road}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="col">
                                        <label className="checkbox">
                                            Tротоари
                                        <input
                                                autoComplete="off"
                                                role="presentation"
                                                type="checkbox"
                                                name="have_sidewalk"
                                                onChange={handleChange}
                                                checked={edit.data.have_sidewalk}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="fromdate">
                                            От
                                    </label>
                                        <input autoComplete="off" role="presentation"
                                            type="date"
                                            id="fromdate"
                                            name="fromdate"
                                            defaultValue={edit.data.from_ymd}
                                        />
                                    </div>
                                    <div className="col">
                                        <label htmlFor="todate">
                                            До
                                    </label>
                                        <input autoComplete="off" role="presentation"
                                            type="date"
                                            id="todate"
                                            name="todate"
                                            defaultValue={edit.data.to_ymd}
                                        />
                                    </div>
                                </div>

                                <div className="buttons">
                                    {edit.loading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        <>
                                            <button className="btn-delete" onClick={handleDelete}>
                                                Изтриване
                                        </button>
                                            <button className="btn-primary">
                                                Редакция
                                        </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div >
    )
}

export default forwardRef(Edit)
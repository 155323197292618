import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Api from '../../../../../_helpers/Api'
import { useHistory } from 'react-router-dom'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import notFoundIcon from '../../../../../assets/images/pages/icons/not-found.png'

//components
import Stage from './../partials/Stage'

//modals
import CreateEdit from './../modals/CreateEdit'
import QuestionModal from '../../../../modals/Question'
import QuestionCleanModal from '../../../../modals/Question'
import QuestionRemoveReportModal from '../../../../modals/Question'
import QuestionFinishSidewalkModal from '../../../../modals/Question'
import QuestionFinishRoad from '../../../../modals/Question'
import QuestionJobType from '../../../../modals/Question'
import AttentionModal from '../../../../modals/Attention'
import AttentionCleanModal from '../../../../modals/Attention'
import AttentionSidewalkFixedModal from '../../../../modals/Attention'
import AttentionRoadFixedModal from '../../../../modals/Attention'
import ReportModal from '../../../../modals/Report'
import QuestionModalFinish from '../../../../modals/Question'
import CompleteModal from '../../tasks/modals/Complete'

//contexts
import { useProjcetContext } from '../../../../../_contexts/ProjectContext'

function Table(props) {
    const history = useHistory()
    const projectContext = useProjcetContext()

    const projectId = useParams().id
    const inCity = useParams().inCity == 1

    const [isShown, setIsShown] = useState({
        id: null,
        shown: false
    });
    const [stages, setStages] = useState({
        data: {
            items: [

            ],
            job_types: [

            ]
        },
        loading: true,
        months: [
            'Януари',
            'Февруари',
            'Март',
            'Април',
            'Май',
            'Юни',
            'Юли',
            'Август',
            'Септември',
            'Октомври',
            'Ноември',
            'Декември'
        ],
        fromYear: null,
        fromMonth: null,
        refresh: null,
        filter: {
            name: '',
            number: '',
            job_id: '',
            note: '',
            sort: ''
        }
    })

    //refs
    const createEditModal = useRef(null)
    const questionModalRef = useRef(null)
    const questionCleanModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    const attentionCleanModalRef = useRef(null)
    const attentionSidewalkFixedModalRef = useRef(null)
    const attentionRoadFixedModalRef = useRef(null)
    const completeModalRef = useRef(null)
    const questionModalFinishRef = useRef(null)
    const reportModal = useRef(null)
    const questionRemoveReportModalRef = useRef(null)
    const questionFinishSidewalkModalRef = useRef(null)
    const questionFinishRoadModalRef = useRef(null)
    const questionJobTypeModalRef = useRef(null)
    //end refs

    useEffect(() => {
        startLoading()
        let url = `stages/getstage?id=${projectId}`
            + `&name=${stages.filter.name}`
            + `&number=${stages.filter.number}`
            + `&job_id=${stages.filter.job_id}`
            + `&note=${stages.filter.note}`
            + `&sort=${stages.filter.sort}`
        Api.get(url)
            .then(res => {
                setStages(stages => ({
                    ...stages,
                    data: res.data,
                }))
            })
            .finally(() => stopLoading())
    }, [stages.refresh])

    const startLoading = () => {
        setStages(stages => ({
            ...stages,
            loading: true
        }))
    }

    const stopLoading = () => {
        setStages(stages => ({
            ...stages,
            loading: false
        }))
    }

    const handleCreateStage = () => createEditModal.current.open()

    const refresh = () => {
        setStages(stages => ({
            ...stages,
            refresh: new Date().getTime()
        }))

        props.handleRefreshData()
    }

    const handleStageProcess = e => {
        let progress = e.target.getAttribute('data-checked')
        let stageId = e.target.getAttribute('data-value')
        if (progress == 0) questionModalRef.current.open(stageId)
        else attentionModalRef.current.open()
    }

    const handleCallback = stageId => {
        Api.get(`stages/process?id=${stageId}&process=1`)

        let filteredStages = stages.data.items.map(item => {
            if (item.id == stageId) item.process = 1

            return item
        })

        setStages(prev => ({
            ...prev,
            data: {
                ...prev.data,
                items: filteredStages
            }
        }))
    }

    const handleShowBrigade = (stageId, e) => {
        if (e.target.classList.contains('not-clickable')) return
        history.push(`?stage_id=${stageId}`)
    }

    const handleItsClean = (id, clean) => {
        if (clean == 0) questionCleanModalRef.current.open(id)
        else attentionCleanModalRef.current.open()
    }

    const handleItsCleanCallback = id => {
        Api.get(`stages/clean?clean=1&id=${id}`)
        let filteredStages = stages.data.items.map(item => {
            if (item.id == id) item.clean = 1

            return item
        })

        setStages(prev => ({
            ...prev,
            data: {
                ...prev.data,
                items: filteredStages
            }
        }))
    }

    const handleReport = id => reportModal.current.open(id)

    const handleReportSuccess = (id, reportMessage) => {
        const updateReports = stages.data.items.map(item => {
            if (item.id == id) item.note = reportMessage

            return item
        })

        setStages(prev => ({
            ...prev,
            data: {
                ...prev.data,
                items: updateReports
            }
        }))
    }

    const handleEditReport = id => {
        const currentReport = stages.data.items.filter(item => {
            return item.id == id
        })[0].note

        reportModal.current.open(id, currentReport)
    }

    const handleRemoveReport = id => questionRemoveReportModalRef.current.open(id)

    const handleRemoveReportCallback = id => {
        Api.get(`stages/removenote?id=${id}`)
        const updateReports = stages.data.items.map(item => {
            if (item.id == id) item.note = ''

            return item
        })

        setStages(prev => ({
            ...prev,
            data: {
                ...prev.data,
                items: updateReports
            }
        }))
    }

    const handleRealtimeChange = (addressId, optionId, parameter) => {
        let updateReports
        if (parameter == 'road_id') {
            const filtered = stages.data.roads.filter(item => {
                return item.id == optionId
            })[0].name

            updateReports = stages.data.items.map(item => {
                if (item.id == addressId) item.road = {
                    ...item.road,
                    name: filtered
                }

                return item
            })
        } else if (parameter == 'firstsidewalk_id') {
            const filtered = stages.data.sidewalks.filter(item => {
                return item.id == optionId
            })[0].name

            updateReports = stages.data.items.map(item => {
                if (item.id == addressId) item.first_side_walk = {
                    ...item.first_side_walk,
                    name: filtered
                }

                return item
            })
        } else if (parameter == 'secondsidewalk_id') {
            const filtered = stages.data.sidewalks.filter(item => {
                return item.id == optionId
            })[0].name

            updateReports = stages.data.items.map(item => {
                if (item.id == addressId) item.second_side_walk = {
                    ...item.second_side_walk,
                    name: filtered
                }

                return item
            })
        } else if (parameter == 'job_id') {
            questionJobTypeModalRef.current.open()
            updateReports = stages.data.items
        }

        setStages(prev => ({
            ...prev,
            data: {
                ...prev.data,
                items: updateReports
            }
        }))
    }

    const handleFinishSidewalk = (id, isFixed) => {
        if (isFixed) attentionSidewalkFixedModalRef.current.open()
        else questionFinishSidewalkModalRef.current.open(id)
    }

    const handleFinishSidewalkCallback = id => {
        Api.get(`stages/sidewalkfix?id=${id}&sidewalk_fix=1`)

        const updated = stages.data.items.map(item => {
            if (item.id == id) item.sidewalk_fix = 1

            return item
        })

        setStages(prev => ({
            ...prev,
            data: {
                ...prev.data,
                items: updated
            }
        }))
    }

    const handleFinishRoad = (id, isFixed) => {
        if (isFixed) attentionRoadFixedModalRef.current.open()
        else questionFinishRoadModalRef.current.open(id)
    }

    const handleConfirmFinishRoad = id => {
        Api.get(`stages/roadfix?id=${id}&road_fix=1`)

        const updated = stages.data.items.map(item => {
            if (item.id == id) item.road_fix = 1

            return item
        })

        setStages(prev => ({
            ...prev,
            data: {
                ...prev.data,
                items: updated
            }
        }))
    }

    const handleFinishTask = (id, type) => {
        if (type == 1) completeModalRef.current.open(id)
        else questionModalFinishRef.current.open(id)
    }

    const handleConfirmFinishTask = id => completeModalRef.current.open(id)

    const handleConfirmJobType = () => refresh()

    const handleFilter = (e) => {

        let name = e.target.name
        let val = e.target.value

        setStages(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                [name]: val
            },
            refresh: new Date().getTime()
        }))
    }

    const handleEnterComment = (e, id) => {
        let targetID = e.target.id
        let width = window.innerWidth - e.clientX
        let cordX
        if (width >= 320) {
            cordX = e.clientX;
        } else {
            cordX = e.clientX - 300;
        }
        let cordY = e.clientY;
        document.getElementById(`comment-${id}`).style.left = cordX + 'px';
        document.getElementById(`comment-${id}`).style.top = cordY + 'px';
        setIsShown(prev => ({
            ...prev,
            id: id,
            shown: true
        }))
    }

    const handleLeaveComment = () => {
        setIsShown(prev => ({
            ...prev,
            id: null,
            shown: false
        }))
    }

    return (
        <>
            <QuestionModalFinish
                ref={questionModalFinishRef}
                mainMessage='Сигурни ли сте, че искате да завършите тази задача?'
                seoncaryMessage='След завършване задачата ще бъде със статус завършена.'
                handleCallback={handleConfirmFinishTask}
            />
            <CompleteModal
                ref={completeModalRef}
                handleUpdateTask={() => props.handleRefreshData()}
                handleRefreshData={refresh}
            />
            <ReportModal
                ref={reportModal}
                handleReportSuccess={handleReportSuccess}
            />
            <CreateEdit
                ref={createEditModal}
                handleRefreshData={refresh}
            />
            <QuestionCleanModal
                mainMessage="Потвърждавате ли, че на адреса е чисто?"
                secondaryMessage="След потвърждение ще видите направените от Вас промени."
                ref={questionCleanModalRef}
                handleCallback={handleItsCleanCallback}
            />
            <QuestionModal
                mainMessage="Потвърждавате ли започването на адреса?"
                secondaryMessage="След потвърждение адреса ще бъде маркиран като активен."
                ref={questionModalRef}
                handleCallback={handleCallback}
            />
            <QuestionRemoveReportModal
                mainMessage="Потвърждавате ли, че няма проблем за започването на СМР?"
                secondaryMessage="След потвърждение ще може да маркирате СМР като започнат."
                ref={questionRemoveReportModalRef}
                handleCallback={handleRemoveReportCallback}
            />
            <QuestionFinishSidewalkModal
                mainMessage="Потвърждавате ли, дейностите по двата тротоара са приключили?"
                secondaryMessage="След потвърждение ще може да видите промените в таблицата."
                ref={questionFinishSidewalkModalRef}
                handleCallback={handleFinishSidewalkCallback}
            />
            <QuestionFinishRoad
                mainMessage="Потвърждавате ли, дейностите по пътя са приключили?"
                secondaryMessage="След потвърждение ще може да видите промените в таблицата."
                ref={questionFinishRoadModalRef}
                handleCallback={handleConfirmFinishRoad}
            />
            <QuestionJobType
                mainMessage="Сигурни ли сте, че искате да смените вида работа?"
                secondaryMessage="След потвърждение прогреса на автоматично генерираните задачите ще бъде занулен."
                ref={questionJobTypeModalRef}
                handleCallback={handleConfirmJobType}
            />
            <AttentionCleanModal
                mainMessage="Адресът е отбелязан като чист."
                secondaryMessage="Ако искате да върнете действието, моля свържете се с администратор."
                ref={attentionCleanModalRef}
            />
            <AttentionModal
                mainMessage="Адресът маркиран като започнат!"
                secondaryMessage="Ако искате да върнете адреса като незапочнат, моля свържете се с администратор."
                ref={attentionModalRef}
            />
            <AttentionSidewalkFixedModal
                mainMessage="Дейностите по двата тротоара са отбелязани като завършени!"
                secondaryMessage="Ако искате да върнете дейностите като незапочнати, моля свържете се с администратор."
                ref={attentionSidewalkFixedModalRef}
            />
            <AttentionRoadFixedModal
                mainMessage="Дейностите пътя са отбелязани като завършени!"
                secondaryMessage="Ако искате да върнете дейностите като незапочнати, моля свържете се с администратор."
                ref={attentionRoadFixedModalRef}
            />
            <>

                <div className='row stageMargin'>
                    <div className="col">
                        <label htmlFor="number">
                            Търсене по номер
                            </label>
                        <input
                            type="search"
                            placeholder="Въведете име"
                            onChange={handleFilter}
                            name="number"
                            id="number"
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="name">
                            Търсене по текуща задача
                                </label>
                        <input
                            type="search"
                            placeholder="Въведете име"
                            onChange={handleFilter}
                            name="name"
                            id="name"
                        />
                    </div>

                    <div className="col">
                        <label htmlFor="job_id">
                            Вид работа
                                </label>
                        <select
                            id="job_id"
                            name="job_id"
                            onChange={handleFilter}
                        >
                            <option value="">Всички</option>
                            {stages.data.job_types && stages.data.job_types.map(job =>
                                <option key={job.id} value={job.id}>
                                    {job.name}
                                </option>
                            )}
                        </select>
                    </div>

                    <div className="col">
                        <label htmlFor="note">
                            Проблем
                                </label>
                        <select
                            id="note"
                            name="note"
                            onChange={handleFilter}
                        >
                            <option value="">Всички</option>
                            <option value="1">С проблем</option>
                            <option value="2">Без проблем</option>
                        </select>
                    </div>

                    <div className="col">
                        <label htmlFor="note">
                            Сортиране по
                                </label>
                        <select
                            id="sort"
                            name="sort"
                            onChange={handleFilter}
                        >
                            <option value="0"></option>
                            <option value="1">Номер</option>
                            <option value="2">Вид</option>
                            <option value="3">Текуща задача</option>
                        </select>
                    </div>

                </div>
                {stages.loading ?
                    <img src={loader} alt="loading" />
                    :
                    stages.data.items.length == 0 ?
                        <div className="no-results">
                            <img src={notFoundIcon} alt="Няма резултати" />
                            Няма създадени адреси.
                        <br />
                            <br />
                            <button
                                className="btn-extra-add"
                                onClick={handleCreateStage}
                                style={{ paddingRight: inCity ? '20px' : '0' }}
                            >
                                {inCity ? 'Адрес' : 'Междушахтие'}
                            </button>
                        </div>
                        :
                        <div className="table-wrapper">
                            <div className="stages wrapper addresses">
                                <div className="aside">
                                    <div className="head">
                                        {inCity ? 'Адрес' : 'Междушахтие'}
                                    </div>
                                    <ul>
                                        {stages.data.items.map((stage, i) =>
                                            <>
                                                <li key={stage.id} onMouseOver={(e) => handleEnterComment(e, stage.id)} onMouseOut={handleLeaveComment}>
                                                    <i style={{ backgroundColor: stage.job_type.color }}>
                                                        {stage.number ? stage.number : i + 1}
                                                    </i>
                                                    <span onClick={e => handleShowBrigade(stage.id, e)}>
                                                        {stage.name ? stage.name : 'от' + ' ' + stage.from + '  ' + 'до' + ' ' + stage.to}
                                                    </span>
                                                </li>
                                                <div id={`comment-` + stage.id} style={isShown.id == stage.id && isShown.shown && stage.comment ? { opacity: '1' } : {}} className="comment">
                                                    <h3>
                                                        Koментар
                                                </h3>
                                                    <p>{stage.comment && stage.comment}</p>
                                                </div>
                                            </>
                                        )}
                                        <li>
                                            <button
                                                className="btn-extra-add"
                                                onClick={handleCreateStage}
                                                style={{ paddingRight: inCity ? '20px' : '0' }}
                                            >
                                                {inCity ? 'Адрес' : 'Междушахтие'}
                                            </button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="right" role="table">
                                    <ul className="head">
                                        <li>
                                            Вид
                                </li>
                                        <li className="small-col">
                                            СМР
                                </li>
                                        <li>
                                            Текуща задача
                                </li>
                                        <li className="small-col">
                                            Чисто
                                </li>

                                        {projectContext.data.have_road ? <li>
                                            Път
                                </li>
                                            :
                                            ''}
                                        {projectContext.data.have_sidewalk ? <li>
                                            Тротоар 1
                                </li>
                                            :
                                            ''}
                                        {projectContext.data.have_sidewalk ? <li>
                                            Тротоар 2
                                </li>
                                            :
                                            ''}
                                    </ul>
                                    <ul className="main-stages">
                                        {stages.data.items.map(stage =>
                                            <Stage
                                                key={stage.id}
                                                data={stage}
                                                roads={stages.data.roads}
                                                sideWalks={stages.data.sidewalks}
                                                jobTypes={stages.data.job_types}
                                                fromYear={stages.fromYear}
                                                fromMonth={stages.fromMonth}
                                                handleEdit={(id, col) => createEditModal.current.edit(id, col)}
                                                handleShowBrigade={handleShowBrigade}
                                                handleStageProcess={handleStageProcess}
                                                handleItsClean={handleItsClean}
                                                handleReport={handleReport}
                                                handleEditReport={handleEditReport}
                                                handleRemoveReport={handleRemoveReport}
                                                handleRealtimeChange={handleRealtimeChange}
                                                handleFinishSidewalk={handleFinishSidewalk}
                                                handleFinishRoad={handleFinishRoad}
                                                handleFinishTask={handleFinishTask}
                                                handleRefreshData={refresh}
                                                project={projectContext.data}
                                            />
                                        )}
                                    </ul>

                                </div>
                            </div>
                        </div>
                }
            </>
        </>
    )
}

export default Table
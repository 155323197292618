import React, { useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//components
import Storage from './Storage'

//modals
import RemoveModal from '../../../../modals/Remove'
import AttentionModal from '../../../../modals/Attention'

function Table(props) {
    const materials = props.materials
    const isForeman = props.isForeman
    //refs
    const removeModalRef = useRef(null)
    const attentionModalRef = useRef(null)
    //end refs

    const handleRemoveMachine = id => {
        if (props.count == 1) attentionModalRef.current.open()
        else removeModalRef.current.open(id)
    }
    const handleRemoveCallback = id => {
        Api.get(`brigades/removemachine?id=${id}`)
            .then(() => props.removeMachine(id))
    }

    return (
        <>
            <RemoveModal
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                mainMessage={`Сигурни ли сте, че искате да премахнете тази машина от бригадата?`}
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
            />
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Не може да премахнете последната машина от бригадата"
                secondaryMessage="Може да изтриете бригадата през бутона за редакция."
            />
            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>Име</th>
                            <th>Характ.</th>
                            <th>Налично кол.</th>
                            <th>Склад</th>
                        </tr>
                    </thead>
                    <tbody>
                        {materials.data.map(material =>
                            <Storage
                                material={material}
                            />
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Table
import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'

//images
import calendarIcon from '../../../assets/images/app/icons/calendar.png'
import deadlineIcon from '../../../assets/images/app/icons/deadline.png'
import clientIcon from '../../../assets/images/app/icons/client.png'
import loader from '../../../assets/images/app/loader.svg'
import notFoundIcon from '../../../assets/images/pages/icons/not-found.png'
import editIcon from '../../../assets/images/pages/icons/edit.png'

//modals
import EditModal from '../modals/Edit'

//contexts
import { useAuthContext } from '../../../_contexts/AuthContext'

function List(props) {
    const history = useHistory()
    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    //refs
    const editModaRef = useRef(null)
    //end refs

    const handleEdit = id => editModaRef.current.open(id)

    const handleViewProject = (id, e, addressType) => {
        if (e.target.classList[0] == 'edit-project') return
        history.push(`projects/view/${id}/${addressType}`)
    }

    return (
        <>
            <EditModal
                ref={editModaRef}
                handleRefreshData={props.handleRefreshData}
            />
            <ul className="projects-list">
                {props.loading ?
                    <img src={loader} alt="loading" />
                    :
                    props.projects.length == 0 ?
                        <div className="no-results">
                            <img src={notFoundIcon} alt="Няма резултати" />
                            Не бяха намерени резултати <br />
                            от Вашето търсене.
                        </div>
                        :
                        props.projects.map(project =>
                            <li key={project.id} onClick={e => handleViewProject(project.id, e, project.address_type)}>
                                <div className="period">
                                    <span>
                                        <img src={calendarIcon} alt="Начална дата" />
                                        <time>
                                            {project.from_dmy}
                                        </time>
                                    </span>
                                    {isAdmin && <img
                                        src={editIcon}
                                        alt="Редакция"
                                        className="edit-project"
                                        onClick={() => handleEdit(project.id)}
                                    />}
                                </div>
                                <h2>
                                    {project.type ? <img src={project.type.image} alt={project.type.name} /> : ''}
                                    <span>
                                        {project.name}
                                        <br />
                                        <i>
                                            {project.type ?  project.type.name : ''}
                                        </i>
                                    </span>
                                </h2>
                                <p className="client">
                                    <img src={clientIcon} alt="Клиент" />
                                    <span>
                                        Клиент:
                                    </span>
                                    {project.client.name}
                                </p>
                                <div className="period">
                                    <span className="deadline">
                                        <img src={deadlineIcon} alt="Краен срок" />
                                        <time>
                                            {project.to_dmy}
                                        </time>
                                    </span>
                                </div>
                                <div className="bar">
                                    <div className="progress" style={{ width: project.progress + '%' }}>
                                        <p>
                                            {project.progress}%
                                        </p>
                                    </div>
                                </div>
                            </li>
                        )}
            </ul>
        </>
    )
}
export default List
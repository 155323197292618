import React, { useRef } from 'react'

//modals
import SelectMachines from '../SelectMachines'
import AttentionModal from '../Attention'

//images
import deleteIcon from '../../../assets/images/modals/icons/delete.png'

function SelectMachinesField(props) {

    // refs
    const selectMachinesRef = useRef(null)
    const attentionModalRef = useRef(null)

    const handleOpenModal = e => {
        e.preventDefault()

        if (props.brigade_id || props.isBrigade) {
            selectMachinesRef.current.open(props.brigade_id)
        } else if (!props.brigade_id && props.isTask) {
            attentionModalRef.current.open()
        }
    }

    const handleRemove = id => {
        let machines = props.machines.filter(m => Number(m.id) !== Number(id))

        if (typeof props.setMachines === 'function') props.setMachines(machines)
    }

    return (
        <div className="col full-width">
            <AttentionModal
                ref={attentionModalRef}
                mainMessage="Моля, изберете бригада!"
                secondaryMessage="Машините са пряко обвързани с бригадите."
            />
            <h4>
                Избрани машини
            </h4>

            {props.machines.length === 0
                ?
                <p className="left extra-text">Все още нямате добавени машини към текущата бригада. </p>
                :
                <ul className="scroller-list">
                    {props.machines.map(m =>
                        <li key={'machine-' + m.id} className="selected-list">
                            {m.type ? (m.type.name + ' - ') : ''} {m.no}
                            <img
                                src={deleteIcon}
                                alt="Изтриване"
                                onClick={() => handleRemove(m.id)} />
                        </li>
                    )}
                </ul>
            }

            {props.machines.map(u =>
                <input key={'machine-' + u.id} name="machine_id[]" value={u.id} type="hidden" />
            )}

            <button className="btn-extra-add" onClick={handleOpenModal} name="machine_id">
                Добавяне
            </button>

            <SelectMachines
                ref={selectMachinesRef}
                isBrigade={props.isBrigade}
                isTask={props.isTask}
                setMachines={props.setMachines}
                brigade_id={props.brigade_id}
                machines={props.machines}
            />
        </div>
    )
}

export default SelectMachinesField
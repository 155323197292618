import React, { useEffect } from 'react'

import { Switch, Route, useLocation, useHistory } from "react-router-dom"

//contexts
import { useAuthContext } from './_contexts/AuthContext'
import ProjectContext from './_contexts/ProjectContext'
import StorageContext from './_contexts/StorageContext'
import FinanceContext from './_contexts/FinananceContex'

//routes
import ProtectedRoute from './routes/Protected'

//styles
import './assets/css/app.css'
import './assets/css/fields.css'
import './assets/css/buttons.css'
import './assets/css/filters.css'
import './assets/css/modals.css'
import './assets/css/tables.css'

//components
import Header from './components/app/Header'
import Login from './components/login/Index'

import Dashboard from './components/dashboard/Index'

import Projects from './components/projects/Index'
import Project from './components/projects/View'

import Storage from './components/storage/Index'

import Finance from './components/finance/Index'

import Clients from './components/clients/Index'

import Administration from './components/administration/Index'

function App() {
  const location = useLocation()
  const history = useHistory()
  const auth = useAuthContext()

  useEffect(() => {
    if (!auth.isLogged) {
      if (location.pathname !== '/login') history.push('/login')
    }
  }, [])

  return (
    <>
      {auth && auth.isLogged && <Header />}
      <main>
        <Route render={({ location }) => (
          <ProjectContext>
            <StorageContext>
              <FinanceContext>
                <Switch location={location}>
                  <Route path="/login" exact component={Login} />
                  <ProtectedRoute view={true} path="/" exact component={Dashboard} />

                  <ProtectedRoute view={true} path="/projects" exact component={Projects} />
                  <ProtectedRoute view={true} path="/projects/view/:id/:inCity" exact component={Project} />

                  <ProtectedRoute view={true} path="/storage" exact component={Storage} />

                  <ProtectedRoute view={true} path="/finance" exact component={Finance} />

                  <ProtectedRoute view={true} path="/clients" exact component={Clients} />

                  <ProtectedRoute view={true} path="/administration" exact component={Administration} />

                  {/* errors */}
                  <Route path="*" render={() => { return (<>404</>) }} />
                </Switch>
              </FinanceContext>
            </StorageContext>
          </ProjectContext>
        )} />
      </main>
    </>
  )
}

export default App;

import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Api from '../../../../_helpers/Api'

//components
import Filter from './partials/Filter'
import Pagination from '../../../app/Pagination'
import Table from './partials/Table'

//images
import loader from '../../../../assets/images/app/loader.svg'
import notFoundIcon from '../../../../assets/images/pages/icons/not-found.png'

//modals
import CreateEditModal from './modals/CreateEdit'

//contexts
import { useFinanceContext } from '../../../../_contexts/FinananceContex'

let timeout
function Index() {
    const financeContext = useFinanceContext()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const [materials, setMaterials] = useState({
        filter: {
            project_id: '',
            fromdate: '',
            todate: '',
            name: '',
            price: 'price-asc'
        },
        loading: true,
        data: [],
        pages: 1,
        page: searchParams.get('page') || 1,
        refresh: null,
    })

    //refs
    const createEditRef = useRef(null)
    //end refs

    useEffect(() => {
        startLoading()

        let url = `income/getall?project_id=${materials.filter.project_id}`
            + `&fromdate=${materials.filter.fromdate}`
            + `&todate=${materials.filter.todate}`
            + `&name=${materials.filter.name}`
            + `&page=${materials.page}`
            + `&sort=${materials.filter.price}`

        Api.get(url)
            .then(res => {
                setMaterials(materials => ({
                    ...materials,
                    data: res.data.result,
                    pages: res.data.page
                }))
                financeContext.setData({
                    expense: res.data.expense_total,
                    income: res.data.income_total,
                    remaining: res.data.remaining
                })
            })
            .finally(() => stopLoading())
    }, [materials.page, materials.filter, materials.refresh])
    const startLoading = () => {
        setMaterials(materials => ({
            ...materials,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setMaterials(materials => ({
            ...materials,
            loading: false,
        }))
    }

    const handleFilter = (key, val, delay = 0) => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            setMaterials(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val
                }
            }))
        }, delay)
    }

    const handlePage = page => {
        setMaterials(materials => ({
            ...materials,
            page: page.selected + 1
        }))
    }

    //refresh
    const handleRefreshData = () => {
        setMaterials(materials => ({
            ...materials,
            refresh: new Date()
        }))
    }

    const handlePriceFilter = () => {
        setMaterials(materials => ({
            ...materials,
            filter: {
                ...materials.filter,
                price: materials.filter.price == 'price-asc' ? 'price-desc' : 'price-asc'
            }
        }))
    }

    const handleCreate = () => createEditRef.current.open()
    const handleEdit = id => createEditRef.current.open(id)

    return (
        <div className="wrapper task">
            <CreateEditModal
                ref={createEditRef}
                handleRefreshData={handleRefreshData}
            />
            <div className="top row">
                <span>

                </span>
                <button className="btn-add" onClick={handleCreate}>
                    Приход
                </button>
            </div>
            <div className="row">
                <Filter
                    handleFilter={handleFilter}
                />
            </div>

            {materials.loading ?
                <img src={loader} alt="loading" />
                :
                materials.data.length == 0 ?
                    <div className="no-results">
                        <img src={notFoundIcon} alt="Няма резултати" />
                        Не бяха намерени резултати.
                    </div>
                    :
                    <>
                        <Table
                            materials={materials.data}
                            handleEdit={handleEdit}
                            priceFilter={handlePriceFilter}
                            priceFilterApplied={materials.filter.price}
                        />
                        <div className="row">
                            <Pagination
                                page={materials.page}
                                pages={materials.pages}
                                handlePage={handlePage}
                            />
                        </div>
                    </>
            }
        </div>
    )
}

export default Index
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import style from '../../assets/css/dropdownmultiple.css';
// import loader from '../../assets/img/loader.svg'
import loader from '../../assets/images/app/loader.svg'
import Api from '../../_helpers/Api'

let timeout;

function Index(props, ref) {
    const listRef = useRef(null);

    const filter = Boolean(props.filter) || false;
    const filterTimeout = Number(props.timeout) || 300;
    const inputName = props.inputName || 'text-value';
    const inputValue = props.inputValue || '';
    const inputId = props.inputId || '';
    const inputClassName = props.inputClassName || '';
    const inputIdName = props.inputIdName || 'id-value';
    const inputIdValue = props.inputIdValue || '';
    const inputPlaceholder = props.inputPlaceholder || '';
    const inputReadOnly = props.inputReadOnly || false;
    const inputDisabled = props.inputDisabled || false;
    const dropdownData = Array.isArray(props.data) ? props.data : [];
    const dataUrl = props.url || null;
    const dataParams = props.params || {};
    const loadDataOnFocus = props.loadDataOnFocus || false;
    const requiredParams = Array.isArray(props.requiredParams) ? props.requiredParams : [];
    let renderText = props.renderText || '';
    const renderInputText = props.renderInputText || '';
    const filterable = typeof props.filterable !== 'undefined' ? Boolean(props.filterable) : true;
    const multiple = typeof props.multiple !== 'undefined' ? Boolean(props.multiple) : false;
    const selectedValues = Array.isArray(props.selectedValues) ? props.selectedValues : [];

    const initialData = {
        data: dropdownData,
        original: dropdownData,
        showResults: false,
        loading: false,
        dataLoaded: false,
        inputValue: inputValue
    };

    const [data, setData] = useState(initialData);

    const [selected, setSelected] = useState({
        name: inputValue,
        id: inputIdValue
    });

    const [multiSelected, setMultiSelected] = useState({});

    const [focused, setFocused] = useState(0);

    useImperativeHandle(ref, () => ({
        reset: () => {
            resetData();
        }
    }));

    useEffect(() => {
        if (typeof props.inputValue !== 'undefined') {
            setData(prev => ({
                ...prev,
                inputValue: inputValue
            }));
        }
    }, [props.inputValue, props.inputIdValue])

    useEffect(() => {

        if (!filter) {
            if (inputIdValue) {


                let exists = data.original.filter(e => {
                    return e.id == inputIdValue;
                })[0];

                if (!exists) {

                    loadData(inputValue, inputIdValue, rows => {
                        let active = rows.filter((e, i) => {
                            if (e.id == inputIdValue) {
                                setFocused(i);
                                return true;
                            }

                            return false;
                        })[0];

                        if (active) {
                            let el = listRef.current.querySelector("li[data-id='" + active.id + "']");

                            if (el) {
                                el.click();
                            }
                        }
                    });
                } else {
                    let el = listRef.current.querySelector("li[data-id='" + exists.id + "']");

                    if (el) {
                        el.click();
                    }
                }

            } else {
                if (typeof props.inputValue !== 'undefined') {
                    setSelected(prev => ({
                        ...prev,
                        name: inputValue,
                        id: inputIdValue
                    }));


                    setData(prev => ({
                        ...prev,
                        inputValue: inputValue
                    }));

                }
            }
        }

    }, [props.inputValue, props.inputIdValue, props.params, props.url]);

    useEffect(() => {
        if (filter) {
            let exists = data.original.filter(e => {
                return e.id == inputIdValue;
            })[0];

            if (exists) {

                let el = listRef.current.querySelector("li[data-id='" + exists.id + "']");

                if (el) {
                    el.click();
                }

                // setSelected(prev => ({
                //     ...prev,
                //     name: exists.name
                // }))
            } else {
                if (props.inputValue) {
                    setSelected(prev => ({
                        ...prev,
                        name: props.inputValue
                    }));
                }
            }
        }
    }, [props.inputValue, props.inputIdValue, data.original]);

    useEffect(() => {
        if (Array.isArray(props.data)) {
            setData(prev => ({
                ...prev,
                data: props.data,
                original: props.data
            }))
        }
    }, [props.data]);

    // за multiple
    useEffect(() => {
        let ids = {};

        selectedValues.map(obj => {
            ids[String(obj.id)] = obj;
        });

        setMultiSelected(ids);

    }, [props.selectedValues]);

    // useEffect(() => {
    //     if (typeof props.params !== 'undefined') {
    //         loadData();
    //     }
    // }, [props.params]);

    useEffect(() => {
        if (multiple && selectedValues.length === 0) {
            setData(prev => ({
                ...prev,
                data: [],
                original: []
            }))
        }
    }, [props.url]);

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    const loadData = (name, id = null, callback = null) => {

        if (typeof name === 'undefined') {
            name = '';
        }


        if (requiredParams.length) {
            let params = Object.keys(dataParams);

            let missingParams = [];


            let hasMissingParams = requiredParams.filter(p => {

                if (params.includes(p) && !dataParams[p]) {
                    missingParams.push(p);

                    return true;
                }

                return false;
            }).length;


            if (hasMissingParams) {
                console.warn(`[Dropdown]: Missing params: ${missingParams.join(', ')}`);
                return false;
            }
        }

        Api.get(dataUrl, {
            params: {
                id: id,
                name: name,
                ...dataParams
            }
        }).then(res => {

            let items = typeof res.data.items !== 'undefined' ? res.data.items : res.data;

            items = items.map(i => {

                if (!i.id) {
                    i.id = Math.random().toString().substr(3);
                }

                return i;
            });

            setData(prev => ({
                ...prev,
                data: items,
                original: items,
                loading: false,
                dataLoaded: true
            }));

            if (selected.id) {
                items.map((e, i) => {
                    if (e.id == selected.id) {
                        setFocused(i);
                    }
                });
            }

            if (typeof callback === 'function') {
                return callback(items);
            }

        }).catch(err => {
            setData(prev => ({
                ...prev,
                data: [],
                original: [],
                loading: false,
                showResults: false
            }));
        });

    }

    const handleClickAway = () => {
        hideResults();
    }

    const handleKeyPress = e => {
        e.preventDefault();
        e.stopPropagation();

        let val = e.target.value;

        showResults();

        let selectedData = {
            name: val,
            id: ''
        };

        setSelected(selectedData);

        let action;

        if (e.keyCode == 40) {
            action = 'down';
        } else if (e.keyCode == 38) {
            action = 'up';
        } else if (e.keyCode == 13) {
            action = 'enter';
        }

        if (action) {
            if (action === 'down' || action === 'up') {

                let min = 0;
                let max = data.original.length - 1;

                let position;

                if (action === 'down') {
                    position = focused + 1;
                } else {
                    position = focused - 1;
                }

                if (position < min || position > max) {
                    return;
                }

                setFocused(position);

                setTimeout(() => {
                    const selectedFocus = listRef.current.querySelector('.focus');

                    if (selectedFocus) {
                        const offsetHeight = selectedFocus.offsetHeight;
                        const offsetTop = selectedFocus.offsetTop;

                        const scroll = listRef.current.scrollTop;
                        const height = listRef.current.offsetHeight;

                        if (offsetTop + offsetHeight > scroll + height) {
                            listRef.current.scrollTop = scroll + offsetHeight;
                        } else if (offsetTop < scroll) {
                            listRef.current.scrollTop = scroll - offsetHeight;
                        }
                    }

                }, 50);

            } else if (action === 'enter') {

                // let selectedFocus = data.original[focused];

                const selectedFocus = listRef.current.querySelector('.focus');

                if (selectedFocus) {
                    selectedFocus.click();
                }

                // if (selectedFocus) {
                //     setSelected({
                //         id: selectedFocus.id,
                //         name: selectedFocus.name
                //     })

                //     if (props.onChange && typeof props.onChange === 'function') {
                //         props.onChange(selectedFocus);
                //     }

                //     hideResults();
                // }

            }
        }
    }

    const handleChange = e => {

        if (!filterable) {
            return;
        }

        let val = e.target.value;

        let selectedData = {
            name: val,
            id: ''
        };

        setSelected(selectedData);

        clearTimeout(timeout);

        // просто филтриране на данните
        if (filter) {
            timeout = setTimeout(() => {

                let filtered = data.original.filter(e => {
                    return e.name ? e.name.indexOf(val) > -1 : true;
                });

                setData(prev => ({
                    ...prev,
                    data: filtered,
                    inputValue: val
                }));

                // ако в списъкът има само 1 елемент се избира автоматично
                // if (filtered.length == 1) {
                //     setSelected({
                //         name: filtered[0] ? filtered[0].name : '',
                //         id: filtered[0] ? filtered[0].id : ''
                //     });

                //     hideResults();
                // }


                if (!multiple && props.onChange && typeof props.onChange === 'function') {
                    props.onChange(selectedData);
                }

            }, 50);
        }
        // филтриране чрез заявка към база данни
        else {

            if (!dataUrl) {
                return;
            }


            if (String(val) !== String(data.inputValue)) {

                setData(prev => ({
                    ...prev,
                    loading: true,
                }));

                timeout = setTimeout(() => {
                    loadData(val, null, data => {
                        // ако в списъкът има само 1 елемент се избира автоматично
                        // if (data.length == 1) {
                        //     setSelected({
                        //         name: data[0] ? data[0].name : '',
                        //         id: data[0] ? data[0].id : ''
                        //     });

                        //     hideResults();
                        // }
                    });

                }, filterTimeout);

                if (!multiple && props.onChange && typeof props.onChange === 'function') {
                    props.onChange(selectedData);
                }

            }

            setTimeout(() => {
                setData(prev => ({
                    ...prev,
                    inputValue: val
                }));
            }, 100)
        }


    }

    const handleResult = e => {
        e.preventDefault();

        let target = e.currentTarget;

        // let val = e.target.getAttribute('data-name');

        // let val = e.target.innerHTML;
        let span = target.querySelector('.display-name');

        // if (span) val = span.innerHTML
        let val = span.innerHTML;

        let id = target.getAttribute('data-id');


        let shouldCallCallback = false;

        let selectedData = {
            name: val,
            id: id
        };

        // пълна информация за избрания елемент от базата данни
        let selectedExtended = data.data.filter((e, i) => {
            if (String(e.id) === String(id)) {
                setFocused(i);
                return true;
            }
            return false;
        })[0];

        let selectedList;

        if (multiple) {
            selectedList = Object.assign({}, multiSelected);

            id = String(id);

            if (selectedList[id]) {
                delete selectedList[id];
            } else {
                if (selectedExtended) {
                    selectedList[id] = selectedExtended
                } else {
                    selectedList[id] = selectedData;
                }

            }

            shouldCallCallback = true;
            setMultiSelected(selectedList);

        } else {
            shouldCallCallback = String(selected.id) !== String(id);
            setSelected(selectedData);
        }

        setData(prev => ({
            ...prev,
            inputValue: val
        }));

        if (shouldCallCallback && props.onChange && typeof props.onChange === 'function') {
            if (multiple) {
                props.onChange(Object.values(selectedList));
            } else {
                if (selectedExtended) {
                    props.onChange(selectedExtended)
                } else {
                    props.onChange(selectedData);
                }
            }
        }

        if (!multiple) {
            hideResults();
        }

    }

    const handleBlur = e => {
        if (multiple) {
            return;
        }

        if (e.nativeEvent.explicitOriginalTarget &&
            e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
            return;
        }

        setTimeout(() => {
            hideResults();
        }, 200);
    }

    const showResults = () => {

        // първоначално зареждане на данните след фокус на полето
        // при последващ фокус не се изпълнява
        if (!data.dataLoaded || loadDataOnFocus) {
            if (dataUrl) {
                loadData(selected.name, selected.id);
            }
        }

        setData(prev => ({
            ...prev,
            showResults: true
        }));
    }

    const hideResults = () => {
        setData(prev => ({
            ...prev,
            showResults: false
        }));
    }

    const resetData = () => {
        setSelected({
            name: '',
            id: ''
        });

        setData(initialData);
    }

    const getDisplayName = el => {

        if (typeof renderInputText === 'function') {
            return renderInputText(el);
        }

        if (typeof renderText === 'function') {
            return renderText(el);
        }

        return el.name ? el.name : '';
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="dropdown-input" onKeyUp={handleKeyPress}>
                <input autoComplete="off" role="presentation"
                    type="text"
                    name={inputName}
                    id={inputId}
                    className={inputClassName}
                    value={selected.name}
                    placeholder={inputPlaceholder}
                    readOnly={inputReadOnly}
                    disabled={inputDisabled}
                    onFocus={showResults}
                    onBlur={handleBlur}
                    onChange={handleChange}
                // onKeyUp={handleChange}
                />
                <ul ref={listRef} className={['dropdown', data.showResults && (data.data.length || data.loading) ? 'show' : ''].join(' ')}>
                    {
                        data.loading
                            ?
                            <img src={loader} alt="loading" />
                            :
                            data.data.map((e, i) =>
                                <React.Fragment key={e.id}>
                                    <li
                                        data-id={e.id}
                                        data-name={e.name}
                                        onClick={e => handleResult(e)}
                                        className={[e.id && (String(selected.id) === String(e.id) || multiSelected[e.id]) ? 'active' : '', focused == i ? 'focus' : '', multiple ? 'multiple' : ''].join(' ')}
                                    >
                                        {multiple
                                            ?
                                            <>
                                                <svg className="selected" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                                                </svg>
                                                <svg className="not-selected" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                                    <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
                                                </svg>
                                            </>
                                            :
                                            ''
                                        }

                                        {typeof renderText === 'function' ? renderText(e) : e.name}

                                        <div className="display-name" hidden>{getDisplayName(e)}</div>
                                    </li>
                                </React.Fragment>
                            )
                    }
                </ul>

                {multiple
                    ?
                    <div className="multiple-selected-label">
                        {Object.values(multiSelected).length} избрани
                    </div>
                    :
                    ''
                }

                {multiple
                    ?
                    Object.keys(multiSelected).map(id => (
                        <input
                            key={'input-id-value-' + id}
                            type="hidden"
                            name={`${inputIdName}[]`}
                            value={id}
                        // readOnly={inputDisabled} 
                        />
                    ))
                    :
                    <input
                        type="hidden"
                        name={inputIdName}
                        value={selected.id}
                    // readOnly={inputDisabled} 
                    />
                }

            </div>
        </ClickAwayListener>
    )

}

// export default forwardRef(Index);

export default React.memo(React.forwardRef(Index), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
});
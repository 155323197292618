import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

function Tabs(props) {
    const history = useHistory()

    const [tabs, setTabs] = useState({
        data: [
            {
                id: 1,
                name: 'Бригадири',
            },
            {
                id: 2,
                name: 'Работници',
            },
            {
                id: 3,
                name: 'Машини',
            },
            {
                id: 4,
                name: 'Видове машини',
            },
            {
                id: 5,
                name: 'Материали',
            },
            {
                id: 6,
                name: 'Доставчици',
            },
            {
                id: 7,
                name: 'Складове',
            },
            {
                id: 8,
                name: 'Приходи',
            },
            {
                id: 9,
                name: 'Разходи',
            },
            {
                id: 10,
                name: 'Проекти',
            },
            {
                id: 11,
                name: 'Видове работа',
            },
            {
                id: 12,
                name: 'Задачи',
            },
        ]
    })

    const handleActiveTab = e => {
        let tabId = e.target.getAttribute('data-tab')

        history.push(`?tab=${tabId}`)

        props.handleCurrentTab(tabId)
    }

    return (
        <ul className="tabs wrapper">
            {tabs.data.map(tab =>
                <li
                    data-tab={tab.id}
                    className={tab.id == props.currentTab ? 'active' : ''}
                    onClick={handleActiveTab}
                >
                    {tab.name}
                </li>
            )}
        </ul>
    )
}

export default Tabs
import axios from 'axios'

const Api = axios.create({
    // baseURL: 'http://192.168.0.24/Prespa-backend/public/api/'
    // baseURL: 'http://192.168.0.5/prespa-backend/public/api/'
    // baseURL: 'http://localhost/Prespa-backend/public/api/'
    baseURL: 'https://prespaserv.danielstandard.com/api/'
})

Api.interceptors.request.use(config => {
    const auth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null
    const token = auth && auth.user && auth.user.access_token

    config.params = config.params || {}

    config.headers['Authorization'] = `Bearer ${token}`

    return config
})

export default Api
import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import Api from '../../../../_helpers/Api'

//components
import Filter from './partials/Filter'
import Pagination from '../../../app/Pagination'
import Table from './partials/Table'

//images
import loader from '../../../../assets/images/app/loader.svg'
import notFoundIcon from '../../../../assets/images/pages/icons/not-found.png'

//modals
import CreateEditModal from './modals/CreateEdit'
import TransferModal from './modals/Transfer'

let timeout
function Index(props) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    const [materials, setMaterials] = useState({
        filter: {
            storage: '',
            data_type: '1',
            fromdate: '',
            todate: '',
            name: '',
            quantity: 'quantity-asc'
        },
        loading: true,
        data: [],
        pages: 1,
        page: searchParams.get('page') || 1,
        refresh: null,
    })

    //refs
    const createEditRef = useRef(null)
    const transferModalRef = useRef(null)
    //end refs

    useEffect(() => {
        startLoading()

        const mainUrl = materials.filter.data_type == 1 ? 'storage/get/quantity' : 'storage/get'

        const url = `${mainUrl}?storage_id=${materials.filter.storage}`
            + `&fromdate=${materials.filter.fromdate}`
            + `&todate=${materials.filter.todate}`
            + `&data=${materials.filter.name}`
            + `&page=${materials.page}`
            + `&sort=${materials.filter.quantity}`

        Api.get(url)
            .then(res => {
                setMaterials(materials => ({
                    ...materials,
                    data: res.data.result,
                    pages: res.data.page
                }))
            })
            .finally(() => stopLoading())
    }, [materials.page, materials.filter, materials.refresh])
    const startLoading = () => {
        setMaterials(materials => ({
            ...materials,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setMaterials(materials => ({
            ...materials,
            loading: false,
        }))
    }

    const handleFilter = (key, val, delay = 0) => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            setMaterials(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val
                }
            }))
        }, delay)
    }

    const handlePage = page => {
        setMaterials(materials => ({
            ...materials,
            page: page.selected + 1
        }))
    }

    //refresh
    const handleRefreshData = () => {
        setMaterials(materials => ({
            ...materials,
            refresh: new Date()
        }))
        props.refreshData()
    }

    const handleQuantityFilter = () => {
        setMaterials(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                quantity: materials.filter.quantity == 'quantity-asc' ? 'quantity-desc' : 'quantity-asc'
            }
        }))
    }

    const handleCreate = () => createEditRef.current.open()
    const handleEdit = id => createEditRef.current.open(id)
    const handleTransfer = id => transferModalRef.current.open(id)

    return (
        <div className="wrapper task">
            <CreateEditModal
                ref={createEditRef}
                handleRefreshData={handleRefreshData}
            />
            <TransferModal
                ref={transferModalRef}
                handleRefreshData={handleRefreshData}
            />
            <div className="top row">
                <span>

                </span>
                <button className="btn-add" onClick={handleCreate}>
                    Материал
                </button>
            </div>
            <div className="row">
                <Filter
                    handleFilter={handleFilter}
                />
            </div>

            {materials.loading ?
                <img src={loader} alt="loading" />
                :
                materials.data.length == 0 ?
                    <div className="no-results">
                        <img src={notFoundIcon} alt="Няма резултати" />
                        Не бяха намерени резултати.
                    </div>
                    :
                    <>
                        <Table
                            materials={materials.data}
                            handleEdit={handleEdit}
                            handleTransfer={handleTransfer}
                            quantityFilter={handleQuantityFilter}
                            dataType={materials.filter.data_type}
                            quantityFilterApplied={materials.filter.quantity}
                        />
                        <div className="row">
                            <Pagination
                                page={materials.page}
                                pages={materials.pages}
                                handlePage={handlePage}
                            />
                        </div>
                    </>
            }
        </div>
    )
}

export default Index
import React from 'react'

//images
import deleteIcon from '../../../../../assets/images/modals/icons/delete.png'

function Machine(props) {
    const material = props.material
    const isForeman = props.isForeman
    return (
        <tr key={material.id}>
            <td>
                {material.material.name}
            </td>
            <td>
                {material.material.specification}
            </td>
            <td>
                {material.quantity}
            </td>
            <td>
                {material.storage.name}
            </td>
        </tr>
    )
}

export default Machine
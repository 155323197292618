import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

//images
import filterArrowIcon from '../../../../../assets/images/elements/icons/filter-arrow.png'
import Api from '../../../../../_helpers/Api'

function Filter(props) {
    const history = useHistory()

    const [stages, setStages] = useState([])

    useEffect(() => {
        Api.get('stages/getstage?id=' + props.projectId)
            .then(res => setStages(res.data.items))

    }, [])

    const handleFilter = e => {
        let name = e.target.name
        let val = e.target.value

        props.handleFilter(name, val)

        if (name == 'stage_id') history.push(`?tab=2&stage_id=${val}`)
    }

    const handleSortByDate = () => props.handleSortByDate()

    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            <div className="row">
                <div className="col">
                    <label htmlFor="stage">
                        Адрес
                    </label>
                    <select
                        id="stage"
                        name="stage_id"
                        value={props.filter.stage_id}
                        onChange={handleFilter}
                    >
                        <option value="" selected>Всички</option>
                        {stages.map(s =>
                            <option
                                key={'s-' + s.id}
                                value={s.id}
                            >
                                {s.name ? s.name : 'oт '+ s.from + ' ' + 'до' + s.to}
                            </option>
                        )}
                    </select>
                </div>

                <div className="col">
                    <label htmlFor="status">
                        Сатус
                    </label>
                    <select
                        id="status"
                        name="status"
                        value={props.filter.status}
                        onChange={handleFilter}
                    >
                        <option value="" selected>Всички</option>
                        <option value="1">Завършени</option>
                        <option value="0">Незавършени</option>
                        <option value="2">Текущи</option>
                    </select>
                </div>
            </div>

            <div className="sort" onClick={handleSortByDate}>
                <p>
                    Сортиране по:
                </p>
                Дата на създаване
                <img src={filterArrowIcon} alt="filter" className={`filter-arrow ${props.filterByDate == 'date_asc' ? 'rotate' : ''}`} />
            </div>
        </form>
    )
}

export default Filter
import React, { createContext, useContext, useState, useMemo, useEffect } from "react"

export const FinanceContext = createContext({})

const Finance = props => {
    const [storage, setStorage] = useState({
        data: {},
        projects: [],
        expenseTypes: [],
        incomeTypes: [],
        paymentTypes: [],
        paid: 0,
        unpaid: 0
    })

    // useEffect(() => {
    //     console.log(storage.paid)
    //     console.log(storage.unpaid)
    // }, [storage.paid, storage.unpaid])

    const setData = data => {
        setStorage(storage => ({
            ...storage,
            data: data
        }))
    }

    const setProjects = data => {
        setStorage(storage => ({
            ...storage,
            projects: data
        }))
    }

    const setExpense = data => {
        setStorage(storage => ({
            ...storage,
            expenseTypes: data.result
        }))
    }


    const setIncome = data => {
        setStorage(storage => ({
            ...storage,
            incomeTypes: data.result
        }))
    }

    const setPaymentTypes = data => {
        setStorage(storage => ({
            ...storage,
            paymentTypes: data
        }))
    }

    const setPaid = data => {
        setStorage(storage => ({
            ...storage,
            paid: data
        }))
    }

    const setUnPaid = data => {
        setStorage(storage => ({
            ...storage,
            unpaid: data
        }))
    }

    const storageData = useMemo(() => ({
        ...storage,
        setData,
        setProjects,
        setExpense,
        setIncome,
        setPaymentTypes,
        setPaid,
        setUnPaid
    }), [storage])

    return <FinanceContext.Provider value={storageData} {...props} />
}

export const useFinanceContext = () => useContext(FinanceContext)

export default Finance
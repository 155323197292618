import React, { useImperativeHandle, forwardRef, useState } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'

function View(props, ref) {
    const [view, setView] = useState({
        overlay: false,
        modal: false,
        loading: true,
        data: []
    })

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        setView(prev => ({
            ...prev,
            overlay: true,
        }))

        Api.get(`projects/stage-items?id=${id}`)
            .then(res => {
                setView(prev => ({
                    ...prev,
                    data: res.data,
                    loading: false
                }))
            })

        setTimeout(() => {
            setView(prev => ({
                ...prev,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = () => {
        setView(prev => ({
            ...prev,
            modal: false,
        }))
        setTimeout(() => {
            setView(prev => ({
                ...prev,
                overlay: false,
            }))
        }, 100)
    }

    return (
        <div className={`${view.overlay ? 'visible' : ''} overlay`}>
            <div className={`${view.modal ? 'show' : ''} box box-small`}>
                <h3>
                    Вложени материали в проект
                </h3>
                {view.loading
                    ? <img style={{ width: '50px' }} src={loader} alt="loading" /> :
                    <>
                        <div className='scroller-b'>
                            <table className="statistic-table">
                                <tbody>
                                    {
                                        view.data.result && view.data.result.map(stage =>
                                            <>
                                                <tr>
                                                    <td>
                                                        Към Адрес/Meждушахтие:
                                                </td>
                                                    <td>
                                                        {stage.stage.name ? stage.stage.name : 'от: ' + stage.stage.from + ' до ' + stage.stage.to}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Материал:
                                                </td>
                                                    <td>
                                                        {stage.materials.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Количество:
                                                </td>
                                                    <td>
                                                        {stage.quantity}
                                                    </td>
                                                </tr>
                                                <br />
                                            </>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ padding: '0px' }}>
                                        <b>
                                            Към други адреси/междушахтия:
                                        </b>
                                    </td>
                                    <td>
                                        <b>
                                            {view.data.remaining}
                                        </b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="buttons">
                            <button onClick={handleClose}>
                                Назад
                     </button>
                        </div>
                    </>}
            </div>
        </div>

    )
}

export default forwardRef(View)
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import ClickAwayListener from 'react-click-away-listener'
import style from '../../assets/css/dropdown.css'
import loader from '../../assets/images/app/loader.svg'
import Api from '../../_helpers/Api'

let timeout

function Index(props, ref) {
    const listRef = useRef(null)

    const filter = Boolean(props.filter) || false
    const filterTimeout = Number(props.timeout) || 300
    const inputName = props.inputName || 'text-value'
    const inputValue = props.multiple ? props.multipleInputValues : props.inputValue || ''
    const inputId = props.inputId || ''
    const inputClassName = props.inputClassName || ''
    const inputIdName = props.inputIdName || 'id-value'
    const inputIdValue = props.multiple ? props.multipleInputIdValues : props.inputIdValue || ''
    const inputPlaceholder = props.inputPlaceholder || ''
    const inputDisabled = props.inputDisabled || false
    const dropdownData = Array.isArray(props.data) ? props.data : []
    const dataUrl = props.url || null
    const dataParams = props.params || {}
    const renderText = props.renderText || ''
    const renderInputText = props.renderInputText || ''
    const multiple = props.multiple
    const loadDataOnFocus = props.loadDataOnFocus || false;


    const initialData = {
        data: dropdownData,
        original: dropdownData,
        showResults: false,
        loading: false,
        dataLoaded: false,
        multipleNames: props.multipleInputValues || [],
        multipleIds: props.multipleInputIdValues || [],
        inputValue: inputValue
    }

    const [data, setData] = useState(initialData)

    const [selected, setSelected] = useState({
        name: inputValue,
        id: inputIdValue
    })

    const [focused, setFocused] = useState(0)

    useImperativeHandle(ref, () => ({
        reset: () => resetData()
    }))

    useEffect(() => {
        setData(prev => ({
            ...prev,
            inputValue: inputValue
        }))
    }, [props.inputValue, props.inputIdValue])

    useEffect(() => {
        if (!filter) {
            if (inputIdValue && !inputValue) {

                let exists = data.original.filter(e => {
                    return e.id == inputIdValue
                })[0]

                if (!exists) {
                    loadData(inputValue, rows => {
                        let active = rows.filter((e, i) => {
                            if (e.id == inputIdValue) {
                                setFocused(i)
                                return true
                            }

                            return false
                        })[0]

                        if (active) {
                            let el = listRef.current.querySelector("li[data-id='" + active.id + "']")

                            if (el) el.click()
                        }
                    })
                } else {
                    let el = listRef.current.querySelector("li[data-id='" + exists.id + "']")

                    if (el) el.click()
                }

            } else {
                if (typeof inputValue !== 'undefined') {
                    setSelected(prev => ({
                        ...prev,
                        name: inputValue,
                        id: inputIdValue
                    }))
                }
            }
        }

    }, [props.inputValue, props.inputIdValue])

    useEffect(() => {
        if (filter) {
            let exists = data.original.filter(e => {
                return e.id == inputIdValue
            })[0]

            if (exists) {
                let el = listRef.current.querySelector("li[data-id='" + exists.id + "']")

                if (el) el.click()
            }
        }
    }, [props.inputIdValue, data.original])

    useEffect(() => {
        if (Array.isArray(props.data)) {
            setData(prev => ({
                ...prev,
                data: props.data,
                original: props.data
            }))
        }
    }, [props.data])

    useEffect(() => {
        return () => clearTimeout(timeout)
    }, [])

    const loadData = (name, callback) => {

        if (!dataUrl) return

        if (typeof name === 'undefined') name = ''

        Api.get(dataUrl, {
            params: {
                name: name,
                ...dataParams
            }
        })
            .then(res => {
                setData(prev => ({
                    ...prev,
                    data: res.data,
                    original: res.data,
                    loading: false,
                    dataLoaded: true
                }))

                if (selected.id) {
                    res.data.map((e, i) => {
                        if (e.id == selected.id) setFocused(i)
                    })
                }

                if (typeof callback === 'function') {
                    return callback(res.data)
                }

            })
            .catch(() => {
                setData(prev => ({
                    ...prev,
                    data: [],
                    original: [],
                    loading: false,
                    showResults: false
                }))
            })

    }




    const handleClickAway = () => hideResults()

    const handleKeyPress = e => {
        e.preventDefault()
        e.stopPropagation()

        let val = e.target.value

        showResults()

        let selectedData = {
            name: val,
            id: ''
        }
        setSelected(selectedData)

        let action

        if (e.keyCode == 40) action = 'down'
        else if (e.keyCode == 38) action = 'up'
        else if (e.keyCode == 13) action = 'enter'

        if (action) {
            if (action === 'down' || action === 'up') {
                let min = 0
                let max = data.original.length - 1

                let position

                if (action === 'down') {
                    position = focused + 1
                } else {
                    position = focused - 1
                }

                if (position < min || position > max) return

                setFocused(position)

                setTimeout(() => {
                    const selectedFocus = listRef.current.querySelector('.focus')

                    if (selectedFocus) {
                        const offsetHeight = selectedFocus.offsetHeight
                        const offsetTop = selectedFocus.offsetTop

                        const scroll = listRef.current.scrollTop
                        const height = listRef.current.offsetHeight

                        if (offsetTop + offsetHeight > scroll + height) {
                            listRef.current.scrollTop = scroll + offsetHeight
                        } else if (offsetTop < scroll) {
                            listRef.current.scrollTop = scroll - offsetHeight
                        }
                    }

                }, 50)

            } else if (action === 'enter') {
                const selectedFocus = listRef.current.querySelector('.focus')

                if (selectedFocus) selectedFocus.click()
            }
        }
    }

    const handleChange = e => {
        let val = e.target.value

        let selectedData = {
            name: val,
            id: ''
        }

        setSelected(selectedData)

        clearTimeout(timeout)

        // просто филтриране на данните
        if (filter) {
            timeout = setTimeout(() => {

                let filtered = data.original.filter(e => {
                    return e.name ? e.name.toLowerCase().indexOf(val.toLowerCase()) > -1 : true
                })

                setData(prev => ({
                    ...prev,
                    data: filtered,
                    inputValue: val
                }))

                if (props.onChange && typeof props.onChange === 'function') props.onChange(selectedData)

            }, 50)
        }
        // филтриране чрез заявка към база данни
        else {
            if (!dataUrl) return

            if (val != data.inputValue) {
                setData(prev => ({
                    ...prev,
                    loading: true,
                }))

                timeout = setTimeout(() => {
                    loadData(val, data => {

                    })

                }, filterTimeout)

                if (props.onChange && typeof props.onChange === 'function') props.onChange(selectedData)
            }

            setTimeout(() => {
                setData(prev => ({
                    ...prev,
                    inputValue: val
                }))
            }, 100)
        }
    }

    const handleResult = e => {
        let span = e.target.querySelector('.display-name')

        let val = span.innerHTML

        let multipleNames = data.multipleNames
        let multipleIds = data.multipleIds

        let id = Number(e.target.getAttribute('data-id'))

        if (multiple) {

            if (multipleNames.includes(val)) {
                multipleNames = multipleNames.filter(array => {
                    return array != val
                })
            } else multipleNames.push(val)

            if (multipleIds.includes(id)) {
                multipleIds = multipleIds.filter(array => {
                    return array != id
                })
            } else multipleIds.push(id)

            val = multipleNames
            id = multipleIds
        }

        let selectedData = {
            name: val,
            id: id
        }

        let shouldCallCallback = selected.id !== id

        setSelected(selectedData)

        setData(prev => ({
            ...prev,
            multipleNames: multipleNames,
            multipleIds: multipleIds,
            inputValue: val
        }))

        // пълна информация за избрания елемент от базата данни
        let selectedExtended = data.data.filter((e, i) => {
            if (e.id == id) {
                setFocused(i)
                return true
            }
            return false
        })[0]

        if (shouldCallCallback && props.onChange && typeof props.onChange === 'function') {
            if (selectedExtended) props.onChange(selectedExtended)
            else props.onChange(selectedData)
        }

        if (!multiple) hideResults()
    }

    const handleBlur = e => {
        if (e.nativeEvent.explicitOriginalTarget &&
            e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
            return
        }

        setTimeout(() => {
            if (!multiple) hideResults()
        }, 200)
    }

    const showResults = () => {

        // първоначално зареждане на данните след фокус на полето
        // при последващ фокус не се изпълнява
        if (!data.dataLoaded || loadDataOnFocus) {
            if (dataUrl) {
                loadData(selected.name, selected.id);
            }
        }

        setData(prev => ({
            ...prev,
            showResults: true
        }))
    }

    const hideResults = () => {
        setData(prev => ({
            ...prev,
            showResults: false
        }))
    }

    const resetData = () => {
        setSelected({
            name: '',
            id: ''
        })

        setData(initialData)
    }

    const getDisplayName = el => {
        if (typeof renderInputText === 'function') {
            return renderInputText(el)
        }

        if (typeof renderText === 'function') {
            return renderText(el)
        }

        return el.name ? el.name : ''
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className="dropdown-input" onKeyUp={handleKeyPress}>
                <input autoComplete="off" role="presentation"
                    type="text"
                    name={inputName}
                    id={inputId}
                    className={inputClassName}
                    value={selected.name}
                    placeholder={inputPlaceholder}
                    readOnly={inputDisabled}
                    onFocus={showResults}
                    onBlur={handleBlur}
                    onChange={handleChange}
                // onKeyUp={handleChange}
                />
                <ul ref={listRef} className={['dropdown', data.showResults && (data.data.length || data.loading) ? 'show' : ''].join(' ')}>

                    {
                        data.loading
                            ?
                            <img src={loader} alt="loading" />
                            :
                            data.data.map((e, i) =>
                                <React.Fragment key={e.id}>
                                    <li
                                        data-id={e.id}
                                        data-name={e.name}
                                        onClick={handleResult}
                                        className={[e.id && (Number(selected.id) == Number(e.id) || (data.multipleIds.length && data.multipleIds.includes(Number(e.id)))) ? 'active' : '', focused == i ? 'focus' : ''].join(' ')}
                                    >
                                        {/* <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                            <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
                                        </svg> */}

                                        {typeof renderText === 'function' ? renderText(e) : e.name}
                                        <div className="display-name" hidden>{getDisplayName(e)}</div>

                                    </li>
                                </React.Fragment>
                            )
                    }
                </ul>
                {!multiple ? <input autoComplete="off" role="presentation"
                    type="hidden"
                    name={inputIdName}
                    value={selected.id}
                    readOnly
                /> :
                    selected.id.length ? selected.id.map(ids =>
                        <input autoComplete="off" role="presentation"
                            type="hidden"
                            name={`${inputIdName}[]`}
                            value={ids}
                            readOnly
                        />
                    )
                        :
                        ''
                }
            </div>
        </ClickAwayListener>
    )
}

export default React.memo(React.forwardRef(Index), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})
import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'
import Api from '../../../../../_helpers/Api'

//images
import loader from '../../../../../assets/images/app/loader.svg'
import closeIcon from '../../../../../assets/images/modals/icons/close.png'

//modals
import SuccessModal from '../../../../modals/Success'
import RemoveModal from '../../../../modals/Remove'

//components
import Dropdown from '../../../../app/Dropdown'

//contexts
import { useFinanceContext } from '../../../../../_contexts/FinananceContex'
import { useAuthContext } from '../../../../../_contexts/AuthContext'

function CreateEdit(props, ref) {
    const financeContext = useFinanceContext()
    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1
    const date = new Date()

    var current_date = new Date();
    var dd = String(current_date.getDate()).padStart(2, '0');
    var mm = String(current_date.getMonth() + 1).padStart(2, '0');
    var yyyy = current_date.getFullYear();
    current_date = yyyy + '-' + mm + '-' + dd;

    const materials = {
        date: `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? 0 : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? 0 : ''}${date.getDate()}`,
        project_id: null,
        expense_id: null,
        user_id: null,
        payment_type_id: 2,
        trip_worker: {
            name: [],
            id: []
        },
        worker: {
            name: ''
        },
        name: '',
        price: '',
    }

    const [createEdit, setCreateEdit] = useState({
        overlay: false,
        modal: false,
        loading: false,
        //edit
        mainLoading: false,
        id: null,
        materials: materials,
        original: materials,
        allUsers: []
    })

    const form = document.getElementById('edit-form')

    //refs
    const successModalRef = useRef(null)
    const removeModalRef = useRef(null)
    //refs end

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = id => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            overlay: true,
            id: id,
            mainLoading: id ? true : false,
        }))
        setTimeout(() => {
            setCreateEdit(createEdit => ({
                ...createEdit,
                modal: true,
            }))
        }, 50)


        Api.get(`workers/foreman-worker`)
            .then(res => {
                setCreateEdit(createEdit => ({
                    ...createEdit,
                    allUsers: res.data
                }))
            })

        if (id) {
            Api.get(`expense/getbyid?id=${id}`)
                .then(res => {
                    setCreateEdit(createEdit => ({
                        ...createEdit,
                        materials: res.data,
                        original: res.data,
                    }))
                })
                .finally(() => stopMainLoading())
        }
    }

    const handleClose = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            modal: false,
        }))
        removeModalRef.current.close()
        setTimeout(() => {
            document.getElementById('edit-form').reset()
            form.querySelectorAll('select').forEach(i => i.value = 0)

            removeValidations()
            setCreateEdit(createEdit => ({
                ...createEdit,
                overlay: false,
                mainLoading: true,
                id: null,
                materials: materials,
                original: materials
            }))
        }, 100)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = new FormData(e.target)

        removeValidations()

        startLoading()

        let url

        if (createEdit.id) {
            data.append('id', createEdit.id)
            url = 'expense/edit'
        }
        else url = 'expense/create'

        Api.post(url, data)
            .then(() => {
                successModalRef.current.open()
                props.handleRefreshData()
            })
            .catch(err => {
                let fields = err.response.data.errors

                Object.entries(fields).map(i => {
                    if (i[0]) {
                        let inputs = form.querySelectorAll("[name='" + i[0] + "']")
                        let inputsWithClass = form.querySelectorAll("[className='" + i[0] + "']")

                        inputs.forEach(i => i.classList.add('validate'))
                        inputsWithClass.forEach(i => i.classList.add('validate'))
                    }
                })
            })
            .finally(() => stopLoading())
    }

    const startLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            loading: false,
        }))
    }

    const stopMainLoading = () => {
        setCreateEdit(createEdit => ({
            ...createEdit,
            mainLoading: false,
        }))
    }

    const handleDelete = e => {
        e.preventDefault()
        removeModalRef.current.open()
    }
    const handleRemoveCallback = () => {
        startLoading()
        handleClose()
        Api.post('expense/delete', {
            id: createEdit.id
        })
            .then(() => {
                stopLoading()
                props.handleRefreshData()
            })
    }

    const handleChange = e => {
        let name = e.target.name
        let val = e.target.value

        if (name == 'expense_id' && val == 6) handleRadio(2)
        if (name == 'expense_id' && val == 4) handleRadio(1)


        setCreateEdit(createEdit => ({
            ...createEdit,
            materials: {
                ...createEdit.materials,
                [name]: val
            }
        }))
    }

    const handleRadio = e => {
        let val
        if (e.target) val = e.target.value
        else val = e

        setCreateEdit(createEdit => ({
            ...createEdit,
            materials: {
                ...createEdit.materials,
                payment_type_id: val
            }
        }))
    }

    const removeValidations = () => form.querySelectorAll('.validate').forEach(i => i.classList.remove('validate'))

    return (
        <div className={`${createEdit.overlay ? 'visible' : ''} overlay`}>
            <RemoveModal
                mainMessage="Сигурни ли сте, че искате да изтриете тоз разход?"
                secondaryMessage="Prespa Invest не съхранява изтритите от Вас данни."
                ref={removeModalRef}
                handleRemoveCallback={handleRemoveCallback}
                loading={createEdit.loading}
            />
            <SuccessModal
                mainMessage={createEdit.id ? 'Успешно редактирахте разход!' : 'Разхода е добавен успешно!'}
                secondaryMessage="Може да откриете промените в списъка с разход."
                ref={successModalRef}
                handleSuccessAction={handleClose}
            />
            <div className="scroller">
                <div style={{ width: '390px' }} className={`${createEdit.modal ? 'show' : ''} box box-small`}>
                    <img src={closeIcon} className="close" onClick={handleClose} />
                    <form id="edit-form" onSubmit={handleSubmit} className="create-task">
                        <h3>
                            {createEdit.id ?
                                'Редакция на разход' :
                                'Добавяне на разход'
                            }
                        </h3>
                        {createEdit.mainLoading ?
                            <img src={loader} alt="loading" />
                            :
                            <>
                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Проект
                                    </label>
                                        <select
                                            name="project_id"
                                            defaultValue={createEdit.materials.project_id && createEdit.materials.project_id}
                                            onChange={handleChange}
                                        >
                                            <option selected disabled>
                                                Избор
                                        </option>
                                            {financeContext.projects.map(project =>
                                                <option key={project.id} value={project.id}>
                                                    {project.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                                {isAdmin ?
                                    createEdit.materials.payment_type_id != 1 ?
                                        <div className="row">
                                            <div className="col full-width">
                                                <label>
                                                    От
                                        </label>
                                                <select
                                                    name="user_id"
                                                    defaultValue={createEdit.materials.user_id}
                                                    onChange={handleChange}
                                                >
                                                    <option selected disabled>
                                                        Избор
                                                </option>
                                                    {createEdit.allUsers.map(user =>
                                                        <option key={user.id} value={user.id}>
                                                            {user.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    :
                                    <input
                                        type="hidden"
                                        name="user_id"
                                        value={auth.user.data.id}
                                    />
                                }
                                <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Тип разход
                                    </label>
                                        <select
                                            name="expense_id"
                                            defaultValue={createEdit.materials.expense_id && createEdit.materials.expense_id}
                                            onChange={handleChange}
                                        >
                                            <option selected disabled>
                                                Избор
                                        </option>
                                            {financeContext.expenseTypes.filter(ex => isForeman ? ex.id != 4 : true).map(expense =>
                                                <option key={expense.id} value={expense.id}>
                                                    {expense.name}
                                                </option>
                                            )}
                                            <option value="0">
                                                Друг
                                        </option>
                                        </select>
                                    </div>
                                </div>


                                {
                                    createEdit.materials.expense_id == 4 &&
                                    <>
                                        <div className='row'>
                                            <div className='col full-width'>
                                                <label>
                                                    Номер на фактура
                                        </label>
                                                <input
                                                    type="text"
                                                    defaultValue={createEdit.materials.facture_number && createEdit.materials.facture_number}
                                                    name="facture_number"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className='col full-width'>
                                                <label>
                                                    Описание на фактура
                                        </label>
                                                <input
                                                    type="text"
                                                    defaultValue={createEdit.materials.facture_description && createEdit.materials.facture_description}
                                                    name="facture_description"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col">
                                                <label>
                                                    Дата на Фактура
                                            </label>
                                                <input
                                                    type="date"
                                                    defaultValue={createEdit.materials.facture_ymd && createEdit.materials.facture_ymd}
                                                    name="facture_date"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }



                                {createEdit.materials.expense_id == 6 && <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Командировачни за
                                    </label>
                                        <Dropdown
                                            inputIdName="trip_user_id"
                                            url='workers/get'
                                            params={{
                                                project_id: ''
                                            }}
                                            multiple={true}
                                            inputClassName="trip_user_id"
                                            multipleInputValues={`${createEdit.materials.trip_worker.name}`}
                                            multipleInputIdValues={createEdit.materials.trip_worker.id}
                                            renderText={data => {
                                                return <>{data.name}</>
                                            }}
                                        />
                                    </div>
                                </div>}

                                {createEdit.materials.expense_id == 5 && <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Аванс за
                                    </label>
                                        <Dropdown
                                            inputIdName="trip_user_id"
                                            url='workers/get'
                                            params={{
                                                project_id: ''
                                            }}
                                            multiple={true}
                                            inputClassName="trip_user_id"
                                            multipleInputValues={`${createEdit.materials.trip_worker.name}`}
                                            multipleInputIdValues={createEdit.materials.trip_worker.id}
                                            renderText={data => {
                                                return <>{data.name}</>
                                            }}
                                        />
                                    </div>
                                </div>}

                                {createEdit.materials.expense_id == 6 &&
                                    <div className='row'>
                                        <div className="col">
                                            <label>
                                                oт дата
                                        </label>
                                            <input
                                                type="date"
                                                defaultValue={createEdit.materials.fromdate && createEdit.materials.fromdate}
                                                name="fromdate"
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="col">
                                            <label>
                                                до дата
                                        </label>
                                            <input
                                                type="date"
                                                defaultValue={createEdit.materials.todate && createEdit.materials.todate}
                                                name="todate"
                                                onChange={handleChange}
                                            />
                                        </div>

                                    </div>
                                }
                                <div className="row">
                                    <div className={`col ${createEdit.materials.expense_id == 4 ? 'disabled-box' : ''}`}>
                                        <label className="checkbox">
                                            Кеш
                                        <input autoComplete="off" role="presentation"
                                                type="radio"
                                                value="2"
                                                name="payment_type_id"
                                                checked={createEdit.materials.payment_type_id ? createEdit.materials.payment_type_id == 2 : true}
                                                onChange={handleRadio}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className=
                                        {`col ${createEdit.materials.expense_id == 6 || createEdit.materials.expense_id == 5 || createEdit.materials.expense_id == 4 ? 'disabled-box' : ''}`}>
                                        <label className="checkbox">
                                            Чрез карта
                                        <input autoComplete="off" role="presentation"
                                                type="radio"
                                                value="3"
                                                name="payment_type_id"
                                                checked={createEdit.materials.payment_type_id == 3}
                                                onChange={handleRadio}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>

                                    {<div className=
                                        {`col ${!isForeman ? '' : 'disabled-box'}`}>
                                        <div>
                                            <label className="checkbox">
                                                По банка
                                        <input autoComplete="off" role="presentation"
                                                    type="radio"
                                                    value="1"
                                                    name="payment_type_id"
                                                    checked={createEdit.materials.payment_type_id == 1}
                                                    onChange={handleRadio}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>}
                                </div>
                                {createEdit.materials.payment_type_id == 2 && <div className="row ">
                                    <div className=
                                        {`col ${createEdit.materials.expense_id == 6 || createEdit.materials.expense_id == 5 || createEdit.materials.expense_id == 4 ? 'disabled-box' : ''}`}>
                                        <label className="checkbox">
                                            Фактура
                                        <input autoComplete="off" role="presentation"
                                                type="checkbox"
                                                value="1"
                                                name="facture"
                                                checked={createEdit.materials.facture}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>}
                                {createEdit.materials.expense_id == 0 && <div className="row">
                                    <div className="col full-width">
                                        <label>
                                            Друг тип
                                    </label>
                                        <input
                                            type="text"
                                            defaultValue={createEdit.materials.name && createEdit.materials.name}
                                            name="name"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col">
                                        <label>
                                            Дата
                                    </label>
                                        <input
                                            type="date"
                                            value={createEdit.materials.date && createEdit.materials.date}
                                            name="date"
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="col">
                                        <label>
                                            {createEdit.materials.expense_id == 4 ? `Стойност(в лв.)без ддс` : 'Стойност(в лв.)'}
                                        </label>
                                        <input
                                            type="number"
                                            defaultValue={createEdit.materials.price && createEdit.materials.price}
                                            name="price"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="buttons">
                                    {createEdit.loading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        <>
                                            {createEdit.id && <button className="btn-delete" onClick={handleDelete}>
                                                Изтриване
                                        </button>}
                                            <button className="btn-add">
                                                {createEdit.id ?
                                                    'Запазване' :
                                                    'Добавяне'
                                                }
                                            </button>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(CreateEdit)
import React from 'react'

function Colors(props) {
    const colors = [
        //1
        '#eeab2e',
        '#F37487',
        '#93495E',
        '#800000',
        //2
        '#9FA8A3',
        '#C3B4D7',
        '#9F4298',
        '#344649',
        //3
        '#A0D3F9',
        '#718DC1',
        '#3366FF',
        '#0C4A60',

        //4
        '#99FF33',
        '#00CC06',
        '#1FE599',
        '#333E51',
    ]

    const handleSelectColor = color => props.selectColor(color)
    return (
        <div className="colors">
            <div
                className="selected-color"
                style={{ backgroundColor: props.value }}
            >

            </div>
            <ul className="tool-tip">
                {colors.map(color =>
                    <li
                        style={{ backgroundColor: color }}
                        onClick={() => handleSelectColor(color)}
                    >

                    </li>
                )}
            </ul>
        </div>
    )
}

export default Colors
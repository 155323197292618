import React, { useState } from 'react'

//contexts
import { useStorageContext } from '../../../../../_contexts/StorageContext'


function Filter(props) {
    const storageContext = useStorageContext()

    const handleFilter = e => {
        let name = e.target.name
        let val = e.target.value

        props.handleFilter(name, val)
    }

    return (
        <form autoComplete="off" role="presentation" className="filter-standard">
            <div className="row">
                <div className="col">
                    <label htmlFor="data_type">
                        Данни
                    </label>
                    <select
                        id="data_type"
                        name="data_type"
                        onChange={handleFilter}
                    >
                        <option value="1">
                            Общи
                        </option>
                        <option value="2">
                            Подробни
                        </option>
                    </select>
                </div>
                <div className="col">
                    <label htmlFor="storage">
                        Склад
                    </label>
                    <select
                        id="storage"
                        name="storage"
                        onChange={handleFilter}
                    >
                        <option value="">Всички</option>
                        {storageContext.data.map(storage =>
                            <option key={storage.id} value={storage.id}>
                                {storage.name}
                            </option>
                        )}
                    </select>
                </div>
                <div className="col">
                    <label htmlFor="fromdate">
                        От
                    </label>
                    <input
                        type="date"
                        onChange={handleFilter}
                        name="fromdate"
                        id="fromdate"
                    />
                </div>
                <div className="col">
                    <label htmlFor="todate">
                        До
                    </label>
                    <input
                        type="date"
                        onChange={handleFilter}
                        name="todate"
                        id="todate"
                    />
                </div>
                <div className="col">
                    <label htmlFor="name">
                        Търсене
                    </label>
                    <input
                        type="search"
                        placeholder="Въведете име"
                        onChange={handleFilter}
                        name="name"
                        id="name"
                    />
                </div>
            </div>
        </form>
    )
}

export default Filter
import React, { useState } from 'react'
import ClickAwayListener from 'react-click-away-listener'

//images
import dotsBlack from '../../../../../assets/images/pages/icons/dots-black.png'

function Worker(props) {
    const worker = props.worker
    const [eachWorker, setEachWorker] = useState({
        optionsMenu: false,
    })

    const handleMenuOptions = () => {
        setEachWorker(eachWorker => ({
            ...eachWorker,
            optionsMenu: eachWorker.optionsMenu ? false : true
        }))
    }

    const handleClose = () => {
        if (eachWorker.optionsMenu) {
            setEachWorker(eachWorker => ({
                ...eachWorker,
                optionsMenu: false
            }))
        }
    }

    const handleEdit = id => {
        props.handleEdit(id)
        handleClose()
    }

    return (
        <tr key={worker.id}>
            <td>
                {worker.name}
            </td>
            <td className="option">
                {worker.editable ? <>
                    <img
                        src={dotsBlack}
                        alt="Опции"
                        title="Опции"
                        onClick={handleMenuOptions}
                    />
                    <ClickAwayListener onClickAway={handleClose}>
                        <div className={`options ${eachWorker.optionsMenu ? 'active' : ''}`}>
                            <div className="menu-item" onClick={() => handleEdit(worker.id)}>
                                Редакция
                            </div>
                        </div>
                    </ClickAwayListener>
                </>
                    :
                    ''
                }
            </td>
        </tr>
    )
}

export default Worker
import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Api from '../../../../_helpers/Api'

//components
import Filter from './partials/Filter'
import List from './partials/List'
import Pagination from '../../../app/Pagination'

//modals
import CreateEditModal from './modals/CreateEdit'
import GalleryModal from '../../../modals/Gallery'

//contexts
import { useAuthContext } from '../../../../_contexts/AuthContext'

let timeout

function Tasks(props) {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const projectId = useParams().id
    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    //refs
    const createEditModalRef = useRef(null)
    const galleryModalRef = useRef(null)
    //end refs

    const [tasks, setTasks] = useState({
        data: [],
        loading: true,
        pages: 1,
        page: searchParams.get('page') || 1,
        filter: {
            sort: 'date_desc',
            data: '',
            stage_id: searchParams.get('stage_id') || '',
            status: '',
            brigade_id: ''
        },
        refresh: null
    })

    useEffect(() => {
        startLoading()

        let url = `tasks/getbyid?id=${projectId}`
            + `&stage_id=${tasks.filter.stage_id}`
            + `&status=${tasks.filter.status}`
            + `&brigade_id=${tasks.filter.brigade_id}`
            + `&data=${tasks.filter.data}`
            + `&sort=${tasks.filter.sort}`
            + `&page=${tasks.page}`

        Api.get(url)
            .then(res => {
                setTasks(tasks => ({
                    ...tasks,
                    data: res.data.result,
                    pages: res.data.pages,
                }))
            })
            .finally(() => stopLoading())
    }, [tasks.page, tasks.filter, tasks.refresh])
    const startLoading = () => {
        setTasks(tasks => ({
            ...tasks,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setTasks(tasks => ({
            ...tasks,
            loading: false,
        }))
    }

    const handleCreateTask = () => createEditModalRef.current.open(projectId)

    const handleEditTask = id => createEditModalRef.current.edit(id)

    const handleFilter = (key, val, delay = 0) => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            setTasks(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val
                }
            }))
        }, delay)
    }

    const handleSortByDate = () => {
        setTasks(tasks => ({
            ...tasks,
            filter: {
                ...tasks.filter,
                sort: tasks.filter.sort == 'date_desc' ? 'date_asc' : 'date_desc'
            }
        }))
    }

    const handlePage = page => {
        setTasks(tasks => ({
            ...tasks,
            page: page.selected + 1
        }))
    }

    //refresh
    const handleRefreshData = () => {
        setTasks(tasks => ({
            ...tasks,
            refresh: new Date().getTime()
        }))

        props.handleRefreshData()
    }

    const handleUpdateTask = data => {
        let updated = tasks.data.map(t => {
            if (Number(t.id) === Number(data.id)) {
                t = data
            }
            return t
        })

        setTasks(prev => ({
            ...prev,
            data: updated
        }))
    }

    const handleViewImages = id => {
        let filteredImages = tasks.data.filter(each => {
            return each.id == id
        })[0].images

        const images = filteredImages.map(image => {
            return image.url
        })
        galleryModalRef.current.open(images)
    }

    return (
        <div className="wrapper task">
            <CreateEditModal
                ref={createEditModalRef}
                handleRefreshData={handleRefreshData}
                projectsFieldReadOnly={true}
            />
            <GalleryModal
                ref={galleryModalRef}
            />
            <div className="top row" style={isWorker ? { height: 100 + 'px' } : {}}>
                <span>

                </span>
                {(isAdmin || isForeman) && <button className="btn-add" onClick={handleCreateTask}>
                    Задача
                </button>}
            </div>
            <div className="row">
                <Filter
                    projectId={projectId}
                    filter={tasks.filter}
                    filterByDate={tasks.filter.sort}
                    handleFilter={handleFilter}
                    handleSortByDate={handleSortByDate}
                />
            </div>
            <hr />
            <div className="row">
                <List
                    tasks={tasks.data}
                    loading={tasks.loading}
                    handleEditTask={handleEditTask}
                    handleRefreshData={handleRefreshData}
                    handleUpdateTask={handleUpdateTask}
                    handleViewImages={handleViewImages}
                />
            </div>
            {!tasks.loading && <div className="row">
                <Pagination
                    page={tasks.page}
                    pages={tasks.pages}
                    handlePage={handlePage}
                />
            </div>}
        </div>
    )
}

export default Tasks
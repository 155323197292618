import React, { useState, useEffect, useRef } from 'react'
import Api from '../../_helpers/Api'
import { useLocation } from 'react-router-dom'

//images
import headingIcon from '../../assets/images/pages/icons/stage.png'
import loader from '../../assets/images/app/loader.svg'


//components
import Tabs from './tabs/Bar'
import Income from './tabs/income/Index'
import Expense from './tabs/expense/Index'
import UnpaidFature from './tabs/unpaid/Index'

import LastActivities from './partials/LastActivities'

//contexts
import { useFinanceContext } from '../../_contexts/FinananceContex'
import { useAuthContext } from '../../_contexts/AuthContext'

function Index() {
    const financeContext = useFinanceContext()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const auth = useAuthContext()

    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1

    const filterRef = useRef(null)
    const allFilterRef = useRef(null)

    const [storage, setStorage] = useState({
        currentTab: searchParams.get('tab') ? searchParams.get('tab') : 1,
        data: [],
        loading: true,
        projects: [],
        expenseTypes: [],
        incomeTypes: [],
        paymentTypes: [],
    })

    const handleCurrentTab = currentTab => {
        setStorage(storage => ({
            ...storage,
            currentTab: currentTab
        }))
    }

    useEffect(() => {
        let url;
        if (isForeman) {
            url = `brigades/foreman-projects?id=${auth.user.data.id}`
        }
        else {
            url = 'dashboard/notcompleted'
        }
        Api.get(url)
            .then(res => {
                setStorage(prev => ({
                    ...prev,
                    projects: res.data.items
                }))
                financeContext.setProjects(res.data.items)
            })

        Api.get('expensetype/all')
            .then(res => {
                setStorage(prev => ({
                    ...prev,
                    expenseTypes: res.data
                }))
                financeContext.setExpense(res.data)
            })

        Api.get('income/type/all-visible')
            .then(res => {
                setStorage(prev => ({
                    ...prev,
                    incomeTypes: res.data
                }))
                financeContext.setIncome(res.data)
            })

        Api.get('expense/payment-type')
            .then(res => {
                setStorage(prev => ({
                    ...prev,
                    paymentTypes: res.data
                }))
                financeContext.setPaymentTypes(res.data)
            })
    }, [])

    const selectCard = (val) => {
        filterRef.current.filter('payment_type_id', val)
    }

    const handleFail2 = (val) => {
        allFilterRef.current.curr(val)
    }

    return (
        <section id="view-project">
            {<aside className="storage-aside">
                <div className="wrapper">
                    <div className="row">
                        <h2>
                            <img src={headingIcon} alt="Дейности" />
                            Последни дейности
                        </h2>
                    </div>
                    {
                        /* !(isForeman ?
                            financeContext.data.expense : financeContext.data.income && financeContext.data.expense &&
                            financeContext.paid && financeContext.unpaid) ?
                            <img src={loader} alt="loading" /> : */
                        <LastActivities
                            card={selectCard}
                            ref={allFilterRef}
                            tab={storage.currentTab}
                        />
                    }
                </div>
            </aside>}
            <div className="main wrapper">
                <Tabs
                    currentTab={storage.currentTab}
                    handleCurrentTab={handleCurrentTab}
                />
                {storage.currentTab == 1 ?
                    <Income />
                    :
                    storage.currentTab == 2 ?
                        <Expense
                            ref={filterRef}
                            handleFail2={handleFail2}
                        />
                        :
                        storage.currentTab == 3 ?
                            <UnpaidFature

                            />
                            :
                            ''
                }
            </div>
        </section>
    )
}

export default Index

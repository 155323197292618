import React, { useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import Api from '../../_helpers/Api'

//images
import loader from '../../assets/images/app/loader.svg'
import machineIcon from '../../assets/images/pages/icons/worker.png'
import notFoundIcon from '../../assets/images/pages/icons/not-found.png'

//contexts
import { useProjcetContext } from '../../_contexts/ProjectContext'

function SelectMachines(props, ref) {
    const brigade = props.isBrigade
    const task = props.isTask
    const projectContext = useProjcetContext()
    const [modal, setModal] = useState({
        overlay: false,
        modal: false,
        mainLoading: true,
        loading: false,
        //machine type data
        data: [],
        selectedTypeId: null,
        stageId: null,
        //machine data
        machinesData: [],
        machinesLoading: false,

        // избрани машини
        selectedMachines: {},

        // брой машини, групирани по типове
        // багер => 1, трактор => 2
        machinesCount: {},
    })

    useEffect(() => {

        let machines = props.machines
        let selectedMachines = {}

        if (Array.isArray(machines)) {
            machines.map(u => {
                selectedMachines[u.id] = u
            })
        }

        setModal(prev => ({
            ...prev,
            selectedMachines: selectedMachines
        }))

    }, [props.machines])

    useEffect(() => {
        let machinesCount = {}

        Object.values(modal.selectedMachines).map(u => {
            if (!machinesCount[u.type_id]) {
                machinesCount[u.type_id] = 0
            }

            machinesCount[u.type_id]++
        })

        setModal(prev => ({
            ...prev,
            machinesCount: machinesCount
        }))

    }, [modal.selectedMachines])

    useImperativeHandle(ref, () => ({
        open: id => handleOpen(id),
        close: () => handleClose(),
    }))

    const handleOpen = stageId => {
        startMainLoading()

        let url
        if (brigade) url = `machines/types/get?with_machines_count=1`
        else if (task) url = `brigades/machines/types?brigade_id=${props.brigade_id}&stage_id=${stageId}&project_id=${projectContext.data.id}`

        Api.get(url)
            .then(res => {
                setModal(modal => ({
                    ...modal,
                    data: res.data,
                }))
            })
            .finally(() => stopMainLoading())

        setModal(modal => ({
            ...modal,
            overlay: true,
            stageId: stageId
        }))
        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                modal: true,
            }))
        }, 50)
    }

    const handleClose = e => {
        if (e) e.preventDefault()

        setModal(modal => ({
            ...modal,
            modal: false,
            machinesData: [],
        }))

        setTimeout(() => {
            setModal(modal => ({
                ...modal,
                overlay: false,
                stageId: null,
            }))
        }, 100)
    }

    const handleGetMachines = typeId => {

        setModal(modal => ({
            ...modal,
            selectedTypeId: typeId,
        }))

        startMachinesLoading()

        let url
        if (brigade) url = `machines/get?type_id=${typeId}`
        else if (task) url = `brigades/machines?brigade_id=${props.brigade_id}&stage_id=${modal.stageId}&type_id=${typeId}&project_id=${projectContext.data.id}`

        Api.get(url)
            .then(res => {
                setModal(modal => ({
                    ...modal,
                    machinesData: res.data,
                }))
            })
            .finally(() => stopMachinesLoading())
    }

    const startMainLoading = () => {
        setModal(modal => ({
            ...modal,
            mainLoading: true,
        }))
    }

    const stopMainLoading = () => {
        setModal(modal => ({
            ...modal,
            mainLoading: false,
        }))
    }

    const startMachinesLoading = () => {
        setModal(modal => ({
            ...modal,
            machinesLoading: true,
        }))
    }

    const stopMachinesLoading = () => {
        setModal(modal => ({
            ...modal,
            machinesLoading: false,
        }))
    }

    const handleSelectMachine = e => {
        let id = Number(e.target.value)

        let machines = modal.machinesData
        let selected = Object.assign({}, modal.selectedMachines)

        let user = machines.filter(u => u.id === id)[0]

        if (selected[id]) {
            delete selected[id]
        } else {
            selected[id] = user
        }

        setModal(prev => ({
            ...prev,
            selectedMachines: selected,
        }))
    }

    const handleSave = e => {
        e.preventDefault()

        let selected = modal.selectedMachines

        if (typeof props.setMachines === 'function') {
            props.setMachines(Object.values(selected))
            handleClose()
        }
    }

    return (
        <div className={`${modal.overlay ? 'visible' : ''} overlay`}>
            <div className="scroller">
                <div className={`${modal.modal ? 'show' : ''} box box-large`}>
                    <h2>
                        Избор на машини
                </h2>
                    <p className="left">
                        Изберете машините, които ще са нужни за изпълнение на задачата
                </p>
                    {modal.mainLoading ?
                        <img src={loader} alt="loading" />
                        :
                        <>
                            {modal.data.length == 0 ?
                                <div className="no-results">
                                    <img src={notFoundIcon} alt="Няма резултати" />
                                Не бяха намерени резултати.
                            </div>
                                :
                                <>
                                    <ul className="checkbox-filter">
                                        {modal.data.items && modal.data.items.map(each =>
                                            <>
                                                <li
                                                    key={each.id}
                                                    onClick={() => handleGetMachines(each.id)}
                                                    className={modal.selectedTypeId == each.id ? 'selected' : ''}
                                                >
                                                    <img src={each.image ? each.image : machineIcon} alt={each.name} />
                                                    <h5>
                                                        {each.name}
                                                    </h5>
                                                    <span>
                                                        {each.machines_count} {each.machines_count == 1 ? 'машина' : 'машини'}
                                                    </span>
                                                    <i>
                                                        {modal.machinesCount[each.id] || 0}
                                                    </i>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                    <hr />
                                    {modal.machinesLoading ?
                                        <img src={loader} alt="loading" />
                                        :
                                        modal.machinesData.length == 0 ?
                                            <div className="no-results">
                                                Изберете тип машина.
                                        </div>
                                            :
                                            <ul className="checkbox-list">
                                                {modal.machinesData.map(each =>
                                                    <li>
                                                        <label className="checkbox">
                                                            {each.no}
                                                            <input autoComplete="off" role="presentation"
                                                                type="checkbox"
                                                                value={each.id}
                                                                checked={modal.selectedMachines[each.id] ? true : false}
                                                                onChange={handleSelectMachine}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </li>
                                                )}
                                            </ul>
                                    }
                                </>
                            }
                            <div className="buttons">
                                {modal.loading ?
                                    <img src={loader} alt="loading" />
                                    :
                                    <>
                                        <button className="btn-cancel" onClick={handleClose}>
                                            Отказ
                                    </button>
                                        <button className="btn-add" onClick={handleSave}>
                                            Добавяне
                                    </button>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(forwardRef(SelectMachines), (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next)
})
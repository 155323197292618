import React, { useEffect, useState } from 'react'
import Api from '../../../../_helpers/Api'

//components
import Material from './partials/Material'

//images
import loader from '../../../../assets/images/app/loader.svg'
import okIcon from '../../../../assets/images/modals/icons/success.png'
import tableFilterIcon from '../../../../assets/images/pages/icons/table-filter.png'

function Index() {
    const [materials, setMaterials] = useState({
        data: [],
        loading: true,
        filter: {
            quantity: 'quantity-asc'
        }
    })

    useEffect(() => {
        startLoading()

        Api.get(`dashboard/exhaust?sort=${materials.filter.quantity}`)
            .then(res => {
                setMaterials(materials => ({
                    ...materials,
                    data: res.data,
                }))
            })
            .finally(() => stopLoading())
    }, [materials.refresh, materials.filter])
    const startLoading = () => {
        setMaterials(materials => ({
            ...materials,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setMaterials(materials => ({
            ...materials,
            loading: false,
        }))
    }

    const handleQuantityFilter = () => {
        setMaterials(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                quantity: materials.filter.quantity == 'quantity-asc' ? 'quantity-desc' : 'quantity-asc'
            }
        }))
    }

    return (
        <div className="holder">
            {materials.loading ?
                <img src={loader} alt="loading" />
                :
                materials.data.length == 0 ?
                    <div className="no-results">
                        <img src={okIcon} alt="В норма" />
                        Всички материали са в
                        <br />
                        достатъчна наличност.
                    </div>
                    :
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Материал</th>
                                    <th>Характ.</th>
                                    <th>Склад</th>
                                    <th>Дост.</th>
                                    <th>
                                        <span
                                            className={`quantity-filter ${materials.filter.quantity == 'quantity-desc' ? 'rotate' : ''}`}
                                            onClick={handleQuantityFilter}
                                        >
                                            Кол.
                                        <img src={tableFilterIcon} alt="Наличност" />
                                        </span>
                                    </th>
                                    <th>Цена</th>
                                </tr>
                            </thead>
                            <tbody>
                                {materials.data.map(material =>
                                    <Material
                                        key={material.id}
                                        material={material}
                                    />
                                )}
                            </tbody>
                        </table>
                    </div>
            }
        </div>
    )
}

export default Index
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Api from '../../../_helpers/Api'

//contexts
import { useAuthContext } from '../../../_contexts/AuthContext'

function Tabs(props) {
    const history = useHistory()
    const auth = useAuthContext()
    const inCity = useParams().inCity == 1
    const isAdmin = auth.user.data.role_id == 3
    const isForeman = auth.user.data.role_id == 2
    const isWorker = auth.user.data.role_id == 1
    const projectId = props.projectId

    const tabsInitial = []

    const [tabs, setTabs] = useState({
        data: [
            {
                id: 1,
                name: inCity ? 'Адреси' : 'Междушахтия',
            },
            {
                id: 2,
                name: 'Задачи',
            },
            {
                id: 3,
                name: 'Материали',
            },
        ],
    })

    useEffect(() => {
        let arr = []
        Api.get(`brigades/getbyprojectid?project_id=${projectId}`)
            .then(res => {
                if (res.data.length != 0) {
                    res.data.map(t =>
                        arr.push({
                            id: t.id,
                            name: t.name
                        })
                    )
                } else {
                    arr.push({
                        id: 4,
                        name: 'Бригада'
                    })
                }

                // arr = tabs.data.concat(arr)

                setTabs(prev => ({
                    ...prev,
                    data: prev.data.concat(arr)
                }))

            })
    }, [])

    const handleActiveTab = e => {
        let tabId = e.target.getAttribute('data-tab')

        history.push(`?tab=${tabId}`)

        props.handleCurrentTab(tabId)
    }

    return (
        <>
            <ul className="tabs wrapper">
                {tabs.data.map(tab =>
                    isWorker && (tab.id == 1 || tab.id > 3) ? '' :
                        <li
                            key={tab.id}
                            data-tab={tab.id}
                            className={tab.id == props.currentTab ? 'active' : ''}
                            onClick={handleActiveTab}
                        >
                            {tab.name}
                        </li>
                )}
            </ul>
        </>
    )
}

export default Tabs
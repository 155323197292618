import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import { useLocation } from 'react-router-dom'
import Api from '../../../../_helpers/Api'

//components
import Filter from './partials/Filter'
import Pagination from '../../../app/Pagination'
import Table from './partials/Table'

//images
import loader from '../../../../assets/images/app/loader.svg'
import notFoundIcon from '../../../../assets/images/pages/icons/not-found.png'

//modals
import CreateEditModal from './modals/CreateEdit'
import ForemanBalanceModal from './modals/ForemanBalance'
import SuccessModal from '../../../modals/Success'

//contexts
import { useFinanceContext } from '../../../../_contexts/FinananceContex'
import { useAuthContext } from '../../../../_contexts/AuthContext'

let timeout
function Index(props, ref) {
    const financeContext = useFinanceContext()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const auth = useAuthContext()
    const isAdmin = auth.user.data.role_id == 3

    const [materials, setMaterials] = useState({
        filter: {
            project_id: '',
            fromdate: '',
            todate: '',
            name: '',
            user_id: '',
            expense_id: '',
            price: 'price-asc',
            date_sort: 'date-asc',
            status: 'paid-asc',
            payment_type_id: ''
        },
        loading: true,
        data: [],
        paid: null,
        pages: 1,
        page: searchParams.get('page') || 1,
        refresh: null,
    })

    //refs
    const createEditRef = useRef(null)
    const addBalanceRef = useRef(null)
    const paidModalRef = useRef(null)
    //end refs

    useImperativeHandle(ref, () => ({
        filter: (key, val) => handleFilter(key, val),
    }))

    useEffect(() => {
        startLoading()
        let url = `expense/unpaid?project_id=${materials.filter.project_id}`
            + `&name=${materials.filter.name}`
            + `&page=${materials.page}`
            + `&sort=${materials.filter.price}`
            + `&date_sort=${materials.filter.date_sort}`
            + `&status=${materials.filter.status}`
            + `&expense_id=${materials.filter.expense_id}`
            + `&fromdate=${materials.filter.fromdate}`
            + `&todate=${materials.filter.todate}`
            + `&expense_id=${materials.filter.expense_id}`
        Api.get(url)
            .then(res => {
                setMaterials(materials => ({
                    ...materials,
                    data: res.data.result,
                    pages: res.data.page
                }))
                financeContext.setData({
                    foreman: res.data.foreman,
                    expense_type: res.data.expense_type,
                })
                financeContext.setPaid(res.data.paid)
                financeContext.setUnPaid(res.data.unpaid)
            })
            .finally(() => stopLoading())
    }, [materials.page, materials.filter, materials.refresh])
    const startLoading = () => {
        setMaterials(materials => ({
            ...materials,
            loading: true,
        }))
    }
    const stopLoading = () => {
        setMaterials(materials => ({
            ...materials,
            loading: false,
        }))
    }

    const handleFilter = (key, val, delay = 0) => {
        clearTimeout(timeout)

        timeout = setTimeout(() => {
            setMaterials(prev => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    [key]: val
                }
            }))
        }, delay)
    }

    const handlePage = page => {
        setMaterials(materials => ({
            ...materials,
            page: page.selected + 1
        }))
    }

    //refresh
    const handleRefreshData = () => {
        setMaterials(materials => ({
            ...materials,
            refresh: new Date()
        }))
    }

    const handlePriceFilter = () => {
        setMaterials(materials => ({
            ...materials,
            filter: {
                ...materials.filter,
                price: materials.filter.price == 'price-asc' ? 'price-desc' : 'price-asc',
                date_sort: '',
                status: ''
            }
        }))
    }

    const handleStatusFilter = () => {
        setMaterials(materials => ({
            ...materials,
            filter: {
                ...materials.filter,
                status: materials.filter.status == 'paid-asc' ? 'paid-desc' : 'paid-asc',
                date_sort: '',
                price: '',
            }
        }))
    }

    const handleDateFilter = () => {
        setMaterials(materials => ({
            ...materials,
            filter: {
                ...materials.filter,
                date_sort: materials.filter.date_sort == 'date-asc' ? 'date-desc' : 'date-asc',
                price: '',
                status: ''
            }
        }))
    }

    const handleEdit = (id, expense_id) => {
        if (expense_id == 7) {
            addBalanceRef.current.open(id, expense_id)
        } else {
            createEditRef.current.open(id)
        }
    }
    const handlePayment = (id, paid) => {
        let pay;
        let url;
        if (paid == 0) {
            pay = paid;
            url = `expense/markaspaid?id=${id}`
        } else {
            pay = paid;
            url = `expense/markasunpaid?id=${id}`
        }
        setMaterials(prev => ({
            ...prev,
            paid: pay
        }))
        Api.get(url)
            .then(res => {
                handleRefreshData()
                paidModalRef.current.open()
            })
    }

    // const handleClose = (e) => {
    //     e.preventDefault()
    //     paidModalRef.current.close(e)
    // }

    const handleFail = (val) => {
        props.handleFail2(val)
    }
    const handleCreate = () => createEditRef.current.open()
    const handleAddBalance = () => addBalanceRef.current.open()
    return (
        <div className="wrapper task">
            <CreateEditModal
                ref={createEditRef}
                handleRefreshData={handleRefreshData}
            />
            <ForemanBalanceModal
                ref={addBalanceRef}
                handleRefreshData={handleRefreshData}
            />
            <SuccessModal
                mainMessage={materials.paid == 0 ? 'Успешно маркирахте като платена!' : 'Успешно маркирахте като неплатена!'}
                ref={paidModalRef}
                handleRefreshData={handleRefreshData}
            />
            <div style={{ justifyContent: 'flex-end' }} className="top row">
                <span>

                </span>

                <div className='col'>
                    <button className="btn-add" onClick={handleCreate}>
                        Разход
                    </button>
                </div>
            </div>

            <div className="row">
                <Filter
                    handleFilter={handleFilter}
                    handleFail={handleFail}
                    data={materials.filter}
                />
            </div>

            {materials.loading ?
                <img src={loader} alt="loading" />
                :
                materials.data.length == 0 ?
                    <div className="no-results">
                        <img src={notFoundIcon} alt="Няма резултати" />
                        Не бяха намерени резултати.
                    </div>
                    :
                    <>
                        <Table
                            materials={materials.data}
                            handleEdit={handleEdit}
                            handlePayment={handlePayment}
                            priceFilter={handlePriceFilter}
                            dateFilter={handleDateFilter}
                            statusFilter={handleStatusFilter}
                            priceFilterApplied={materials.filter.price}
                            dateFilterApplied={materials.filter.date_sort}
                            statusFilterApplied={materials.filter.status}
                        />
                        <div className="row">
                            <Pagination
                                page={materials.page}
                                pages={materials.pages}
                                handlePage={handlePage}
                            />
                        </div>
                    </>
            }
        </div>
    )
}

export default forwardRef(Index)